<div *ngIf="!loadingPage else loadingTemplate" [formGroup]="formGroup">

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 pb-3 col-12">
            <app-basic-input id="document_number" controlName="document_number" [formGroup]="formGroup" label="CPF"
              [maskOptions]="{mask: '000.000.000-00'}" (onValueChange)="searchPatient($event)"></app-basic-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Dados pessoais</h3>
        </div>

        <div class="col-12 col-lg-3">
          <app-image-input id="profile_image" [disabled]="formGroup.disabled" (onImageChange)="onImageSelect($event)"
            [value]="existingPatientData.profile_image ?? ''">
          </app-image-input>
        </div>

        <div class="col p-0">
          <div class="row p-0">
            <div class="col-sm-12 px-2 mb-3">
              <app-basic-input id="name" [formGroup]="formGroup" controlName="name" label="Nome Completo">
              </app-basic-input>
            </div>

            <div class="col-sm-6 px-2 mb-3">
              <app-basic-input id="display_name" [formGroup]="formGroup" controlName="display_name"
                label="Nome de tratamento"></app-basic-input>
            </div>

            <div class="col-sm-2 px-2 mb-2">
              <label class="field__title">Gênero</label>

              <div class="radio-option">
                <label for="gender_male"><input type="radio" name="gender" formControlName="gender" value="MALE"
                    id="gender_male" /> Masculino</label>
              </div>

              <div class="radio-option">
                <label for="gender_female"><input type="radio" name="gender" formControlName="gender" value="FEMALE"
                    id="gender_female" /> Feminino</label>
              </div>
            </div>

            <div class="col-sm-4 px-2 mb-3">
              <app-basic-input type="date" id="birthdate" [formGroup]="formGroup" controlName="birthdate"
                label="Data de nascimento">
              </app-basic-input>
            </div>

            <div class="col-sm-6 px-2 mb-3">
              <app-basic-input id="email" [formGroup]="formGroup" controlName="email" label="E-mail"></app-basic-input>
            </div>

            <div class="col-3 px-2 mb-3">
              <app-select-input id="marital_status" label="Estado civil" [formGroup]="formGroup"
                [options]="maritalStatusList" [searchable]="false" [clearable]="false">
              </app-select-input>
            </div>

            <div class="col-3 px-2 mb-3">
              <app-select-input id="profession" label="Profissão" [formGroup]="formGroup" [options]="professionsList"
                [searchable]="true" [clearable]="false" addTagText="Adicionar novo" [addTag]="true"></app-select-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" *ngIf="isClinicStaff">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Clinicas</h3>
        </div>
        <div class="px-2 col-12">
          <app-select-input id="clinics" [formGroup]="formGroup" [options]="clinicsList" [hideSelected]="true"
                            [addTag]="false" [multiple]="true" [clearable]="false" [searchable]="false"></app-select-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" *ngIf="isClinicStaff">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Tags</h3>
        </div>
        <div class="px-2 col-12">
          <app-select-input id="tags" [formGroup]="formGroup" [options]="patientTagsList" [hideSelected]="true"
            [addTag]="false" [multiple]="true" [clearable]="false" [searchable]="false"></app-select-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" formArrayName="phone_numbers">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Telefone</h3>
        </div>

        <ng-container *ngFor="let phoneNumber of phoneNumberForms.controls; index as i">
          <div class="row col-12" [class]="{'d-none': !phoneNumberIsActive(i)}">
            <div class="col-6 p-0 mb-3">
              <phone-number-input [formGroup]="getPhoneNumberForm(i)"></phone-number-input>
            </div>

            <div class="px-2 col-4 mb-3" [formGroup]="getPhoneNumberForm(i)">
              <label class="field__title"></label>
              <app-select-input id="type" [formGroup]="getPhoneNumberForm(i)" [options]="phoneTypeList"
                [searchable]="false" [clearable]="false"></app-select-input>
            </div>

            <div class="px-2 col-2">
              <button (click)="removePhoneNumber(i)" [disabled]="formGroup.disabled"
                class="remove-button">Remover</button>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-link" [disabled]="formGroup.disabled" (click)="addPhoneNumber()">Adicionar
              novo</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" formArrayName="health_insurances">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Convênios</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <label class="field__title">Convênio</label>
        </div>

        <div class="col-3">
          <label class="field__title">Plano</label>
        </div>

        <div class="col-3">
          <label class="field__title">Número do convênio</label>
        </div>
      </div>

      <div class="row">
        <ng-container *ngFor="let healthInsuranceForm of healthInsuranceForms.controls; index as i">
          <div class="row col-12 p-0 mb-3" [formGroupName]="i" [class]="{'d-none': !healthInsuranceIsActive(i)}">
            <div class="px-2 col-4">
              <app-select-input id="health_insurance_id" [formGroup]="getHealthInsuranceForm(i)"
                [options]="healthInsurancesList" (change)="changeHealthInsurancePlans($event)" [searchable]="false"
                [clearable]="false"></app-select-input>
            </div>

            <div class="px-2 col-3">
              <app-select-input [formGroup]="getHealthInsuranceForm(i)" [options]="getHealthInsurancePlansFrom(i)"
                id="health_insurance_plan" [clearable]="true"></app-select-input>
            </div>

            <div class="px-2 col-3">
              <app-basic-input class="custom" [formGroup]="getHealthInsuranceForm(i)" id="number"
                controlName="number"></app-basic-input>
            </div>

            <div class="px-2 col-2">
              <button (click)="removeHealthInsurance(i)" [disabled]="formGroup.disabled"
                class="remove-button">Remover</button>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-link" [disabled]="formGroup.disabled" (click)="addHealthInsurance()">Adicionar
              novo</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" [formGroup]="formGroup">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Filiação</h3>
        </div>

        <div class="px-2 col-6">
          <app-basic-input id="mother_name" [formGroup]="formGroup" label="Nome da mãe" controlName="mother_name">
          </app-basic-input>
        </div>

        <div class="px-2 col-6">
          <app-basic-input id="father_name" [formGroup]="formGroup" label="Nome da pai" controlName="father_name">
          </app-basic-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" [formGroup]="addressFormGroup">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Endereço</h3>
        </div>
        <div class="px-2 col-sm-2 mt-3 zipcode">
          <app-basic-input id="zipcode" controlName="zipcode" label="CEP" [formGroup]="addressFormGroup"
            (focusout)="handleZipCodeChange($event)" [maskOptions]="{mask: '00000-000'}"></app-basic-input>
          <span *ngIf="searchZipcode" class="spinner-border"></span>
        </div>
        <div class="px-2 col-sm-8 mt-3">
          <app-basic-input id="address" [formGroup]="addressFormGroup" label="Endereço" controlName="address">
          </app-basic-input>
        </div>
        <div class="px-2 col-sm-2 mt-3">
          <app-basic-input id="address_number" [formGroup]="addressFormGroup" label="Número" controlName="number">
          </app-basic-input>
        </div>
        <div class="px-2 col-sm-3 mt-3">
          <app-basic-input id="complement" [formGroup]="addressFormGroup" label="Complemento" controlName="complement">
          </app-basic-input>
        </div>
        <div class="px-2 col-sm-3 mt-3">
          <app-basic-input id="neighborhood" [formGroup]="addressFormGroup" label="Bairro" controlName="neighborhood">
          </app-basic-input>
        </div>
        <div class="px-2 col-sm-3 mt-3">
          <app-basic-input id="city" [formGroup]="addressFormGroup" label="Cidade" controlName="city"></app-basic-input>
        </div>
        <div class="px-2 col-sm-3 mt-3">
          <app-basic-input id="state" [formGroup]="addressFormGroup" label="Estado" controlName="state">
          </app-basic-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" [formGroup]="formGroup" *ngIf="isClinicStaff">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Indicação</h3>
        </div>

        <div class="px-2 col-6 mb-3 d-flex" [formGroup]="recommendationForm">
          <label class="field__title mr-4">Tipo de indicação</label>
          <div class="radio-option mr-4">
            <label for="clinic" (click)="resetAds()">
              <input type="radio" name="type" formControlName="type" value="clinic" id="clinic" />
              Clínica</label>
          </div>

          <div class="radio-option">
            <label for="publicity" (click)="resetClinic()">
              <input type="radio" name="type" formControlName="type" value="publicity" id="publicity" />
              Propaganda
            </label>
          </div>
        </div>

        <div *ngIf="recommendationForm.get('type')!.value == 'publicity'" class="col-12 p-0 m-0">
          <div class="row p-0 m-0">
            <div class="px-2 col-6 mb-3">
              <app-select-input id="ad_origin" label="Propaganda" [formGroup]="formGroup" [options]="adOriginList"
                [searchable]="false" [clearable]="false"></app-select-input>
            </div>
          </div>
        </div>

        <div *ngIf="recommendationForm.get('type')!.value == 'clinic'" class="col-12 p-0 m-0">
          <div class="row p-0 m-0">
            <div class="px-2 col-6 mb-3">
              <app-select-input id="clinic" label="Clínica" [formGroup]="formGroup" [options]="clinicsList"
                [searchable]="false" [clearable]="false"
                (onValueChange)="getProfessionalsFromClinic($event)"></app-select-input>
            </div>

            <div class="px-2 col-6 mb-3">
              <app-select-input id="professional" label="Profissional" [formGroup]="formGroup"
                [options]="professionalsList" [searchable]="false" [clearable]="false"></app-select-input>
            </div>
          </div>
        </div>

        <div class="px-2 col-12">
          <app-textarea-input label="Observações" [rows]="3" id="notes" controlName="notes"
            [formGroup]="formGroup"></app-textarea-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-lg-3 mb-3">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-12 col-lg-3 mb-3" *ngIf="button">
      <button (click)="removeHandler()" [disabled]="submittingRemove" class="btn btn-secondary btn-lg" type="button">
        {{removeButton}}
        <span class="spinner-border" *ngIf="submittingRemove"></span>
      </button>
    </div>

    <div class="col-12 col-lg-3 mb-3">
      <button (click)="submitHandler()" [disabled]="submitting" class="btn btn-primary btn-lg" type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
