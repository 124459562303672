import { DOCUMENT, registerLocaleData } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AttendanceTimeModel } from 'src/app/core/models/attendance-time.model';
import { AvailableAttendanceModel } from 'src/app/core/models/available-attendance.model';
import { Schedule } from 'src/app/core/models/schedule.model';
import { ScheduleService } from 'src/app/core/services/schedules.service';
import * as moment from 'moment';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { Dictionary } from '@fullcalendar/angular';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import { RegistersService } from 'src/app/core/services/registers.service';
import localeEs from '@angular/common/locales/pt';
import { ToastrService } from 'ngx-toastr';

registerLocaleData(localeEs);

@Component({
  selector: 'app-schedules-form',
  templateUrl: './schedules-form.component.html',
  styleUrls: ['./schedules-form.component.scss']
})
export class SchedulesFormComponent implements OnInit {
  formGroup = this.fb.group({
    name: [''],
    slug: ['', [Validators.required]],
    since: [null, [Validators.required]],
    until: [null, [Validators.required]]
  });

  calendarFormGroup = this.fb.group({
    weekday: ['1', [Validators.required]],
    start_time: [null, [Validators.required]],
    end_time: [null, [Validators.required]],
    duration: [10, [Validators.required]],
    allowed_attendance_types: this.fb.array([])
  })

  firstSplitTime = this.fb.control(10, [Validators.required]);

  submitting: boolean = false;
  submittingRemove: boolean = false;
  id!: number;
  isRegister: boolean = true;
  loadingPage: boolean = false;
  fetchedScheduleData!: Schedule;

  disabledSalvar: boolean = false;
  disabledRemover: boolean = false;

  removeButton!: String;
  submitButton: String = 'Salvar';
  button: boolean = false;
  isActive: boolean = true;

  availableAttendances: AvailableAttendanceModel[] = [];
  attendanceTimes: AttendanceTimeModel[] = [];
  attendanceCheckedList: any[] = [];
  createPeriodAttendance: boolean = true;

  constructor(@Inject(DOCUMENT) private document: Document, private fb: FormBuilder, private scheduleService: ScheduleService, modalConfig: NgbModalConfig, private modalService: NgbModal, private router: Router, private activatedRoute: ActivatedRoute, private sessionManager: SessionManagerService, private registersService: RegistersService, private toast: ToastrService) {
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      if (this.id) {
        this.isRegister = false;
        this.initializeForm();
      }
    });
    if (!this.sessionManager.getUserPermission(PermissionConst.change_calendar) || !this.sessionManager.getUserPermission(PermissionConst.add_calendar)) {
      this.disabledSalvar = true;
      this.disabledRemover = true;
      this.formGroup.disable();
      this.calendarFormGroup.disable();
    }
    if (this.sessionManager.getUserPermission(PermissionConst.remove_calendar) && this.sessionManager.getUserPermission(PermissionConst.add_calendar)) {
      this.disabledRemover = false;
    }
    if (!this.sessionManager.getUserPermission(PermissionConst.remove_calendar)) {
      this.disabledRemover = true;
    }
    this.fetchAttendanceType();
  }

  initializeForm() {
    this.loadingPage = true;
    this.fetchSchedule();
  }

  fetchSchedule() {
    this.scheduleService.retrieve(this.id).subscribe((response) => {
      if(!response.slug) {
        response.slug = '';
      }
      this.fetchedScheduleData = response;
      if (this.fetchedScheduleData.is_active) {
        this.button = true;
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
      } else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
        this.calendarFormGroup.disable();
        this.isActive = false;
      }
      this.setFormData(response);
    })
  }

  fetchAttendanceType() {
    this.registersService.attendancesTypeAll().subscribe((response) => {
      this.attendanceCheckedList = response.map((type) => {
        return {
          id: type.id,
          name: type.name,
          checked: true
        }
      })
      let attendanceTypeArray: FormArray = this.calendarFormGroup.get('allowed_attendance_types') as FormArray;
      this.attendanceCheckedList.map(attendanceType => {
        attendanceTypeArray.push(this.fb.control(attendanceType.id));
      })
    }, (error) => {
      this.attendanceCheckedList = [];
    })
  }

  setFormData(schedule: Schedule) {
    let mapping: Dictionary = this.treatKeyValue(schedule);
    this.formGroup.patchValue(mapping);
    if (schedule.is_active) {
      this.formGroup.get('since')?.reset();
      this.formGroup.get('until')?.reset();
    }
    this.loadingPage = false;
  }

  cancelHandler() {
    this.router.navigate(["dashboard/registers/schedules"]);
  }

  submitHandler() {
    var schedule = this.formGroup.value as Schedule;
    schedule.is_active = true;
    schedule.attendances = this.__mountAttendanceTimes();
    this.submitting = true;
    if (this.isRegister) this.scheduleRegister(schedule);
    else this.scheduleEdit(schedule);
  }

  scheduleRegister(scheduleData: Schedule) {
    this.scheduleService.create(scheduleData).subscribe(() => {
      this.submitting = false;
      this.router.navigate(["dashboard/registers/schedules"]);
      this.toast.success("Agenda criada com sucesso", "Sucesso");
    }, (error: HttpErrorResponse) => {
      this.submitting = false;
      this.setErrors(error);
    })
  }

  scheduleEdit(scheduleData: Schedule) {
    scheduleData.id = this.id;
    this.scheduleService.update(this.id, scheduleData).subscribe(() => {
      this.submitting = false;
      this.router.navigate(["dashboard/registers/schedules"]);
      this.toast.success("Agenda alterada com sucesso", "Sucesso");

    }, (error: HttpErrorResponse) => {
      this.submitting = false;
      this.setErrors(error);
    })
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar esta agenda?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.scheduleService.remove(this.id).subscribe(() => {
          this.router.navigate(['/dashboard/registers/schedules']);
          this.toast.success("Agenda desativada com sucesso", "Sucesso");
        }, (errorResponse: HttpErrorResponse) => {
          this.setErrors(errorResponse);
          this.submitting = false;
        })
      } else {
        this.loadingPage = false;
      }
      this.submittingRemove = false;
    });
  }

  setErrors(error: HttpErrorResponse) {
    if (error.error['detail']) {
      this.toast.error(error.error["detail"], "Erro");
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    } else {
      var treatedErrors = this.extractErrors(error.error);
      treatedErrors.forEach((value, key) => {
        this.formGroup.get(key)?.setErrors({ 'response': value });
      });
    }
    this.document.getElementById('main-container')?.scroll({ top: 0 });
  }

  treatKeyValue(data: any, prefix: string = ''): Dictionary {
    var treated: Dictionary = {};
    for (let key in data) {
      if (typeof data[key] === 'object' && data[key] !== null) {
        var obj = this.treatKeyValue(data[key], `${prefix}${key}.`);
        Object.keys(obj).forEach((key) => treated[key] = obj[key]);
      } else {
        treated[`${prefix}${key}`] = `${data[key]}`;
      }
    }
    return treated;
  }

  extractErrors(errors: any, prefix: string = '') {
    var treatedErrors = new Map();
    for (let key in errors) {
      if (!errors[key][0]) {
        var object = this.extractErrors(errors[key], `${prefix}${key}.`);
        object.forEach((value, key) => treatedErrors.set(key, value));
      }
      else {
        let unavailable_dates = prefix + key
        if (unavailable_dates == 'unavailable_dates') {
          let unavailableError = this.extractErrors(errors[key], `${prefix}${key}.`);
          unavailableError.forEach((value, key) => treatedErrors.set(key, 'Este campo é obrigatório'));
        }
        else {
          treatedErrors.set(`${prefix}${key}`, `${errors[key]}`);
        }
      }
    }
    return treatedErrors;
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  }

  private __hoursBetween(minHour: string, maxHour: string) {
    let hours = [];
    for (let index = 0; index < 24; index++) {
      let hour = { hour: parseInt(minHour.split(':')[0]), minute: 0, second: 0 };
      let curTime = moment().set(hour).add(index, 'hour').format('HH:mm');
      if (curTime > maxHour) {
        break;
      }
      hours.push(curTime);
    }
    return hours;
  }

  get timeline() {
    let timeline = this.availableAttendances.map((value: AvailableAttendanceModel) => value.start_time);
    timeline = timeline.sort((a, b) => {
      if (a > b)
        return 1;
      return -1;
    });
    if (timeline.length > 0) {
      let minHour = timeline[0];
      let maxHour = timeline[timeline.length - 1];
      timeline = this.__hoursBetween(minHour, maxHour);
    }
    return timeline;
  }

  getEvents(weekday: string, hour: string): AvailableAttendanceModel[] {
    return this.availableAttendances.filter((value: AvailableAttendanceModel) => value.start_time.split(':')[0] == hour.split(':')[0] && value.weekday == weekday);
  }

  createPeriod(ev: any) {
    if (this.calendarFormGroup.valid) {
      ev.preventDefault();
      let weekday = this.calendarFormGroup.get('weekday')?.value;
      this.availableAttendances = this.availableAttendances.filter(val => val.weekday != weekday);
      let duration = parseInt(this.calendarFormGroup.get('duration')?.value);
      let endTime = moment(this.calendarFormGroup.get('end_time')?.value);
      let startTime = moment(this.calendarFormGroup.get('start_time')?.value);
      let millisecondsBetween = parseInt(endTime.format('X')) - parseInt(startTime.format('X'));
      let eventsAmount = millisecondsBetween / duration / 60;
      for (let index = 0; index < eventsAmount; index++) {
        let startsAt = moment(startTime).add(duration * index, 'minute');
        let availableAttendance: AvailableAttendanceModel = {
          weekday: weekday, start_time: startsAt.format('HH:mm'),
          duration: duration,
          allowed_attendance_types: this.attendanceCheckedList.filter(type => type.checked == true) ?? []
        };
        this.availableAttendances.push(availableAttendance);
      }
      this.attendanceCheckedList.forEach((type) => { type.checked = true});
      this.calendarFormGroup.get('weekday')?.reset();
      this.calendarFormGroup.get('duration')?.reset();
      this.calendarFormGroup.get('end_time')?.reset();
      this.calendarFormGroup.get('start_time')?.reset();
    }
  }

  private __mountAttendanceTimes(): AttendanceTimeModel[] {
    this.attendanceTimes = [];
    let since = moment(this.formGroup.get('since')?.value);
    let until = moment(this.formGroup.get('until')?.value).add(1, 'days');
    let daysBetween = until.diff(since) / 1000 / 60 / 60 / 24;

    this.availableAttendances.forEach((availableAttendance: AvailableAttendanceModel) => {
      for (let index = 0; index < daysBetween; index++) {
        let current = moment(since).add(index, 'days');
        if (current.weekday() == parseInt(availableAttendance.weekday)) {
          let hours = {
            hour: parseInt(availableAttendance.start_time.split(':')[0]),
            minute: parseInt(availableAttendance.start_time.split(':')[1])
          };
          let attendanceTime: AttendanceTimeModel = {
            is_available: true,
            start_time: current.set(hours).format('YYYY-MM-DDTHH:mm:ssZ'),
            end_time: current.set(hours).add(availableAttendance.duration, 'minute').format('YYYY-MM-DDTHH:mm:ssZ'),
            allowed_attendance_types: availableAttendance.allowed_attendance_types.map((value: any) => {
              return { id: value.id }
            })
          };
          this.attendanceTimes.push(attendanceTime);
        }
      }
    });
    return this.attendanceTimes;
  }

  selectedToAction: AvailableAttendanceModel[] = [];

  remove() {
    this.selectedToAction.forEach(value => {
      this.availableAttendances = this.availableAttendances.filter(attendance => attendance != value);
    });
    this.selectedToAction = [];
  }

  get isSequencial() {
    if (this.selectedToAction.length < 2) {
      return false;
    } else {
      let weekday = this.selectedToAction[0].weekday;
      let availableAttendancesForWeekday = this.availableAttendances.filter(value => value.weekday == weekday);
      let lastIndex: number | null = null;
      let isSequencial = true;

      this.selectedToAction.forEach(attendance => {
        let index = availableAttendancesForWeekday.findIndex(value => value == attendance);
        if (lastIndex == null) {
          lastIndex = index;
        } else {
          if (index - lastIndex != 1) {
            isSequencial = false;
          }
        }
        lastIndex = index;
      });
      return isSequencial;
    }
  }

  merge() {
    let item: AvailableAttendanceModel;
    this.selectedToAction.forEach(attendance => {
      if (!item) {
        item = attendance;
      } else {
        item.duration += attendance.duration;
        this.availableAttendances = this.availableAttendances.filter(value => attendance != value);
      }
    });
    this.selectedToAction = [];
  }

  split() {
    let duration: number = this.firstSplitTime.value;
    let item = this.availableAttendances.find(value => value == this.selectedToAction[0]);
    if (!item) {
      return;
    }
    if (duration < item.duration) {
      let attendanceType = this.calendarFormGroup.get('allowed_attendance_types')?.value;
      let secondDuration = item.duration - duration;
      item.duration = duration;
      let hour = {
        hour: parseInt(item.start_time.split(':')[0]),
        minute: parseInt(item.start_time.split(':')[1])
      };
      let startsAt = moment().set(hour).add(duration, 'minutes').format('HH:mm');
      let availableAttendance: AvailableAttendanceModel = {
        weekday: item.weekday, start_time: startsAt,
        duration: secondDuration,
        allowed_attendance_types: attendanceType
      };
      this.availableAttendances.push(availableAttendance);
      this.availableAttendances.sort((a, b) => {
        if (a.start_time > b.start_time) {
          return 1;
        }
        if (a.start_time < b.start_time) {
          return -1;
        }
        return 0;
      });
    }
  }

  isSelected(attendance: AvailableAttendanceModel) {
    return this.selectedToAction.some(value => attendance == value);
  }

  modalRefContent!: NgbModalRef;

  open(content: any) {
    this.attendanceCheckedList.forEach((type) => {
      let id = type.id;
      let checked;
      if (this.selectedToAction.length > 0) {
        checked = this.selectedToAction[0].allowed_attendance_types.some((value: any) => value.id == id);
      } else checked = true;
      type.checked = checked;
    });
    this.modalRefContent = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: true, keyboard: true });
  }

  get isCheckedAll() {
    return !this.attendanceCheckedList.some(type => !type.checked)
  }

  onSelectAll(ev: any) {
    this.attendanceCheckedList.forEach(type => {
      type.checked = ev.target.checked;
    })
  }

  isChecked(type: any) {
    let attendanceType = this.calendarFormGroup.get('allowed_attendance_types') as FormArray;
    return attendanceType.value.some((id: number) => id == type.id);
  }

  onCheckChange(type: any) {
    this.attendanceCheckedList.filter(value => value.id == type.id)?.map(() => type.checked = !type.checked);
  }

  updateAllowedAttendanceType() {
    if (this.selectedToAction.length > 0) {
      let types = this.attendanceCheckedList.filter(type => type.checked == true) ?? [];
      this.selectedToAction.forEach((attendance) => {
        attendance.allowed_attendance_types = types
      });
    }
    this.modalRefContent.close();
  }

  select(attendance: AvailableAttendanceModel) {
    if (this.isSelected(attendance)) {
      this.selectedToAction = this.selectedToAction.filter(value => attendance != value);
    } else {
      let hasDistinct = this.selectedToAction.some(value => attendance.weekday != value.weekday);
      if (hasDistinct) {
        this.selectedToAction = [];
      }
      this.selectedToAction.push(attendance);
    }

    this.selectedToAction.sort((a, b) => {
      if (a.start_time > b.start_time)
        return 1;
      if (a.start_time < b.start_time)
        return -1;
      return 0;
    });
  }
}
