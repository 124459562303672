import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {NavigationModule} from './navigation/navigation.module';
import {SchedulesModule} from './schedules/schedules.module';
import {PatientsModule} from './patients/patients.module';
import {RegistersModule} from './registers/registers.module';
import {RenderersModule} from 'src/app/shared/renderers/renderers.module';
import {ChartsModule} from './charts/charts.module';
import {FinancialModule} from './financial/financial.module';
import {OximeterModule} from './oximeter/oximeter.module';
import {
  FinancialTasksTableComponent
} from './registers/financial-tasks/financial-tasks-table/financial-tasks-table.component';
import {
  FinancialTasksFormComponent
} from './registers/financial-tasks/financial-tasks-form/financial-tasks-form.component';
import {SharedModule} from "../../shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {DragDropModule} from "@angular/cdk/drag-drop";
import { LeadsComponent } from './leads/leads.component';
import {ExamsRequestModule} from "./exams-request/exams-request.module";

@NgModule({
  declarations: [
    FinancialTasksTableComponent,
    FinancialTasksFormComponent,
    LeadsComponent
  ],
  imports: [
    CommonModule,
    NavigationModule,
    SchedulesModule,
    PatientsModule,
    RegistersModule,
    RenderersModule,
    ChartsModule,
    FinancialModule,
    OximeterModule,
    SharedModule,
    ReactiveFormsModule,
    DragDropModule,
    ExamsRequestModule
  ]
})
export class DashboardModule {
}
