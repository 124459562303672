import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Schedule } from 'src/app/core/models/schedule.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { ScheduleService } from 'src/app/core/services/schedules.service';
import { ListTemplate } from '../models/list-template';
import * as moment from 'moment';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { DocumentGeneration } from 'src/app/core/models/document-generation';
import { phoneNumberStringFormatter } from 'src/app/core/utils/string-formatters';
import { HealthInsuranceModel } from 'src/app/core/models/health-insurance.model';
import { ExportModalComponent } from '../modals/export-modal/export-modal.component';
import {ExamsRequestService} from "../../core/services/exams-request.service";

@Component({
  selector: 'app-list-renderer',
  templateUrl: './list-renderer.component.html',
  styleUrls: ['./list-renderer.component.scss']
})
export class ListRendererComponent implements OnInit {

  @Input() listTemplate!: ListTemplate;
  @Output() onNextPage = new EventEmitter<number>();
  @ViewChild('searchbar') input!: ElementRef;
  @ViewChild('date') date!: ElementRef;
  @ViewChild('dateStart') dateStart!: ElementRef;
  @ViewChild('dateEnd') dateEnd!: ElementRef;

  signing: boolean = false;
  page: number = 1;
  searchAdvancedBool: boolean = false;
  mediaQuery: boolean = false;
  patientTagsList: any[] = [];
  selectedPatientTags: any[] = [];
  isPatientDocuments: boolean = false;
  isPatientSchedulings: boolean = false;
  isCharts: boolean = false;
  schedulesList: Schedule[] = [];
  scheduleFilterIsOpened: boolean = false;
  selectedSchedule: Schedule[] = [];
  allowedSchedule: Schedule[] = [];
  labelSchedule: string = 'Todas as agendas';
  labelStatus: string = 'Nenhum status selecionado';
  statusFilterIsOpened: boolean = false;

  isClinicStaff = JSON.parse(localStorage.getItem('isClinicStaff')!);

  selectedStatus: any[] = [];

  statusList = [{ name: '', value: '' }];

  statusExamList = [
    { value: 'SEND', name: 'Enviar' },
    { value: 'DELIVERED', name: 'Entregue' },
    { value: 'IN_PROGRESS', name: 'Em andamento' },
    { value: 'DONE', name: 'Exames realizados' },
    { value: 'CONCLUDED', name: 'Concluído' },
    { value: 'CANCELED', name: 'Cancelado' }
  ];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private modalService: NgbModal,
    private registersService: RegistersService, private scheduleService: ScheduleService, private renderer: Renderer2,
              private toast: ToastrService, private examRequestService: ExamsRequestService) {
    let mediaQuery = window.matchMedia("(min-width: 768px)");
    fromEvent<MediaQueryList>(mediaQuery, 'change')
      .pipe(
        startWith(mediaQuery),
        map((list: MediaQueryList) => list.matches)
      ).subscribe((matches) => {
        this.mediaQuery = matches;
      });
  }

  ngOnInit(): void {
    this.listTemplate.is_active = true;
    if (this.listTemplate.searchBarSchedule) {
      this.scheduleService.all().subscribe(response => {
        this.schedulesList = response;
        this.toggleAllSchedule();
      });
    }
    if (this.listTemplate.showAdvancedSearch) {
      this.registersService.tagGetAll("patient").subscribe(response => this.patientTagsList = response);
    }
    if (this.listTemplate.showInfo == undefined) {
      this.listTemplate.showInfo = true;
    }
    if (this.listTemplate.count == undefined) {
      this.listTemplate.count = true;
    }
    if (this.router.url.split('/')[5] == 'patientDocuments' || this.router.url.split('/')[5] == 'patientExams') {
      this.isPatientDocuments = true;
    } else this.isPatientDocuments = false;

    if (this.router.url.split('/')[5] == 'patientSchedulings') {
      this.isPatientSchedulings = true;
    } else this.isPatientSchedulings = false;

    if (this.router.url.split('/')[2] == 'charts') {
      this.isCharts = true;
    } else this.isCharts = false;

    if (this.router.url.split('/')[3] == 'exams') {
      this.statusList = this.statusExamList;
    } else {
      this.statusList = [
        { name: 'Disponível', value: 'AVAILABLE' },
        { name: 'Ocupado', value: 'OCCUPIED' }
      ];
    }

  }

  getWidth(index: number): string {
    if (this.mediaQuery) {
      return this.listTemplate.header![index].widthInPercentage ?? '10%';
    } else {
      return this.listTemplate.header![index].width ?? '100px';
    }
  }

  onClickActive() {
    this.page = 1;
    if (this.listTemplate.onClickActive)
      this.listTemplate.onClickActive();
  }

  get isActiveText() {
    if (this.listTemplate.is_active)
      return "Ver desativados";
    return "Ver ativos";
  }

  ngAfterViewInit() {
    if (this.router.url.split('/')[2] == 'charts') {
      let today = moment().format('YYYY-MM-DD');
      this.renderer.setAttribute(this.date.nativeElement, 'value', today);
    }
    if (this.listTemplate.showSearch && this.listTemplate.searchBarPlaceholder) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap((text) => {
            if (this.listTemplate.onSearchFocusOut) {
              this.page = 1;
              this.listTemplate.onSearchFocusOut(this.input.nativeElement.value);
            }
          })
        )
        .subscribe();
    }
  }

  getSearchDate() {
    if (this.listTemplate.onDateFocusOut) {
      this.listTemplate.onDateFocusOut(this.date.nativeElement.value);
    }
  }

  getSearchDateRange() {
    let dateStart = this.dateStart.nativeElement.value;
    let dateEnd = this.dateEnd.nativeElement.value;
    if (this.listTemplate.onDateRangeFocusOut) {
      let data = {
        start: dateStart,
        end: dateEnd
      }
      this.listTemplate.onDateRangeFocusOut(data);
    }
  }

  isSignable(columnIndex: number, row: any, type: string) {
    let typeValue = this.getKeyByValue(columnIndex, row, type)
    if (typeValue == 'MEDICAL_REPORT') return 'Assinar'
    return ''
  }

  signHandle(event: any, index: number) {
    if (sessionStorage.getItem("birdid_account") == null) {
      this.toast.error("Conecte-se ao BirdID", "Erro");
      return;
    }
    this.signing = true;
    let data: {
      id: number
      patient__id: number
    } = this.listTemplate.data!.results![index];
    let dataSign = {
      birdid_account: sessionStorage.getItem("birdid_account"),
      exams_id: [data.id],
    };
    this.registersService.signExam(data.patient__id, dataSign).subscribe(response => {
      this.toast.success("Documento enviado para assinatura", "Assinando");
      this.storeDocuments(response);
      this.signing = false;
    }, (error) => {
      this.toast.error("Documento não pôde ser assinado", "Erro");
      this.signing = false;
    });
  }

  canReemitDocument(columnIndex: number, row: any, type: string) {
    let value = this.getKeyByValue(columnIndex, row, type);
    return value == 'MEDICAL_REPORT';
  }

  reemitDocument($event: any, rowIndex: number) {
    let { id } = this.listTemplate.data!.results![rowIndex];
    this.registersService.reemitMedicalReport(id).subscribe({
      next: response => {
        this.toast.success('Documento está sendo reemitido', 'Emitindo documento');
        this.storeDocuments([response]);
      }
    });
  }

  storeDocuments(documentsToBeStored: DocumentGeneration[]) {
    let documentsInSessionStorage = sessionStorage.getItem('documents') || '';
    let storedDocuments = [];
    if (documentsInSessionStorage) {
      storedDocuments = JSON.parse(documentsInSessionStorage)
    }
    storedDocuments.push(...documentsToBeStored);
    sessionStorage.setItem('documents', JSON.stringify(storedDocuments));
  }

  searchAdvanced() {
    this.searchAdvancedBool = !this.searchAdvancedBool;
  }

  openScheduleFilter() {
    this.scheduleFilterIsOpened = true;
  }

  closeScheduleFilter() {
    this.scheduleFilterIsOpened = false;
  }


  openStatusFilter() {
    this.statusFilterIsOpened = true;
  }

  closeStatusFilter() {
    this.statusFilterIsOpened = false;
  }

  toggleAllStatus() {
    if (!this.selectedStatus) {
      this.selectedStatus = this.statusList;
      this.statusList.forEach(item => this.statusIsSelected(item));
      this.labelSchedule = 'Todas os status';
    } else if (this.selectedStatus.length != this.statusList.length) {
      this.statusList.forEach(item => {
        if (!this.selectedStatus.some(value => value.name == item.name))
          this.selectedStatus.push(item);
        this.statusIsSelected(item);
      });
      this.labelStatus = 'Todas os status';
    } else {
      this.selectedStatus = [];
      this.labelStatus = 'Nenhum status selecionado';
    }
    let idsMap = this.selectedStatus.map((item) => {
      return item.value;
    });
    let idsStr = idsMap.join(',');
    if (this.listTemplate.onScheduleFocusOut) this.listTemplate.onScheduleFocusOut(idsStr);
  }

  toggleStatus(status: any) {
    if (this.statusIsSelected(status)) {
      this.selectedStatus = this.selectedStatus.filter(value => status.value != value.value);
    } else {
      this.selectedStatus.push(status);
    }
    let valueMap = this.selectedStatus.map((item) => {
      return item.value;
    });
    let valueStr = valueMap.join(',');
    if (this.listTemplate.onScheduleFocusOut) this.listTemplate.onScheduleFocusOut(valueStr);
    let nameMap = this.selectedStatus.map((item) => {
      return item.name;
    });

    if (nameMap.length == 0) {
      this.labelStatus = 'Nenhum status selecionado';
    } else if (nameMap.length == this.statusList.length) {
      this.labelStatus = 'Todos os status';
    } else {
      this.labelStatus = nameMap.join(', ');
    }
  }

  statusIsSelected(status: any) {
    return this.selectedStatus.some(value => status.name == value.name);
  }

  toggleSchedule(schedule: Schedule) {
    if (this.scheduleIsSelected(schedule)) {
      this.selectedSchedule = this.selectedSchedule.filter(value => schedule.id != value.id);
    } else {
      this.selectedSchedule.push(schedule);
    }
    let idsMap = this.selectedSchedule.map((item) => {
      return item.id;
    });
    let idsStr = idsMap.join(',');
    if (this.listTemplate.onScheduleFocusOut) this.listTemplate.onScheduleFocusOut(idsStr);
    let nameMap = this.selectedSchedule.map((item) => {
      return item.name;
    });
    if (nameMap.length == 0) {
      this.labelSchedule = 'Nenhuma agenda selecionada';
    } else if (nameMap.length == this.schedulesList.length) {
      this.labelSchedule = 'Todas as agendas';
    } else {
      this.labelSchedule = nameMap.join(',');
    }
  }

  toggleAllSchedule() {
    if (!this.selectedSchedule) {
      this.selectedSchedule = this.schedulesList;
      this.schedulesList.forEach(item => this.scheduleIsSelected(item));
      this.labelSchedule = 'Todas as agendas';
    } else if (this.selectedSchedule.length != this.schedulesList.length) {
      this.schedulesList.forEach(item => {
        if (!this.selectedSchedule.some(value => value.id == item.id))
          this.selectedSchedule.push(item);
        this.scheduleIsSelected(item);
      });
      this.labelSchedule = 'Todas as agendas';
    } else {
      this.selectedSchedule = [];
    }
    let idsMap = this.selectedSchedule.map((item) => {
      return item.id;
    });
    let idsStr = idsMap.join(',');
    if (this.listTemplate.onScheduleFocusOut) this.listTemplate.onScheduleFocusOut(idsStr);
  }

  scheduleIsSelected(schedule: Schedule) {
    return this.selectedSchedule.some(value => schedule.id == value.id);
  }

  getKeyByValue(columnIndex: number, object: any, value: string): string | undefined {
    if (!object[value])
      return '-';
    if (this.listTemplate.header![columnIndex].displayStringConvertionFunction)
      return this.listTemplate.header![columnIndex].displayStringConvertionFunction!(object[value]);
    else
      return object[value];
  }

  getFirstPhoneNumber(columnIndex: number, object: any, value: string, format = true): string {
    if (object[value][0]) {
      if (this.listTemplate.header![columnIndex].displayStringConvertionFunction && format) {
        let phone_number = `${object["phone_numbers"][0]['country_code']} ${object[value][0]['phone_number']}`;
        return phoneNumberStringFormatter(phone_number);
      }
      else
        return `${object["phone_numbers"][0]['country_code']}${object[value][0]['phone_number']}`;
    }
    else return "-";
  }

  getPhoneNumberPatientFormatter(object: any) {
    let phone_number = object['patient__phone_number'];
    if (phone_number) {
      return phoneNumberStringFormatter(phone_number);
    } else return '-';
  }

  getPhoneNumberPatient(columnIndex: number, object: any, value: string) {
    return object['patient__phone_number']
  }

  isPhoneNumberWhatsapp(object: any, value: string): boolean {
    if (object[value][0]) {
      if (object[value][0]['type'] == "WHATSAPP") return true;
      else return false;
    }
    else return false;
  }

  whatsappButtonClickHandle(phoneNumber: string) {
    window.open(`//wa.me/${phoneNumber}`, "_blank");
  }

  rowClickHandle(event: Event, index: number) {
    const element = event.target! as HTMLElement;
    if (element.nodeName === "BUTTON") {
    }
    else if (index != undefined) {
      let object = this.listTemplate.data?.results?.find(item => item.id == index);
      if (this.isCharts) {
        this.router.navigate([`/dashboard/charts/edit/${object.patient_id}`], {
          queryParams: {
            date: object.date_time
          }
        });
      } else if (this.isPatientDocuments) {
        window.open(object.file, "_blank");
      } else if (this.isPatientSchedulings) {
        this.router.navigate([`/dashboard/schedules/${object.schedule_id}/edit-event/${object.id}`], {
          queryParams: {
            patientId: object.patient.id,
            attendanceTimeId: object.attendance_time_id
          }
        });
      } else {
        this.router.navigate(['edit', index], { relativeTo: this.activatedRoute });
      }
    }
  }

  getHealthInsurancesList(index: number) {
    var healthInsurancePlans: string[] = [];
    if (this.listTemplate.data?.results![index].health_insurances) {
      this.listTemplate.data?.results![index].health_insurances.forEach((element: HealthInsuranceModel) => {
        healthInsurancePlans.push(element.health_insurance__name! + ' ' + element.health_insurance_plan__name!);
      });
    }
    if (healthInsurancePlans.length > 0) {
      return healthInsurancePlans.join(', ');
    } else return '-';
  }

  getClinicName(index: number) {
    if (this.listTemplate.data!.results![index].type == "COMPANY") {
      return this.listTemplate.data!.results![index].display_name;
    } else {
      return this.listTemplate.data!.results![index].name;
    }
  }

  getLeadsTags(index: number) {
    var tags: string[] = [];
    if (this.listTemplate.data?.results![index].tags) {
      this.listTemplate.data?.results![index].tags.map((element: any) => {
        let foundItem = this.patientTagsList.find((tag: any) => tag.id == element);
        if (foundItem)
          tags.push(foundItem.name);
      });
    }
    if (tags.length > 0) {
      return tags.join(', ');
    } else return '-';
  }

  redirectToChart(obj: any) {
    this.router.navigate([`/dashboard/charts/edit/${obj.patient.id}`],
      { queryParams: { date: obj.date_time } });
  }

  getStatusOximeter(object: any) {
    if (object.status == 'AVAILABLE') {
      return 'Disponível';
    } else if (object.status == 'OCCUPIED') {
      return 'Ocupado';
    } else return '-';
  }

  getStatusExam(object: any) {
    return this.statusExamList.find(item => item.value == object.status)?.name;
  }

  getCaseSensitive(index: number) {
    let case_sensitive: string = '-';
    if (this.listTemplate.data?.results![index].case_sensitive) {
      case_sensitive = 'Sim';
    } else case_sensitive = 'Não';
    return case_sensitive;
  }

  getExamNights(index: number) {
    let nights = this.listTemplate.data?.results![index].exam_nights;
    let remaining_nights = this.listTemplate.data?.results![index].remaining_nights;
    let nights_done = nights - remaining_nights;

    return `${nights} (${nights_done})`;
  }

  getContains(index: number) {
    let contains: string = '-';
    if (this.listTemplate.data?.results![index].contains) {
      contains = 'Sim';
    } else contains = 'Não';
    return contains;
  }

  getDate(index: number, obj: any, name: any) {
    if (obj[name]) {
      return moment(obj[name]).format('DD/MM/YYYY HH:mm');
    } else return '-';
  }

  getDateSentAt(index: number, obj: any, name: any) {
    if (obj[name]) {
      return moment(obj[name]).format('DD/MM/YYYY');
    } else return '-';
  }

  onHeaderClick(orderBy: string) {
    this.page = 1;
    this.listTemplate.onHeaderItemClick!(orderBy);
  }

  exportCSV() {
    var modal = this.modalService.open(ExportModalComponent, { centered: true });
    modal.componentInstance.fields = this.listTemplate.exportOptions;
    modal.componentInstance.url = this.listTemplate.exportUrl;
  }

  get hasNext() {
    return this.listTemplate.hasNext;
  }

  nextPage() {
    this.onNextPage.emit(++this.page);
  }

  isTagsSelected(item: any) {
    return this.selectedPatientTags.some(id => item == id);
  }

  toggleTags(item: any) {
    if (this.isTagsSelected(item)) {
      this.selectedPatientTags = this.selectedPatientTags.filter(id => id != item);
    } else {
      this.selectedPatientTags.push(item);
    }
    let idsMap = this.selectedPatientTags.map((item) => {
      return item;
    });
    let idsStr = idsMap.join(',');
    if (this.listTemplate.onAdvancedSearchFocusOut) this.listTemplate.onAdvancedSearchFocusOut(idsStr);
  }

  getPaymentStatusClass(paymentStatus: any): string {
    if (paymentStatus.payment_status == 'APPROVED') {
      return 'text-success';
    } else if (paymentStatus.payment_status == 'PENDING') {
      return 'text-warning';
    } else if (paymentStatus.payment_status == 'OVERDUE') {
      return 'text-danger';
    }
    return '';
  }

  getPaymentStatus(request: any): string {
    if (request.payment_status == 'APPROVED') {
      return 'Aprovado';
    } else if (request.payment_status == 'PENDING') {
      return 'Pendente';
    } else if (request.payment_status == 'OVERDUE') {
      return 'Vencido';
    }
    return '';
  }

  openInvoice(data: any) {
    if (data.invoice_url) {
      window.open(data.invoice_url, "_blank");
    }
  }

  resendConfirmationFlow(request: any) {
    this.examRequestService.resendConfirmationFlow(request.id).subscribe({
      next: response => {
        this.toast.success('Confirmação reenviada', 'Sucesso');
      }, error: error => {
        this.toast.error('Tivemos um erro ao reenviar confirmação', 'Erro');
      }
    });
  }
}
