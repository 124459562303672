<div *ngIf="!loadingPage else loadingTemplate" [formGroup]="formGroup">
  <div class="row separator">
    <div class="col card">
      <div class="col px-0">
        <h3 class="mb-4">Dados da operadora</h3>
      </div>

      <div class="row px-0">
        <div class="col-12 col-lg-2">
          <app-image-input id="image" height="150px" [disabled]="formGroup.disabled" (onImageChange)="onImageSelect($event)"
            [value]="fetchedHealthInsuranceData.image ?? ''">
          </app-image-input>
        </div>
        <div class="col p-0">
          <div class="row">
            <div class="col-6 p-0">
              <app-basic-input id="name" label="Nome" controlName="name" [formGroup]="formGroup"></app-basic-input>
            </div>
            <div class="col-6 pr-0">
              <label class="field__title" for="type">Tipo</label>
              <ng-select
                [ngClass]="formGroup.get('type')!.errors && formGroup.get('type')!.touched ? 'error' : 'custom'"
                [searchable]="false" [clearable]="false" id="type" formControlName="type">
                <ng-option value="MEDICAL">Médico</ng-option>
                <ng-option value="DENTAL">Odontológico</ng-option>
              </ng-select>
              <app-form-error id="type" [formGroup]="formGroup" controlName="type"></app-form-error>
            </div>
          </div>

          <div class="row">
            <div class="col-4 p-0 mt-4">
              <app-basic-input id="name" label="Código ANS" controlName="code" [formGroup]="formGroup"
                [maskOptions]="{mask: '000000'}"></app-basic-input>
            </div>

            <div class="col-4 mt-4 pr-0">
              <app-basic-input id="font_color" controlName="font_color" [formGroup]="formGroup" label="Cor do texto"
                                 type="color"></app-basic-input>
            </div>

            <div class="col-4 mt-4 pr-0">
              <app-basic-input id="background_color" controlName="background_color" [formGroup]="formGroup"
                               label="Cor de fundo" type="color"></app-basic-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator">
    <div class="col card">
      <div class="col px-0">
        <h3 class="mb-4">Planos</h3>
      </div>

      <div class="row px-0" formArrayName="plans">
        <ng-container *ngFor="let plan of healthInsurancePlanForms.controls; index as i">
          <div class="row p-0 col-12" [class]="{'d-none': !healthInsurancePlanIsActive(i)}">
            <div class="col-6 p-0 mb-3">
              <app-basic-input id="name" label="Nome do plano" controlName="name"
                [formGroup]="getHealthInsurancePlanForm(i)">
              </app-basic-input>
            </div>

            <div class="px-3 col-4 mb-3" [formGroup]="getHealthInsurancePlanForm(i)">
              <label class="field__title" for="refund">Reembolso</label>
              <ng-select [ngClass]="plan.get('refund')!.errors && plan.get('refund')!.touched ? 'error' : 'custom'"
                [searchable]="false" [clearable]="false" id="refund" formControlName="refund">
                <ng-option value="TOTAL">Amplo</ng-option>
                <ng-option value="RESTRICTED">Restrito</ng-option>
                <ng-option value="NONEXISTENT">Inexistente</ng-option>
              </ng-select>
              <app-form-error id="refund" [formGroup]="getHealthInsurancePlanForm(i)" controlName="refund">
              </app-form-error>
            </div>

            <div class="p-0 col-2">
              <button (click)="removeHealthInsurancePlan(i)" [disabled]="formGroup.disabled"
                class="remove-button">Remover</button>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="row">
          <div class="col-12 d-flex align-items-center">
            <button class="btn btn-link" [disabled]="formGroup.disabled" (click)="addHealthInsurancePlan()">Adicionar
              novo</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="row justify-content-end mt-5 mb-5">
  <div class="col-12 col-md-3 mb-3">
    <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
      Cancelar
    </button>
  </div>

  <div class="col-12 col-md-3 mb-3" *ngIf="button">
    <button (click)="removeHandler()" [disabled]="submittingRemove || !canRemove" class="btn btn-secondary btn-lg"
      type="button">
      {{removeButton}}
      <span class="spinner-border" *ngIf="submittingRemove"></span>
    </button>
  </div>

  <div class="col-12 col-md-3 mb-3">
    <button (click)="submitHandler()" [disabled]="submitting || !canSave" class="btn btn-primary btn-lg" type="button">
      {{submitButton}}
      <span class="spinner-border" *ngIf="submitting"></span>
    </button>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
