<div *ngIf="!loadingPage else loadingTemplate">
  <div class="card">
    <form>
      <div class="row d-flex justify-content-between">
        <div class="col">
          <basic-input label="Nome da agenda" [formGroup]="formGroup" id="name"></basic-input>
        </div>

        <div class="col">
          <basic-input label="URL de agendamento" [formGroup]="formGroup" id="slug"></basic-input>
        </div>

        <div class="row p-0 col-6 schedule-dates-wrapper mb-3">
          <div class="pl-0 col-6">
            <date-input label="Início" [formGroup]="formGroup" id="since"></date-input>
          </div>

          <div class="pl-0 col-6">
            <date-input label="Fim" [formGroup]="formGroup" id="until"></date-input>
          </div>
        </div>

        <div class="row col-12 mt-4" *ngIf="isActive">
          <h2 class="col-12 px-0 mb-4">Definição do período</h2>

          <div class="calendar">
            <div class="calendar-toolbar mb-3">
              <form [formGroup]="calendarFormGroup" class="row d-flex align-items-end" (submit)="createPeriod($event)">
                <div class="p-0 col-3">
                  <label for="weekday">Dia da semana</label>
                  <ng-select id="weekday" formControlName="weekday" class="custom" [searchable]="false"
                    [clearable]="false" (click)="selectedToAction = []">
                    <ng-option value="1">Segunda-feira</ng-option>
                    <ng-option value="2">Terça-feira</ng-option>
                    <ng-option value="3">Quarta-feira</ng-option>
                    <ng-option value="4">Quinta-feira</ng-option>
                    <ng-option value="5">Sexta-feira</ng-option>
                    <ng-option value="6">Sábado</ng-option>
                    <ng-option value="0">Domingo</ng-option>
                  </ng-select>
                </div>

                <div class="pr-0 col-2">
                  <label for="start_time">Início do período</label>
                  <ngb-timepicker id="start_time" formControlName="start_time"></ngb-timepicker>
                </div>

                <div class="pr-0 col-2">
                  <label for="end_time">Fim do período</label>
                  <ngb-timepicker id="end_time" formControlName="end_time"></ngb-timepicker>
                </div>

                <div class="pr-0 col-2">
                  <label for="duration">Duração (minutos)</label>
                  <input id="duration" type="number" formControlName="duration" class="form-control">
                </div>

                <div class="col pr-0">
                  <button class="btn btn-success btn_add" type="submit" value="Adicionar">Adicionar</button>
                </div>
              </form>

              <div class="row d-flex align-items-end mt-3">
                <div class="col-2 pl-0 ">
                  <button class="btn btn-danger" (click)="remove()">Apagar</button>
                </div>

                <div class="col-2 pl-0 ">
                  <button class="btn btn-primary" (click)="merge()"
                    [disabled]="selectedToAction.length < 2 || !isSequencial">Mesclar
                  </button>
                </div>

                <div class="col-5 p-0 ">
                  <div class="row d-flex align-items-end">
                    <div class="col-5 p-0 ">
                      <label for="firstSplitTime">Primeiro atendimento (min)</label>
                      <input type="number" id="firstSplitTime" [formControl]="firstSplitTime">
                    </div>

                    <div class="col-7 btn-dividir">
                      <button class="btn btn-info" (click)="split()" [disabled]="selectedToAction.length != 1">Dividir
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-3 pr-0">
                  <button class="btn btn-lg btn-primary" (click)="open(content)">Tipo de atendimento</button>
                </div>
              </div>

              <div class="calendar-header">
                <div class="row">
                  <div class="cell cell--title time">&nbsp;</div>

                  <div class="cell cell--title">
                    <p class="text-center weekday">Segunda</p>
                  </div>

                  <div class="cell cell--title">
                    <p class="text-center weekday">Terça</p>
                  </div>

                  <div class="cell cell--title">
                    <p class="text-center weekday">Quarta</p>
                  </div>

                  <div class="cell cell--title">
                    <p class="text-center weekday">Quinta</p>
                  </div>

                  <div class="cell cell--title">
                    <p class="text-center weekday">Sexta</p>
                  </div>

                  <div class="cell cell--title">
                    <p class="text-center weekday">Sábado</p>
                  </div>

                  <div class="cell cell--title">
                    <p class="text-center weekday">Domingo</p>
                  </div>
                </div>
              </div>

              <div class="calendar-body">
                <div class="row" *ngFor="let hour of timeline; let index = index">
                  <div class="cell cell--time">
                    <span>{{ hour }}</span>
                  </div>

                  <div class="cell" *ngFor="let weekday of ['1', '2', '3', '4', '5', '6', '0']">
                    <div class="available-attendance" *ngFor="let availableAttendance of getEvents(weekday, hour)"
                      [class]="{'is-selected': isSelected(availableAttendance)}" (click)="select(availableAttendance)">
                      <p>{{ availableAttendance.start_time }} - {{ availableAttendance.duration }}min</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="row justify-content-end">
    <div class="col-sm-12 col-md-3 col-lg-2 mt-5 mb-5">
      <button (click)="cancelHandler()" [disabled]="submitting" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-sm-12 col-md-3 mt-md-5 mb-5" *ngIf="button">
      <button class="btn btn-secondary btn-lg" [disabled]="submittingRemove || disabledRemover" (click)="removeHandler()">
        {{removeButton}}
        <span *ngIf="submittingRemove" class="spinner-border" role="status" aria-hidden="true"></span>
      </button>
    </div>

    <div class="col-sm-12 col-md-3 mt-md-5 mb-5">
      <button (click)="submitHandler()" class="btn btn-primary btn-lg" [disabled]="submitting" type="button">
        {{submitButton}}
        <span *ngIf="submitting" class="spinner-border" role="status" aria-hidden="true"></span>
      </button>
    </div>
  </div>
</div>


<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #content let-modal class="modal modal-content">
  <div class="modal-body" [formGroup]="calendarFormGroup">
    <h4>Tipos de atendimento</h4>
    <div class="checkbox-option">
      <label>
        <input type="checkbox" [checked]="isCheckedAll" (change)="onSelectAll($event)" /> Selecionar Todos</label>
    </div>
    <div class="row" *ngFor="let type of attendanceCheckedList">
      <div class="col checkbox-option p-0">
        <label [for]="type.id">
          <input type="checkbox" [value]="type.id" [id]="type.id" [checked]="type.checked"
            (change)="onCheckChange(type)" />
          {{ type.name }}
        </label>
      </div>
    </div>

  </div>
  <div class="row justify-content-end buttons">
    <button class="btn btn-link col-4 " (click)="modal.dismiss()">Cancelar</button>
    <button class="btn btn-primary col-4" (click)="updateAllowedAttendanceType()">Salvar</button>
  </div>
</ng-template>
