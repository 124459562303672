import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormGroup} from '@angular/forms';
import { MaskOptions } from '../../models/form-input';

@Component({
  selector: 'app-basic-input',
  templateUrl: './basic-input.component.html',
  styleUrls: ['./basic-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppBasicInputComponent implements OnInit {
  @Input() id!: string;
  @Input() formGroup!: FormGroup | any;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() labelPosition: string = 'top';
  @Input() maskOptions!: MaskOptions;
  @Input() removeVerticalSpacing: boolean = false;
  @Input() capitalize: boolean = false;
  @Input() showError: boolean = true;
  @Input() dropSpecialCharacters: boolean = true;
  @Input() controlName: string = '';
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() type: string = 'text';
  @Input() max: string = '';
  @Input() min: string = '';

  @Output() onValueChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  checkKey(e: any) {
    if (this.capitalize) {
      var regex = new RegExp("^[a-zA-Z0-9 ]+$");
      var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(str)) {
        return true;
      }

      e.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  focusOutHandle(e: any) {
    if (this.formGroup.get(this.id)?.valid) {
      this.onValueChange.emit(this.formGroup.get(this.id)?.value);
    }
  }
}
