<div *ngIf="!loadingPage else loadingTemplate" [formGroup]="formGroup">
  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Dados da clínica</h3>
        </div>

        <div class="col-12 col-lg-3">
          <image-input id="profile_image" [disabled]="formGroup.disabled" (onImageChange)="onImageSelect($event)">
          </image-input>
        </div>

        <div class="col p-0">
          <div class="row p-0 px-2">
            <div class="col-12 radio-type mb-3">
              <label>Tipo</label>

              <div class="radio-option">
                <label for="pessoa_juridica"><input type="radio" formControlName="type" value="COMPANY"
                    (click)="onSubtypeSelected('COMPANY')" id="pessoa_juridica" /> Pessoa jurídica</label>
              </div>

              <div class="radio-option">
                <label for="pessoa_fisica"><input type="radio" formControlName="type" value="PERSONAL"
                    id="pessoa_fisica" (click)="onSubtypeSelected('PERSONAL')" />
                  Pessoa física</label>
              </div>
            </div>

            <div class="col-sm-5 mb-3">
              <app-basic-input id="document_number" controlName="document_number" [formGroup]="formGroup"
                [label]="labelCpfCnpj" [maskOptions]="{mask: mask}"></app-basic-input>
            </div>

            <div class="col-sm-7 pl-1 mb-3">
              <app-basic-input id="name" controlName="name" [formGroup]="formGroup" [label]="labelName">
              </app-basic-input>
            </div>

            <div class="col-sm-5 mb-3">
              <app-basic-input id="display_name" controlName="display_name" [formGroup]="formGroup"
                [label]="labelDisplay"></app-basic-input>
            </div>

            <div class="col-sm-4 pl-1 mb-3">
              <app-basic-input id="email" controlName="email" [formGroup]="formGroup" label="Email"></app-basic-input>
            </div>

            <div class="col-sm-3 pl-1 mb-3">
              <app-basic-input id="cro" controlName="cro" [formGroup]="formGroup" label="CRO"></app-basic-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" *ngIf="isClinicStaff">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Usuário Master</h3>
        </div>
        <div class="px-2 col-12">
          <app-select-input id="master_user" [formGroup]="formGroup" [options]="accountList"
                            [clearable]="true"></app-select-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" formArrayName="exams" *ngIf="canEditExams">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Exames</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <label class="field__title">Exame</label>
        </div>

        <div class="col-2">
          <label class="field__title">Custo</label>
        </div>

        <div class="col-2">
          <label class="field__title">Alíquota (%)</label>
        </div>

        <div class="col-2" *ngIf="!isClinicStaff">
          <label class="field__title">Valor Sugerido</label>
        </div>
      </div>

      <div class="row">
        <ng-container *ngFor="let examForm of examForms.controls; index as i">
          <div class="row col-12 p-0 mb-3" [formGroup]="getExamForm(i)" *ngIf="showExam(i)">
            <div class="px-2 col-4" *ngIf="unSelectedExamList">
              <ng-select id="exam_id" formControlName="exam_id" [bindValue]="examIdBindValue" bindLabel="description"
                         [items]="unSelectedExamList" [clearable]="false" (change)="treatClinicExams(i)"
                         [ngClass]="examForm.get('exam_id')?.errors && examForm.get('exam_id')?.touched ? 'error' : 'custom'">
              </ng-select>
              <app-form-error id="exam_id" [formGroup]="getExamForm(i)" controlName="exam_id">
              </app-form-error>
            </div>

            <div class="px-2 col-2">
              <input id="value" [formGroup]="getExamForm(i)" (keyup)="setClinicMinimumValue(i)"
                     [readonly]="!isClinicStaff" formControlName="value"
                     currencyMask [options]="{prefix: '', thousands: '.', decimal: ','}">
              <app-form-error id="value" [formGroup]="getExamForm(i)" controlName="value"></app-form-error>
            </div>

            <div class="px-2 col-2">
              <input id="aliquot" [formGroup]="getExamForm(i)" [readonly]="!isClinicStaff"
                     (keyup)="setClinicMinimumValue(i)" formControlName="aliquot"
                     currencyMask [options]="{prefix: '', thousands: '.', decimal: ','}">
              <app-form-error id="aliquot" [formGroup]="getExamForm(i)" controlName="aliquot"></app-form-error>
            </div>

            <div class="px-2 col-2" *ngIf="!isClinicStaff">
              <input id="clinic_value" [formGroup]="getExamForm(i)"
                     formControlName="clinic_value" currencyMask [options]="{prefix: '', thousands: '.', decimal: ','}">
              <app-form-error id="clinic_value" [formGroup]="getExamForm(i)" controlName="clinic_value"></app-form-error>
            </div>

            <div class="px-2 col-2">
              <button (click)="removeExam(i)" [disabled]="submitting" class="remove-button">Remover</button>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-link" [disabled]="submitting" (click)="addExam()">
              Adicionar novo
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" *ngIf="isClinicStaff">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Profissionais</h3>
        </div>
        <div class="px-2 col-9">
          <ng-container>
            <ng-select [formGroup]="formGroup" formControlName="professionals"
              [ngClass]="formGroup.get('professionals')!.errors ? 'error' : 'custom'" notFoundText="Não encontrado"
              [addTag]="false">
              <ng-option *ngFor="let item of professionalListAll" [value]="item.id">
                {{item.name}} - {{item.cro}}
              </ng-option>
            </ng-select>
          </ng-container>
          <app-form-error id="professionals" [formGroup]="formGroup" controlName="professionals">
          </app-form-error>
        </div>
        <div class="col-3 d-flex align-items-center">
          <button (click)="setProfessional()" class="btn btn-link" [disabled]="formGroup.disabled">Adicionar
            novo</button>
        </div>
        <div class="col-12">
          <p *ngFor="let item of professionalList">
            {{item.name}} - {{item.cro}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" *ngIf="isClinicStaff">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Tags</h3>
        </div>
        <div class="px-2 col-12">
          <ng-select id="tags" [items]="clinicTagsList" bindLabel="name" bindValue="id" [hideSelected]="true"
            [multiple]="true" [ngClass]="formGroup.get('tags')!.errors ? 'error' : 'custom'" [clearable]="false"
            addTagText="Adicionar novo" notFoundText="Não encontrado" formControlName="tags">

            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span class="ng-value-label">{{item.name}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="false">×</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <span class="ng-value-label">{{item.name}}</span>
            </ng-template>
          </ng-select>
          <app-form-error id="tags" [formGroup]="formGroup" controlName="tags">
          </app-form-error>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Áreas de atuação</h3>
        </div>
        <div class="px-2 col-12">
          <ng-select id="specialties" [items]="specialtiesList" bindLabel="name" bindValue="id" [hideSelected]="true"
            [multiple]="true" [ngClass]="formGroup.get('specialties')!.errors ? 'error' : 'custom'" [clearable]="false"
            addTagText="Adicionar novo" notFoundText="Não encontrado" formControlName="specialties">

            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span class="ng-value-label">{{item.name}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="false">×</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <span class="ng-value-label">{{item.name}}</span>
            </ng-template>
          </ng-select>
          <app-form-error id="specialties" [formGroup]="formGroup" controlName="specialties">
          </app-form-error>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" formArrayName="phone_numbers">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Telefone</h3>
        </div>

        <ng-container *ngFor="let phoneNumber of phoneNumberForms.controls; index as i">
          <div class="row col-12" [class]="{'d-none': !phoneNumberIsActive(i)}">
            <div class="col-6 p-0 mb-3">
              <phone-number-input [formGroup]="getPhoneNumberForm(i)"></phone-number-input>
            </div>

            <div class="px-2 col-4 mb-3" [formGroup]="getPhoneNumberForm(i)">
              <label class="field__title" for="type"></label>
              <ng-select
                [ngClass]="phoneNumber.get('phone_number')!.errors && phoneNumber.get('phone_number')!.touched ? 'error' : 'custom'"
                [searchable]="false" [clearable]="false" id="type" formControlName="type">
                <ng-option value="WHATSAPP">Whatsapp</ng-option>
                <ng-option value="COMERCIAL">Comercial</ng-option>
                <ng-option value="RESIDENTIAL">Residencial</ng-option>
              </ng-select>
            </div>

            <div class="px-2 col-2">
              <button (click)="removePhoneNumber(i)" [disabled]="formGroup.disabled"
                class="remove-button">Remover</button>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="row">
          <div class="col-12 d-flex align-items-center">
            <button class="btn btn-link" [disabled]="formGroup.disabled" (click)="addPhoneNumber()">Adicionar
              novo</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Endereço</h3>
        </div>
        <div class="px-2 col-sm-2 zipcode">
          <app-basic-input id="zipcode" [formGroup]="addressFormGroup" label="CEP" controlName="zipcode"
            [maskOptions]="{mask: '00000-000'}" (onValueChange)="handleZipCodeChange($event)">
          </app-basic-input>
          <span *ngIf="searchZipcode" class="spinner-border"></span>
        </div>

        <div class="col-8 px-2 mb-3">
          <app-basic-input id="address" [formGroup]="addressFormGroup" controlName="address" label="Endereço">
          </app-basic-input>
        </div>

        <div class="col-2 px-2 mb-3">
          <app-basic-input id="number" [formGroup]="addressFormGroup" controlName="number" label="Número">
          </app-basic-input>
        </div>

        <div class="col-3 px-2 mb-3">
          <app-basic-input id="complement" [formGroup]="addressFormGroup" controlName="complement" label="Complemento">
          </app-basic-input>
        </div>

        <div class="col-3 px-2 mb-3">
          <app-basic-input id="neighborhood" [formGroup]="addressFormGroup" controlName="neighborhood" label="Bairro">
          </app-basic-input>
        </div>

        <div class="col-3 px-2 mb-3">
          <app-basic-input id="city" [formGroup]="addressFormGroup" controlName="city" label="Cidade"></app-basic-input>
        </div>

        <div class="col-3 px-2 mb-3">
          <app-basic-input id="state" [formGroup]="addressFormGroup" controlName="state" label="Estado">
          </app-basic-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" *ngIf="isMasterUser">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Financeiro</h3>
        </div>
        <div class="px-2 col-12 mb-3">
          <app-basic-input id="wallet_id" [formGroup]="formGroup" [max]="'50'" controlName="wallet_id" label="Wallet ID">
          </app-basic-input>
        </div>
        <div class="px-2 col-6 mb-3">
          <app-basic-input id="pix_key" [formGroup]="formGroup" controlName="pix_key" label="Cheve PIX">
          </app-basic-input>
        </div>
        <div class="px-2 col-6 mb-3">
          <app-select-input id="pix_key_type" label="Tipo da Chave PIX" [clearable]="true"
                            [formGroup]="formGroup" [options]="pixKeyTypes" >
          </app-select-input>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="row justify-content-end mt-5 mb-5">
  <div class="col-12 col-md-3 mb-3">
    <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
      Cancelar
    </button>
  </div>

  <div class="col-12 col-md-3 mb-3" *ngIf="button">
    <button (click)="removeHandler()" [disabled]="submittingRemove" class="btn btn-secondary btn-lg" type="button">
      {{removeButton}}
      <span class="spinner-border" *ngIf="submittingRemove"></span>
    </button>
  </div>

  <div class="col-12 col-md-3 mb-3">
    <button (click)="submitHandler()" [disabled]="submitting" class="btn btn-primary btn-lg" type="button">
      {{submitButton}}
      <span class="spinner-border" *ngIf="submitting"></span>
    </button>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
