import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PermissionConst} from "../../../../../core/models/permission-const.model";
import {SessionManagerService} from "../../../../../core/services/session-manager.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {FinancialStatusModel} from "../../../../../core/models/financial-status.model";
import {FinancialTask} from "../../../../../core/models/financial-task.model";
import {FinancialService} from "../../../../../core/services/financial.service";

@Component({
  selector: 'app-financial-tasks-form',
  templateUrl: './financial-tasks-form.component.html',
  styleUrls: ['./financial-tasks-form.component.scss']
})
export class FinancialTasksFormComponent implements OnInit {
  id?: number;
  loadingPage = false;

  formGroup = this.fb.group({
    id: [],
    name: ['', Validators.required],
    has_due_date: [true],
  });

  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton?: String = "Desativar";
  submitButton?: String = "Salvar";

  button: boolean = false;

  isRegister = true;

  obj?: FinancialTask;

  constructor(private fb: FormBuilder, private sessionManager: SessionManagerService, private toastr: ToastrService,
              private router: Router, private financialService: FinancialService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      this.id = Number(params['id']);
      if (this.id) {
        this.isRegister = false;
      }
    });
    this.initializeForm();
  }

  initializeForm() {
    if(this.id) this.fetch();
    else this.loadingPage = false;
  }

  fetch() {
    if (!this.id) return;
    this.financialService.retrieveTask(this.id).subscribe(response => {
      this.obj = response;
      if (this.obj?.is_active) {
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
        this.button = true;
      } else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
      }
      this.formGroup.patchValue(this.obj);
      this.loadingPage = false;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    })
  }

  canDeactivate() {
    return true;
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_task);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_task);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_task);
  }

  cancelHandler() {
    this.router.navigate(["/dashboard/registers/tasks"]);
  }

  removeHandler() {
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    let data = this.formGroup.getRawValue() as FinancialTask;
    if (!this.formGroup.valid) {
      this.submitting = false;
      return;
    }

    if (this.isRegister) {
      this.register(data);
    } else {
      this.edit(data);
    }
  }

  register(data: FinancialTask) {
    data.id = undefined;
    this.financialService.createTask(data).subscribe(response => {
      this.toastr.success("Tarefa do financeiro criada com sucesso", "Sucesso");
      this.cancelHandler();
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  edit(data: FinancialTask) {
    console.log(data);
    this.financialService.editTask(data).subscribe(response => {
      this.toastr.success("Tarefa do financeiro alterada com sucesso", "Sucesso");
      this.cancelHandler();
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toastr.error(errorResponse.error["detail"], "Erro");
    } else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = ["name", "has_due_date"];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({response: errorResponse.error[name]});
    });
  }
}
