import { Component, OnInit } from '@angular/core';
import {RegistersService} from "../../../../core/services/registers.service";
import {LeadsModel} from "../../../../core/models/leads.model";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {Observable} from "rxjs";
import {DefaultModalComponent} from "../../../renderers/components/default-modal/default-modal.component";

@Component({
  selector: 'app-lead-modal',
  templateUrl: './lead-modal.component.html',
  styleUrls: ['./lead-modal.component.scss']
})
export class LeadModalComponent implements OnInit {
  modalRef?: NgbModalRef;

  leadId?: number;
  lead?: LeadsModel;

  loading = false;

  formGroup: FormGroup = this.fb.group({
    name: [],
    email: [],
    whatsapp: [],
    status: [],
    source: [],
    service_channel: [],
    value: [],
    purchased_services: [],
    campaign: [],
    medium: [],
    content: [],
  });

  constructor(private fb: FormBuilder, private registersService: RegistersService, private toast: ToastrService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    if (this.leadId) {
      this.loading = true;
      this.registersService.leadsGet(this.leadId!).subscribe({
        next: (response) => {
          this.lead = response;
          this.formGroup.patchValue(this.lead);
          this.loading = false;
        },
        error: (error) => {
          console.error(error);
          this.loading = false;
        }
      });
    }
  }

  cancel() {
    this.modalRef?.close();
  }

  submit() {
    this.loading = true;
    let subscription: Observable<LeadsModel>;
    if (this.leadId) {
      subscription = this.registersService.leadsEdit(this.leadId!, this.formGroup.getRawValue());
    } else {
      subscription = this.registersService.leadsRegister(this.formGroup.getRawValue());
    }

    subscription.subscribe({
      next: (response) => {
        this.modalRef?.close({id: this.leadId, ...this.formGroup.getRawValue()});
      },
      error: (error) => {
        this.toast.error('Verifique todos os campos e tente novamente.', 'Erro');
        this.loading = false;
      }
    });
  }

  treatExistingLead(data: any) {
    this.lead = data[0] as LeadsModel;
    this.leadId = this.lead.id;
    this.formGroup.patchValue(this.lead);
    let modalRef = this.modalService.open(DefaultModalComponent);
    modalRef.componentInstance.title = 'Atenção';
    modalRef.componentInstance.text = 'Este lead foi encontrado na base de dados';
    modalRef.result.then(() => {})
  }

  treatLeadIfExists() {
    let name = this.formGroup.get('name')?.value;
    let whatsapp = this.formGroup.get('whatsapp')?.value;
    let email = this.formGroup.get('email')?.value;

    if (!this.leadId && name && whatsapp && email) {
      this.loading = true;
      this.registersService.leadsList({
        'name': name, 'whatsapp': whatsapp, 'email': email}).subscribe(response => {
          if (response.results && response.results.length > 0) {
            this.treatExistingLead(response.results)
          }
        this.loading = false;
      })
    }
  }

}
