<div class="row p-0 m-0 header">
    <div class="col-12">
        <app-header title="Exames" subTitle="Lista de Exames"></app-header>
    </div>
</div>
<div class="row p-0 m-0 content">
    <div class="col-12 p-0">
        <app-list-renderer [listTemplate]="listTemplate" (onNextPage)="loadMore($event)"></app-list-renderer>
    </div>
</div>
