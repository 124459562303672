import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ListModel} from "../models/list.model";
import {environment} from "../../../environments/environment";
import {ClinicExamsRequestModel} from "../models/clinic-exams-request.model";

@Injectable({
  providedIn: 'root'
})
export class ExamsRequestService {

  constructor(private httpClient: HttpClient) { }

  examsRequestList(searchString: string = "", orderingParam: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/clinic_exams_request/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  examsRequestListByClinic(clinicId: number, searchString: string = "", orderingParam: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/clinic_exams_request/?clinic_id=${clinicId}&search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  getExamsRequest(cliniExamRequestId: any): Observable<ClinicExamsRequestModel> {
    return this.httpClient.get<ClinicExamsRequestModel>(`${environment.API_URL}/clinic_exams_request/${cliniExamRequestId}/`)
  }

  examsRequestRegister(data: ClinicExamsRequestModel): Observable<ClinicExamsRequestModel> {
    return this.httpClient.post<ClinicExamsRequestModel>(`${environment.API_URL}/clinic_exams_request/`, data)
  }

  examsRequestEdit(data: ClinicExamsRequestModel): Observable<ClinicExamsRequestModel> {
    return this.httpClient.patch<ClinicExamsRequestModel>(`${environment.API_URL}/clinic_exams_request/${data.id}/`, data)
  }

  examsRequestDelete(cliniExamRequestId: any): Observable<ClinicExamsRequestModel> {
    return this.httpClient.delete<ClinicExamsRequestModel>(`${environment.API_URL}/clinic_exams_request/${cliniExamRequestId}/`)
  }

  resendConfirmationFlow(examRequestId: number) {
    return this.httpClient.post(`${environment.API_URL}/clinic_exams_request/${examRequestId}/resend_flow_to_confirmation/`, null);
  }
}
