import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistersService } from 'src/app/core/services/registers.service';
import { ListTemplate } from 'src/app/shared/renderers/models/list-template';
import {SessionManagerService} from "../../../../../core/services/session-manager.service";

@Component({
  selector: 'app-patient-edit-exam',
  templateUrl: './patient-edit-exam.component.html',
  styleUrls: ['./patient-edit-exam.component.scss']
})
export class PatientEditExamComponent implements OnInit {
  searchString: string = '';
  orderingItem: string = '-created_at';

  isLoading: boolean = true;
  patientId!: number;

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: false,
    showAdvancedSearch: false,
    showInfo: false,
    notFoundMessage: "Nenhum documento encontrado.",
    header: [
      { name: "created_at", label: "Data e hora", widthInPercentage: "15%" },
      { name: "type", label: "Tipo", widthInPercentage: "10%", displayStringConvertionFunction: this.printType },
      { name: "label", label: "Documento", widthInPercentage: "35%" },
      { name: "professional__name", label: "Profissional", widthInPercentage: "20%" },
      { name: 'sendWhatsappExamMessage', label: 'Mensagem', widthInPercentage: '10%' },
      { name: "reemit", label: "Laudo", widthInPercentage: "10%" }
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      this.fetchList(`${invertString}${value}`);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
  };

  constructor(private activatedRoute: ActivatedRoute, private registersService: RegistersService,
              private sessionManagerService: SessionManagerService) { }

  ngOnInit(): void {
    if (!this.sessionManagerService.isExternalClinicUser()) {
      this.listTemplate.header = this.listTemplate.header?.filter(_ => _.name != 'reemit');
    }

    this.activatedRoute.parent?.params.subscribe(params => {
      this.patientId = params['id'];
      this.fetchList('-created_at');
    });
  }

  printType(value: string) {
    if (value == 'ATTACHMENT')
      return 'Anexo';
    return 'Laudo';
  }

  fetchList(orderingItem: string) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;
    let clinicId: number | string = "";
    if (!this.sessionManagerService.isExternalClinicUser()) {
      clinicId = this.sessionManagerService.getClinicId();
    }
    this.registersService.medicalReportPatientList(this.patientId, this.searchString, orderingItem, 1, clinicId).subscribe((response: any) => {
      this.listTemplate.data = response;
      this.listTemplate.loading = false;
      this.listTemplate.hasNext = response.next != null;
    }, (error: HttpErrorResponse) => {
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = error.error['detail'];
    });
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    let clinicId: number | string = "";
    if (!this.sessionManagerService.isExternalClinicUser()) {
      clinicId = this.sessionManagerService.getClinicId();
    }
    this.registersService.medicalReportPatientList(this.patientId, this.searchString, this.orderingItem, page, clinicId).subscribe((response) => {
      response.results?.forEach(result => this.listTemplate.data?.results?.push(result));
      this.listTemplate.loading = false;
      this.listTemplate.hasNext = response.next != null;
      this.listTemplate.loadingNext = false;
    }, (error: HttpErrorResponse) => {
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = error.error['detail'];
      this.listTemplate.loadingNext = false;
    });
  }
}
