import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/pt';

registerLocaleData(localeEs);

import * as moment from 'moment';
import { DatePickerComponent, IDay } from 'ng2-date-picker';
import { ActivatedRoute, Router } from '@angular/router';
import { Schedule } from "../../../core/models/schedule.model";
import { ScheduleService } from "../../../core/services/schedules.service";
import { AvailableAttendanceModel } from "../../../core/models/available-attendance.model";
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  ConfirmationModalComponent
} from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import { RegistersService } from "../../../core/services/registers.service";
import { SchedulingStatusModel } from 'src/app/core/models/scheduling-status.model';
import { AttendanceTypeModel } from 'src/app/core/models/attendance-type.model';
import { AccountsService } from "../../../core/services/accounts.service";
import { AccountModel } from "../../../core/models/account.model";
import { ContextMenuBasicComponent } from 'src/app/shared/components/context-menu/context-menu.component';
import { ContextMenuService } from 'ngx-contextmenu';
import { Title } from "@angular/platform-browser";
import { ToastrService } from 'ngx-toastr';
import { SelectorModalComponent } from 'src/app/shared/renderers/components/selector-modal/selector-modal.component';
import { CreateReminderModalComponent } from 'src/app/shared/components/create-reminder-modal/create-reminder-modal.component';
import { ReminderStatusModel } from 'src/app/core/models/reminder-status.model';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss'],
})
export class SchedulesComponent implements OnInit {
  formGroup = this.fb.group({
    scheduleViewType: ['weekly'],
    currentSchedule: [],
    currentDate: [moment()],
    allowed_attendance_types: this.fb.array([])
  });

  weekdays: string[] = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

  datePickerConfig = {
    locale: 'pt-br',
    weekDayFormatter: ((weekdayNumber: number) => this.weekdays[weekdayNumber].charAt(0)),
    monthFormatter: ((moment: moment.Moment) => {
      let month = moment.format("MMMM");
      month = month.charAt(0).toUpperCase() + month.slice(1);
      return month + ' de ' + moment.year()
    }),
    enableMonthSelector: false,
    showGoToCurrent: true,
    unSelectOnClick: false,
  };

  @ViewChild('modalContent', { static: true }) modalContent!: TemplateRef<any>;
  @ViewChild('datePickerComponent') datePickerComponent!: DatePickerComponent;

  scheduleList: Schedule[] = [];
  loading: boolean = true;
  loadingSchedules: boolean = true;
  cells: any = [];
  _weekResponse: { [key: string]: [] } = {};
  week: Date[] = [];
  startHour = '00:00';
  endHour = '23:59';
  showDatePicker: boolean = false;
  loadingWeek = true;
  scheduleIsEmpty = false;
  scheduleIsReminder = false;

  isWeekly: boolean = false;
  isCut: boolean = false;
  scheduleIdCopy: number = 0;

  attendance?: any;
  reminderData?: any;

  availableAttendances: AvailableAttendanceModel[] = [];

  selectedToAction: any[] = [];
  attendanceCheckedList: any[] = [];
  attendanceTypes?: AttendanceTypeModel[];
  selectedAttendanceTypes: AttendanceTypeModel[] = [];
  allowedAttendanceTypes: AttendanceTypeModel[] = [];
  attendanceTypeFilterIsOpened = false;

  isClinicStaff = JSON.parse(localStorage.getItem('isClinicStaff')!);

  get currentDate(): moment.Moment {
    return this.formGroup.get('currentDate')?.value as moment.Moment;
  }

  set currentDate(value: moment.Moment) {
    this.formGroup.patchValue({ 'currentDate': value });
  }

  get currentMonth(): string {
    let months = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro',
      'outubro', 'novembro', 'dezembro'];
    return months[moment(this.currentDate).month()];
  }

  constructor(@Inject(DOCUMENT) private document: Document, private router: Router,
    private activatedRoute: ActivatedRoute, private scheduleService: ScheduleService,
    private fb: FormBuilder, config: NgbModalConfig, private registerService: RegistersService,
    private modalService: NgbModal, private accountService: AccountsService, private titleService: Title,
    private registersService: RegistersService, private contextMenuService: ContextMenuService,
    private toast: ToastrService, private changeDetection: ChangeDetectorRef) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.titleService.setTitle('ELEVE - Agenda');
  }

  ngOnInit(): void {
    if (!this.isClinicStaff) {
      this.router.navigate([`dashboard/patients/`])
    }
    let mediaQuery = window.matchMedia('(max-width: 768px)');
    if (mediaQuery.matches) {
      this.formGroup.get('scheduleViewType')?.setValue('daily');
    } else {
      this.formGroup.get('scheduleViewType')?.setValue('weekly');
    }
    let accountData = localStorage.getItem('account');
    let account = JSON.parse(accountData!) as AccountModel;
    this.accountService.accountGet(account.id!).subscribe(response => {
      accountData = JSON.stringify(response);
      localStorage.setItem('account', accountData);
    });

    this.registerService.schedulingsStatusList().subscribe(response => {
      let treatedBackgroundColor: { [key: string]: string | undefined } = {};
      let treatedFontColor: { [key: string]: string | undefined } = {};
      response.results?.forEach((item: SchedulingStatusModel) => treatedBackgroundColor[`${item.id}`] = item.background_color);
      localStorage.setItem('schedulingStatusBackgroundColor', JSON.stringify(treatedBackgroundColor));
      response.results?.forEach((item: SchedulingStatusModel) => treatedFontColor[`${item.id}`] = item.font_color);
      localStorage.setItem('schedulingStatusFontColor', JSON.stringify(treatedFontColor));
    });

    this.registersService.statusReminderAll().subscribe(response => {
      let treated: { [key: string]: {} | undefined } = {};
      response.forEach((item: ReminderStatusModel) => treated[`${item.id}`] = item);
      localStorage.setItem('reminderStatus', JSON.stringify(treated));
    });

    this.registerService.schedulingsStatusAll().subscribe(response => {
      localStorage.setItem('schedulingStatus', JSON.stringify(response));
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['date']) {
        this.formGroup.get('currentDate')?.setValue(moment(params['date']));
        if (params['iszWeekly'] == 'false')
          this.setDateAndDaily(params['date']);
        if (params['scheduleId'] != 'reminder') {

        }
      }
      this.fetchSchedules();
      this.setWeek();
    });
    this.fetchAttendanceType();
  }

  fetchAttendanceType() {
    this.registersService.attendancesTypeAll().subscribe((response) => {
      this.attendanceTypes = response;
      this.selectedAttendanceTypes = response;
      let treated: { [key: string]: {} | undefined } = {};
      response.forEach((item: AttendanceTypeModel) => treated[`${item.id}`] = item);
      localStorage.setItem('attendanceType', JSON.stringify(treated));
      this.attendanceCheckedList = response.map((type) => {
        return {
          id: type.id,
          name: type.name,
          checked: true
        }
      })
      let attendanceTypeArray: FormArray = this.formGroup.get('allowed_attendance_types') as FormArray;
      this.attendanceCheckedList.map(attendanceType => {
        attendanceTypeArray.push(this.fb.control(attendanceType.id));
      });
    }, (error) => {
      this.attendanceCheckedList = [];
    })
  }

  attendanceTypeIsAvailable(attendanceType: AttendanceTypeModel): boolean {
    return this.allowedAttendanceTypes.some(item => item.id == attendanceType.id);
  }

  modalRefContent!: NgbModalRef;

  open(content: any) {
    this.attendanceCheckedList.forEach((type) => {
      let id = type.id;
      let checked = this.selectedToAction[0].allowed_attendance_types.some((value: any) => value.id == id);
      type.checked = checked;
    });
    this.modalRefContent = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: true, keyboard: true });
  }

  get isCheckedAll() {
    return !this.attendanceCheckedList.some(type => !type.checked)
  }

  onSelectAll(ev: any) {
    this.attendanceCheckedList.forEach(type => {
      type.checked = ev.target.checked;
    })
  }

  isChecked(type: any) {
    let attendanceType = this.formGroup.get('allowed_attendance_types') as FormArray;
    return attendanceType.value.some((id: number) => id == type.id);
  }

  onCheckChange(type: any) {
    this.attendanceCheckedList.filter(value => value.id == type.id)?.map(() => type.checked = !type.checked);
  }

  updateAllowedAttendanceType() {
    if (this.selectedToAction.length > 0) {
      let types = this.attendanceCheckedList.filter(type => type.checked == true) ?? [];
      this.selectedToAction.forEach((attendance) => {
        attendance.allowed_attendance_types = types;
        let data = {
          allowed_attendance_types: types.map(type => { return { id: type.id } })
        }
        this.scheduleService.onceAttendanceType(attendance.id, data).subscribe((response) => { });
      });
    }
    this.modalRefContent.close();
    this.fetchWeek(this.currentSchedule, this.currentDate.format('YYYY-MM-DD'));
    this.selectedToAction = [];
  }

  getSchedulingStatus() {
    return JSON.parse(localStorage.getItem('schedulingStatus')!);
  }

  @ViewChild(ContextMenuBasicComponent) basicMenu?: ContextMenuBasicComponent;
  @ViewChild(ContextMenuBasicComponent) basicMenuInclude?: ContextMenuBasicComponent;
  @ViewChild(ContextMenuBasicComponent) reminderInclude?: ContextMenuBasicComponent;
  @ViewChild(ContextMenuBasicComponent) reminder?: ContextMenuBasicComponent;

  onContextMenuInclude($event: any) {
    let ev = $event.$event;
    this.basicMenuInclude!.contextMenuItems = { item: $event.item, scheduleId: $event.scheduleId };
    this.contextMenuService.show.next({
      contextMenu: this.basicMenuInclude?.contextMenuInclude,
      event: ev,
      item: $event.item
    });
    ev.preventDefault();
    ev.stopPropagation();
  }

  onContextMenuIncludeReminder($event: any) {
    let ev = $event.$event;
    this.reminderInclude!.contextMenuItems = { item: $event.item, scheduleId: 'lembrete' };
    this.contextMenuService.show.next({
      contextMenu: this.reminderInclude?.reminderInclude,
      event: ev,
      item: $event.item
    });
    ev.preventDefault();
    ev.stopPropagation();
    this.reminderInclude!.initContextMenu();
  }

  onSchedulingIncluded(response: any) {
    this.reloadWeek(response);
  }

  onReminderIncluded(response: any) {
    this.reloadWeek(response);
  }

  onContextMenu($event: any) {
    let ev = $event.$event;
    this.basicMenu!.contextMenuItems = $event.item;
    this.contextMenuService.show.next({
      contextMenu: this.basicMenu?.contextMenu,
      event: ev,
      item: $event.item
    });
    ev.preventDefault();
    ev.stopPropagation();
    this.basicMenu!.initContextMenu();
  }

  onContextMenuReminder($event: any) {
    let ev = $event.$event;
    this.reminder!.contextMenuItems = $event.item;
    this.contextMenuService.show.next({
      contextMenu: this.reminder?.reminder,
      event: ev,
      item: $event.item
    });
    ev.preventDefault();
    ev.stopPropagation();
    this.reminder!.initContextMenu();
  }

  toggleSchedulingStatus(ev: any) {
    let schedulingStatus = { scheduling_status: ev.id };
    let date = ev.event?.item?.start_time;
    let iszWeekly = this.formGroup.get('scheduleViewType')?.value;
    if (iszWeekly == 'weekly') this.isWeekly = true;
    else this.isWeekly = false;
    this.registersService.schedulingStatusToggle(this.currentSchedule?.id!, ev.attendanceId, schedulingStatus).subscribe(response => {
      this.toast.success("Status alterado com sucesso", "Sucesso");
      this.reloadWeek(response);
    }, error => {
      this.toast.error(error.error["detail"], "Erro", {
        closeButton: true,
      });
    });
  }

  toggleReminderStatus(ev: any) {
    let reminderStatus = { status_id: ev.id };
    let iszWeekly = this.formGroup.get('scheduleViewType')?.value;
    if (iszWeekly == 'weekly') this.isWeekly = true;
    else this.isWeekly = false;
    this.registersService.statusReminderToggle(ev.reminderId, reminderStatus).subscribe(response => {
      this.toast.success("Status alterado com sucesso", "Sucesso");
      this.reloadWeek(response);
    }, error => {
      this.toast.error(error.error["detail"], "Erro");
    });
  }

  cutAttendance(ev: any) {
    this.isCut = true;
    if (!this.scheduleIsReminder) {
      this.attendance = ev?.attendance.id;
      this.scheduleIdCopy = this.currentSchedule?.id!;
    } else {
      this.reminderData = ev.attendance;
    }
  }

  copyAttendance(ev: any) {
    this.isCut = false;
    if (!this.scheduleIsReminder) {
      this.attendance = ev?.attendance.id;
      this.scheduleIdCopy = this.currentSchedule?.id!;
    } else {
      this.reminderData = ev.attendance;
    }
  }

  pasteAttendance(ev: any) {
    let data = {
      cut: this.isCut,
      date_time: ev.item.start_time,
      schedule: this.currentSchedule?.id!,
      attendance_type: 0
    };
    let iszWeekly = this.formGroup.get('scheduleViewType')?.value;
    if (iszWeekly == 'weekly') this.isWeekly = true;
    else this.isWeekly = false;
    let messageTitle = '';
    if (this.isCut) {
      messageTitle = `Para remover o agendamento da data ${moment(this.attendance?.date_time).format('DD/MM/YYYY - HH:mm:ss')} e colar na data ${moment(data.date_time).format('DD/MM/YYYY - HH:mm:ss')}, escolha um tipo de atendimento:`;
    } else {
      messageTitle = `Para copiar o agendamento da data ${moment(this.attendance?.date_time).format('DD/MM/YYYY - HH:mm:ss')} e colar na data ${moment(data.date_time).format('DD/MM/YYYY - HH:mm:ss')}, escolha um tipo de atendimento`;
    }
    if (this.scheduleIsReminder) {
      if (ev.item.date) {
        this.reminderData.date = ev.item.date;
      } else {
        let date = moment(ev.item.item);
        let hour = moment('10:00', 'HH:mm');
        let date_time = date.set({
          hour: hour.get('hour'),
          minute: hour.get('minute'),
          second: hour.get('second')
        });
        this.reminderData.date = moment(date_time).format();
      }
      this.reminderData.id = '';
      this.scheduleService.createReminder(this.reminderData).subscribe((response) => {
        this.reloadWeek(response);
        this.toast.success("Lembrete criado", "Sucesso");
      }, (error) => {
        this.toast.error(error.error["detail"], "Erro");
      });
    } else {
      this.changeDetection.detach();
      const modalRef = this.modalService.open(SelectorModalComponent, { centered: true });
      modalRef.componentInstance.text = messageTitle;
      modalRef.componentInstance.options = ev.item.allowed_attendance_types;
      modalRef.result.then((result) => {
        this.changeDetection.reattach();
        if (result) {
          data.attendance_type = Number(result);
          this.scheduleService.duplicateAttendance(this.scheduleIdCopy, this.attendance, data).subscribe(response => {
            this.reloadWeek(response);
            if (this.isCut)
              this.basicMenu!.duplicateAttendance = false;
            this.toast.success('Atendimento salvo com sucesso', "Sucesso");
          }, error => {
            this.toast.error(error.error["detail"], "Erro");
            this.basicMenu!.duplicateAttendance = true;
          });
        }
      });
    }
  }

  createReminder(ev: any) {
    const modalRef = this.modalService.open(CreateReminderModalComponent, { centered: true });
    modalRef.componentInstance.action = 'create';
    modalRef.componentInstance.scheduleReminder = this.scheduleIsReminder;
    modalRef.componentInstance.modalItems = ev;
    modalRef.result.then((result) => {
      if(result && this.scheduleIsReminder) {
        this.reloadWeek(result);
        this.toast.success('Lembrete criado com sucesso', "Sucesso");
      }
     });
  }

  get currentSchedule(): Schedule | undefined {
    let id = this.formGroup.get('currentSchedule')?.value;
    return this.fetchSchedule(id);
  }

  reloadWeek(response: any) {
    this._weekResponse = response;
    this.week = [];
    Object.keys(response).forEach(day => this.week.push(moment(day).toDate()));
    let all: string[] = [];
    this.allowedAttendanceTypes = [];
    if (this.scheduleIsReminder) {
      this.scheduleIsEmpty = true;
      Object.values(this._weekResponse).forEach((value) => {
        value.forEach(val => {
          if (val != null) {
            this.scheduleIsEmpty = false;
          }
        });
      });
      this.mountSchedule();
    } else {
      Object.values(this._weekResponse).forEach(value => {
        value.map(val => {
          (val['allowed_attendance_types'] as []).forEach(item => this.allowedAttendanceTypes.push(item));
          let date = moment(val['start_time']);
          let hour = date.format('HH:mm');
          if (all.length == 0)
            all.push(hour);
          if (all.length > 0 && hour < all[0])
            all.unshift(hour);
          if (all.length > 0 && hour > all[all.length - 1])
            all.push(hour);
        });
      });
      if (all.length == 0 && !this.scheduleIsReminder) {
        this.scheduleIsEmpty = true;
      } else {
        this.startHour = all[0];
        this.endHour = all[all.length - 1];
        this.mountSchedule();
      }
    }
  }

  fetchWeek(schedule: Schedule | undefined, date: string) {
    this.scheduleIsEmpty = false;
    if (!this.scheduleIsReminder) {
      if (schedule) {
        this.loadingWeek = true;
        this.scheduleService.getWeek(schedule.id!, date).subscribe(response => {
          this.loadingWeek = false;
          this.reloadWeek(response);
        }, error => {
          this.scheduleIsEmpty = true;
          this.loadingWeek = false;
        });
      }
    } else {
      this.scheduleService.getReminder(date).subscribe(response => {
        this.loadingWeek = false;
        this.reloadWeek(response);
      })
    }
  }

  setWeek() {
    this.fetchWeek(this.currentSchedule, moment(this.currentDate).format('yyyy-MM-DD'));
  }

  selectSchedule(scheduleId: any) {
    if (scheduleId != 'reminder')
      localStorage.setItem('currentSchedule', scheduleId);
    this.formGroup.get('currentSchedule')?.setValue(scheduleId);
    if (scheduleId == 'reminder') {
      this.scheduleIsReminder = true;
    } else {
      this.scheduleIsReminder = false;
    }
    this.router.navigate(
      ['/dashboard/schedules'],
      { queryParams: { scheduleId: scheduleId }, queryParamsHandling: 'merge' }
    );
    this.setWeek();
    this.closeDatePicker();
  }

  isCurrentDate(date: Date): boolean {
    let sameDay = moment(this.currentDate).date() == date.getDate();
    let sameMonth = moment(this.currentDate).month() == date.getMonth();
    let sameYear = moment(this.currentDate).year() == date.getFullYear();
    return sameDay && sameMonth && sameYear;
  }

  getWeekday(date: Date): string {
    return this.weekdays[date.getDay()];
  }

  formatDate(date: Date): string {
    let months = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro',
      'outubro', 'novembro', 'dezembro'];
    return `${date.getDate()} de ${months[date.getMonth()]}`;
  }

  fetchSchedules() {
    this.scheduleService.availableSchedulesForUser().subscribe((response) => {
      this.scheduleList = response;
      this.loadingSchedules = false;
      if (this.scheduleList.length > 0) {
        let id = this.scheduleList[0].id;
        this.activatedRoute.queryParams.subscribe(params => {
          if (params['scheduleId']) {
            id = params['scheduleId'];
            if (params['scheduleId'] != 'reminder') {
              id = Number(id);
            }
            this.selectSchedule(id);
          }
        });
        this.setCurrentSchedule(id);
        this.setWeek();
      }
    }, (error) => console.error(error));
  }

  setCurrentSchedule(id: any) {
    if (localStorage.getItem('currentSchedule')) {
      id = parseInt(localStorage.getItem('currentSchedule')!);
    }
    this.formGroup.get('currentSchedule')?.setValue(id);
  }

  fetchSchedule(id: any): Schedule | undefined {
    if (id == 'reminder') {
      this.scheduleIsReminder = true;
    } else {
      if (this.scheduleList.length > 0) {
        return this.scheduleList.find(schedule => {
          return schedule.id == id;
        });
      }
    }
    return;
  }

  dateChangeClickHandler(date: IDay) {
    this.router.navigate(
      ['/dashboard/schedules'],
      { queryParams: { date: date.date.format('YYYY-MM-DD') }, queryParamsHandling: 'merge' }
    );
    this.formGroup.get('currentDate')?.setValue(date.date);
    this.setWeek();
    this.closeDatePicker();
  }

  setDateAndDaily(date: Date) {
    let mDate = moment(date);

    if (this.isWeekly) {
      this.formGroup.get('scheduleViewType')?.setValue('weekly');
    }
    else {
      this.formGroup.get('scheduleViewType')?.setValue('daily');
      this.router.navigate(
        ['/dashboard/schedules'],
        { queryParams: { date: mDate.format('YYYY-MM-DD') }, queryParamsHandling: 'merge' }
      );
      this.formGroup.get('currentDate')?.setValue(mDate.toDate());
    }
    this.isWeekly = !this.isWeekly;
    this.setWeek();
    this.closeDatePicker();
  }

  goToCurrentClickHandler() {
    let date = moment().format('YYYY-MM-DD');
    this.router.navigate(
      ['/dashboard/schedules'],
      { queryParams: { date: date }, queryParamsHandling: 'merge' }
    );
    this.formGroup.get('currentDate')?.setValue(moment());
    this.setWeek();
  }

  openDatePicker() {
    this.showDatePicker = true;
  }

  closeDatePicker() {
    this.showDatePicker = false;
  }

  datePickerIsOpened(): boolean {
    return this.showDatePicker;
  }

  openAttendanceTypeFilter() {
    this.attendanceTypeFilterIsOpened = true;
  }

  closeAttendanceTypeFilter() {
    this.attendanceTypeFilterIsOpened = false;
  }

  toggleAttendanceType(attendanceType: AttendanceTypeModel) {
    if (this.attendanceTypesIsSelected(attendanceType)) {
      this.selectedAttendanceTypes = this.selectedAttendanceTypes.filter(value => attendanceType.id != value.id);
    } else {
      this.selectedAttendanceTypes.push(attendanceType);
    }
  }

  toggleAllAttendanceTypes() {
    let totalAvailableAttendanceTypes = 0;
    if (this.attendanceTypes) {
      totalAvailableAttendanceTypes = this.attendanceTypes.filter(item => this.attendanceTypeIsAvailable(item)).length;
      let totalSelectedAvailableAttendanceTypes = this.selectedAttendanceTypes.length;
      if (totalSelectedAvailableAttendanceTypes >= totalAvailableAttendanceTypes) {
        this.selectedAttendanceTypes = [];
      } else {
        this.selectedAttendanceTypes = this.attendanceTypes.filter(item => this.attendanceTypeIsAvailable(item));
      }
    }
  }

  attendanceTypesIsSelected(attendanceType: AttendanceTypeModel) {
    return this.selectedAttendanceTypes.some(value => attendanceType.id == value.id)
  }

  mountSchedule() {
    let cells: any = [];
    this.week.forEach((day: Date) => {
      if (!this.scheduleIsReminder) {
        cells.push(this.getIntervalsInDate(day, this.startHour, this.endHour));
      } else {
        cells.push([day]);
      }
    });
    let inverted: [any?] = [];
    cells.forEach((day: any, i: number) => {
      day.forEach((time: any, j: number) => {
        if (inverted[j] === undefined) {
          inverted[j] = [];
        }
        inverted[j][i] = time;
      });
    });
    this.cells = inverted;
  }

  getIntervalsInDate(date: Date, startAt: string, endAt: string): Date[] {
    let minHour = parseInt(startAt.split(':')[0]);
    let minMinute = 0;
    let maxHour = parseInt(endAt.split(':')[0]) + 1;
    let maxMinute = parseInt(endAt.split(':')[1]);
    let start = date.setHours(minHour, minMinute, 0);
    let end = date.setHours(maxHour, maxMinute, 0);
    let amount = (end - start) / (60 * 60 * 1000);
    let dates: Date[] = [];

    for (let index = 0; index < amount; index++) {
      let date = new Date(start + (60 * 60 * 1000 * index));
      dates.push(date);
    }

    return dates;
  }

  get timeline() {
    return this.cells.map((arr: [Date]) => moment(arr[0]).format('HH:mm'));
  }

  previous() {
    let unit: moment.unitOfTime.DurationConstructor = 'day';
    if (this.weekly) {
      unit = 'week';
    }
    this.currentDate = moment(this.currentDate).subtract(1, unit);
    this.setWeek();
  }

  next() {
    let unit: moment.unitOfTime.DurationConstructor = 'day';
    if (this.weekly) {
      unit = 'week';
    }
    this.currentDate = moment(this.currentDate).add(1, unit);
    this.setWeek();
  }

  get weekly() {
    return this.formGroup.get('scheduleViewType')?.value == 'weekly';
  }

  get isSequencial() {
    if (this.selectedToAction.length < 2) {
      return false;
    } else {
      return !this.selectedToAction.some((value, index, array) => {
        if (index + 1 < array.length) {
          let next = array[index + 1];
          return (value.end_time != next.start_time);
        }
        return false;
      });
    }
  }

  isSelected(attendance: any) {
    return this.selectedToAction.some(value => attendance == value);
  }

  onSelect(availableTime: any) {
    if (this.isSelected(availableTime)) {
      this.selectedToAction = this.selectedToAction.filter(value => availableTime != value);
    } else {
      if (availableTime['attendance'] != null)
        return;
      let hasDistinct = this.selectedToAction.some(value => moment(availableTime.start_time).date() != moment(value.start_time).date());
      if (hasDistinct) {
        this.selectedToAction = [];
      }
      this.selectedToAction.push(availableTime);
    }

    this.selectedToAction.sort((a, b) => {
      if (a.start_time > b.start_time)
        return 1;
      if (a.start_time < b.start_time)
        return -1;
      return 0;
    });
  }

  firstSplitTime = this.fb.control(10, [Validators.required]);

  remove() {
    let id = this.formGroup.get('currentSchedule')?.value;
    let attendances = { attendances: this.selectedToAction.map(value => value.id) };
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja remover este horário de atendimento?";
    modalRef.result.then(async (result) => {
      if (result == true) {
        this.scheduleService.removeAttendances(id, attendances, this.currentDate).subscribe(response => {
          this.reloadWeek(response);
        }, error => {
          this.toast.error(error.error["detail"], "Erro", {
            closeButton: true,
          });
        });

        this.selectedToAction = [];
      } else {
        // TODO: fazer um loading
      }
    });
  }

  merge() {
    let attendances = this.selectedToAction.map(value => value.id);
    this.scheduleService.mergeAttendances(this.currentSchedule?.id!, { attendances }, this.currentDate).subscribe(
      response => {
        this.reloadWeek(response);
        this.selectedToAction = [];
      }, error => {
        if (error.error['detail']) {
          this.toast.error(error.error["detail"], "Erro", {
            closeButton: true,
          });
        }
      }
    );
  }

  split() {
    let duration: number = this.firstSplitTime.value;
    let attendance = this.selectedToAction[0].id;
    let body = { attendance, first_time: duration };
    this.scheduleService.splitAttendance(this.currentSchedule?.id!, body, this.currentDate).subscribe(response => {
      this.reloadWeek(response);
      this.selectedToAction = [];
    }, error => {
      if (error.error['detail']) {
        this.toast.error(error.error["detail"], "Erro", {
          closeButton: true,
        });
      }
    });
  }
}
