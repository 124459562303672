import { Component, OnInit } from '@angular/core';
import {ListTemplate} from "../../../../shared/models/list-template";
import {
  documentsStringFormatter,
  isActiveStatusStringFormatter,
  phoneNumberStringFormatter
} from "../../../../core/utils/string-formatters";
import {HttpErrorResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {ListModel} from "../../../../core/models/list.model";
import {ExamsRequestService} from "../../../../core/services/exams-request.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-exams-request-list',
  templateUrl: './exams-request-list.component.html',
  styleUrls: ['./exams-request-list.component.scss']
})
export class ExamsRequestListComponent implements OnInit {

  searchString: string = "";
  orderingItem: string = "patient__name";

  isClinicStaff = JSON.parse(localStorage.getItem('isClinicStaff')!);
  userAccessClinicId = JSON.parse(localStorage.getItem('userAccessClinicId')!);

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Buscar por Nome ou CPF",
    showAdvancedSearch: false,
    newDataText: "+ Novo Exame",
    newDataRouterLink: "register",
    notFoundMessage: "Nenhum Exame encontrado",
    header: [
      { name: "patient__name", label: "Nome", widthInPercentage: "30%", width: "350px" },
      { name: "patient__document_number", label: "CPF", widthInPercentage: "20%", width: "350px", displayStringConvertionFunction: documentsStringFormatter },
      { name: "patient__phone_number", label: "Telefone", widthInPercentage: "20%", width: "250px", displayStringConvertionFunction: phoneNumberStringFormatter },
      { name: "payment_status", label: "Pagamento", widthInPercentage: "15%", width: "100px" },
      { name: "attendance__date_time", label: "Agendamento", widthInPercentage: "15%", width: "100px" }
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      this.fetchList(this.searchString, `${invertString}${value}`, this.listTemplate.is_active);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      this.fetchList(value, this.orderingItem, this.listTemplate.is_active);
    },
    onClickActive: () => {
      this.listTemplate.is_active = !this.listTemplate.is_active;
      this.fetchList(this.searchString, this.orderingItem, this.listTemplate.is_active);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
    exportUrl: '/patient_exams_request/export',
    exportOptions: {
      id: 'ID',
      patient__name: 'Nome',
      patient__document_number: 'CPF',
      patient__phone_number: 'Telefone',
      created_at: 'Criando em'
    }
  };

  constructor(private examsRequestSerice: ExamsRequestService, private titleService: Title) {
        this.titleService.setTitle('ELEVE - Exames');
  }

  ngOnInit(): void {
    this.initializeList();
  }

  initializeList() {
    this.fetchList("", "name", this.listTemplate.is_active);
  }

  fetchExamsRequest(searchString: string, orderingItem: string, isActive: boolean | undefined, page = 1): Observable<ListModel> {
    if (this.isClinicStaff) {
      return this.examsRequestSerice.examsRequestList(searchString, orderingItem, isActive, page)
    }
    return this.examsRequestSerice.examsRequestListByClinic(this.userAccessClinicId, searchString, orderingItem, isActive, page)
  }

  fetchList(searchString: string, orderingItem: string, isActive: boolean | undefined, page = 1) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;

    this.fetchExamsRequest(searchString, orderingItem, isActive).subscribe(response => {
      this.listTemplate.loading = false;
      this.listTemplate.data = response;
      this.listTemplate.hasNext = response.next != null;
    }, error => {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    });
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    this.fetchExamsRequest(this.searchString, this.orderingItem, this.listTemplate.is_active, page).subscribe(
      response => {
        this.listTemplate.hasNext = response.next != null;
        response.results!.map(item => this.listTemplate.data!.results!.push(item));
        this.listTemplate.loadingNext = false;
      },
      error => {
        const response = error as HttpErrorResponse;
        this.listTemplate.loading = false;
        this.listTemplate.error = true;
        this.listTemplate.errorMessage = response.error['detail'];
      });
  }
}
