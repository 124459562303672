import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { HealthInsurancePlanModel } from 'src/app/core/models/health-insurance-plan.model';
import { HealthInsuranceModel } from 'src/app/core/models/health-insurance.model';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-health-insurances-form',
  templateUrl: './health-insurances-form.component.html',
  styleUrls: ['./health-insurances-form.component.scss']
})
export class HealthInsurancesFormComponent implements OnInit {

  formGroup = this.fb.group({
    id: [],
    name: [],
    type: [],
    code: [],
    plans: this.fb.array([]),
    // image: [],
    font_color: [],
    background_color: []
  });

  id!: number;
  loadingPage: boolean = true;
  submitting: boolean = false;
  submittingRemove: boolean = false;
  image: string = '';
  imageFile!: File | null;
  changedImage: boolean = false;

  isRegister: boolean = true;
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";

  button: boolean = false;

  fetchedHealthInsuranceData: HealthInsuranceModel = {};
  plansList!: HealthInsurancePlanModel[];


  constructor(@Inject(DOCUMENT) private document: Document, private fb: FormBuilder, private registersService: RegistersService, private activatedRoute: ActivatedRoute, private sessionManager: SessionManagerService, private toast: ToastrService, private router: Router, config: NgbModalConfig, private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.addHealthInsurancePlan();
      if (this.id) {
        this.isRegister = false;
        this.fetcHealthInsuranceExistingData();
      } else this.loadingPage = false;
    })
  }

  onImageSelect(file: any) {
    this.imageFile = file;
    this.changedImage = true
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_healthinsurance);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_healthinsurance);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_healthinsurance);
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/registers/healthInsurances'])
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    (this.healthInsurancePlanForms?.value as HealthInsurancePlanModel[]).forEach((plan, index) => {
      if(plan.id == null) this.healthInsurancePlanForms.at(index)?.get('id')?.setValue(undefined);
    });
    let healthInsurancesData = this.formGroup.getRawValue() as HealthInsuranceModel;
    if (this.isRegister) {
      if (this.formGroup.valid) {
        this.healthInsurancesRegister(healthInsurancesData);
      } else this.submitting = false;
    } else {
      this.healthInsurancesEdit(healthInsurancesData);
    }
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar esta convênio?";
    modalRef.result.then((result: boolean) => {
      if (result == true) {
        this.healthInsurancesRemove();
      } else this.submittingRemove = false;
    })
  }

  healthInsurancesRegister(healthInsuranceData: HealthInsuranceModel) {
    this.registersService.healthInsuranceRegister(healthInsuranceData).subscribe(response => {
      if(this.changedImage) {
        this.registersService.uploadImageHealthInsurance(this.imageFile!, response.id!).subscribe(() => {}, (errorResponse: HttpErrorResponse) => {
          this.mapErrorResponse(errorResponse);
          this.submitting = false;
        });
      }
      this.toast.success("Convênio criada com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/registers/healthInsurances']);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  healthInsurancesEdit(healthInsuranceData: HealthInsuranceModel) {
    healthInsuranceData.is_active = true;
    this.registersService.healthInsuranceEdit(healthInsuranceData).subscribe(response => {
      if(this.changedImage) {
        this.registersService.uploadImageHealthInsurance(this.imageFile!, response.id!).subscribe(() => {}, (errorResponse: HttpErrorResponse) => {
          this.mapErrorResponse(errorResponse);
          this.submitting = false;
        });
      }
      this.toast.success("Convênio alterada com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/registers/healthInsurances']);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  healthInsurancesRemove() {
    this.registersService.healthInsuranceRemove(this.id).subscribe(response => {
      this.toast.success("Convênio removida com sucesso", "Sucesso", {
        closeButton: true,
      });
      this.router.navigate(['/dashboard/registers/healthInsurances']);
    }, (error: HttpErrorResponse) => {
      this.submittingRemove = false;
      this.mapErrorResponse(error);
    })
  }

  fetcHealthInsuranceExistingData() {
    this.registersService.healthInsuranceGet(this.id).subscribe(response => {
      this.fetchedHealthInsuranceData = response;
      if (this.fetchedHealthInsuranceData.is_active) {
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
        this.button = true;
      }
      else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
      }
      this.healthInsurancePlanForms.clear();
      this.fetchedHealthInsuranceData.plans?.forEach(() => this.addHealthInsurancePlan());
      this.formGroup.patchValue(this.fetchedHealthInsuranceData);
      this.loadingPage = false;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    })
  }

  get healthInsurancePlanForms() {
    return this.formGroup.get('plans') as FormArray;
  }

  getHealthInsurancePlanForm(index: number) {
    return this.healthInsurancePlanForms.controls[index] as FormGroup;
  }

  addHealthInsurancePlan() {
    let planFormGroup = this.fb.group({
      id: [],
      name: [],
      refund: [],
      is_active: [true]
    });
    this.healthInsurancePlanForms.push(planFormGroup);
  }

  removeHealthInsurancePlan(at: number) {
    let formGroup = this.healthInsurancePlanForms.controls[at] as FormGroup;
    if(formGroup.get('id')?.value) {
      formGroup.patchValue({ is_active: false });
    } else {
      this.healthInsurancePlanForms.removeAt(at);
    }
  }

  healthInsurancePlanIsActive(index: number) {
    let formGroup = this.healthInsurancePlanForms.controls[index] as FormGroup;
    return formGroup.get('is_active')?.value;
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro", {
        closeButton: true,
      });
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
    else {
      this.setFormErrors(errorResponse);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "name", "code", "plans", "type"
    ];
    errNames.forEach(name => {
      if(errorResponse.error[name])
      this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name]})
    });

    if(errorResponse.error.plans) {
      let plans: [] = errorResponse.error.plans;
      plans.map((res, index) => {
        let formPlan = this.healthInsurancePlanForms.controls[index] as FormGroup;
        let errNamesPlan = [
          "health_insurance", "name", "refund"
        ];
        errNamesPlan.forEach(name => {
          if(res[name]) {
            formPlan.get(name)?.setErrors({ response: res[name]})
          }
        })
      })
    }
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  }
}
