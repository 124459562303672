import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";

export class FieldValidator {
    static documentValidator(): ValidatorFn {
        return (control: AbstractControl): Validators | null => {
            const document = control.value;

            if (!document) {
              return false;
            }

            if (
                !document ||
                document.length != 11 ||
                document == "00000000000" ||
                document == "11111111111" ||
                document == "22222222222" ||
                document == "33333333333" ||
                document == "44444444444" ||
                document == "55555555555" ||
                document == "66666666666" ||
                document == "77777777777" ||
                document == "88888888888" ||
                document == "99999999999"
            ) return { 'document': true };

            var sum = 0;
            var rest = 0;
            var result = false;

            sum = 0;

            for (var i = 1; i <= 9; i++) sum = sum + parseInt(document.substring(i - 1, i)) * (11 - i);
            rest = (sum * 10) % 11;

            if ((rest == 10) || (rest == 11)) rest = 0;
            if (rest != parseInt(document.substring(9, 10))) result = true;

            sum = 0;
            for (i = 1; i <= 10; i++) sum = sum + parseInt(document.substring(i - 1, i)) * (12 - i);
            rest = (sum * 10) % 11;

            if ((rest == 10) || (rest == 11)) rest = 0;
            if (rest != parseInt(document.substring(10, 11))) result = true;

            if (result)
                return { 'document': true };
            else
                return null;
        }
    }

    static document3Validator(): ValidatorFn {
        return (control: AbstractControl): Validators | null => {
            const document = control.value;

            if (!document ||
                document.length != 14 ||
                document == "00000000000000" ||
                document == "11111111111111" ||
                document == "22222222222222" ||
                document == "33333333333333" ||
                document == "44444444444444" ||
                document == "55555555555555" ||
                document == "66666666666666" ||
                document == "77777777777777" ||
                document == "88888888888888" ||
                document == "99999999999999")
                return { 'document3': true };

            var tamanho = document.length - 2
            var numeros = document.substring(0, tamanho);
            var digitos = document.substring(tamanho);
            var soma = 0;
            var pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0))
                return { 'document3': true };

            tamanho = tamanho + 1;
            numeros = document.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1))
                return { 'document3': true };

            return null;
        }
    }

    static document2Validator(): ValidatorFn {
        return (control: AbstractControl): Validators | null => {
            const document2 = control.value;

            if (!document2) return { 'document2': true };

            var document2Regex = /^(^\d{1,2}).?(\d{3}).?(\d{3})-?(\d{1}|X|x$)$/;

            if (document2Regex.test(document2) == false)
                return { 'document2': true };
            else
                return null;
        }
    }

    static zipCodeValidator(): ValidatorFn {
        return (control: AbstractControl): Validators | null => {
            const zipCode = control.value;

            if (!zipCode) return { 'zipCode': true };

            var zipCodeRegex = /^[0-9]{8}$/;

            if (zipCodeRegex.test(zipCode) == false)
                return { 'zipCode': true };
            else
                return null;
        }
    }

    static phoneValidator(): ValidatorFn {
        return (control: AbstractControl): Validators | null => {
            const phone = control.value;

            if (!phone) return { 'phone': true };

            var phoneRegex = /(?:^\([0]?[1-9]{2}\)|^[0]?[1-9]{2}[\.-\s]?)[9]?[1-9]\d{3}[\.-\s]?\d{4}$/;

            if (phoneRegex.test(phone) == false)
                return { 'phone': true };
            else
                return null;
        }
    }

    static timeRangeValidator(): ValidatorFn {
        return (control: AbstractControl): Validators | null => {
            const timeRange = control.value;

            if (!timeRange) return { 'timeRange': true };

            var timeRangeRegex = /^((?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9])(?:\s?)-(?:\s?)(?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9])(?:\s?,\s?)?)+$/;

            if (timeRangeRegex.test(timeRange) == false)
                return { 'timeRange': true };
            else
                return null;
        }
    }

    static passwordConfirmValidator(): ValidatorFn {
        return (control: AbstractControl): Validators | null => {
            const passwordValue = control.parent?.get('newPassword')!.value;
            const passwordConfirmValue = control.value;

            if (passwordConfirmValue != passwordValue)
                return { 'mismatch': true };
            else
                return null;
        }
    }

  static minimumValue(minimumValue: number): ValidatorFn {
    return (control: AbstractControl): Validators | null => {
      const value = control.value
      if (value === null || value === undefined || isNaN(value)) {
        return { minimumValue: true, value: minimumValue.toString().replace('.', ',') }
      }
      if (minimumValue > value) {
        return { minimumValue: true, value: minimumValue.toString().replace('.', ',') }
      }
      return null
    }
  }
}
