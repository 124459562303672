<div class="kanban" cdkDropListGroup>
  <form class="filters" [formGroup]="formGroup" (change)="filterData()">
    <div class="filter">
      <label for="start">Início</label>
      <input type="date" id="start" formControlName="start">
    </div>

    <div class="filter">
      <label for="end">Fim</label>
      <input type="date" id="end" formControlName="end">
    </div>

    <div class="filter">
      <label for="name">Nome</label>
      <input type="text" id="name" (keyup)="filterData()" formControlName="name">
    </div>

    <div class="filter">
      <label for="whatsapp">Whatsapp</label>
      <input type="text" id="whatsapp" (keyup)="filterData()" formControlName="whatsapp">
    </div>

    <span class="clear-filters" (click)="clearFilters()">Limpar filtros</span>
  </form>

  <div class="columns">
    <div class="column" *ngFor="let column of organizedData; let index = index" [style.background-color]="column.color">
      <h4>
        ({{ column.data.length }})
        {{ column.name }}

        <button *ngIf="index == 0" class="btn btn-link" (click)="openModal()">
          <i class="icon icon-Icon-ionic-ios-add"></i>
        </button>
      </h4>

      <div class="content" cdkDropList [cdkDropListData]="column.data" [id]="column.name"
           (cdkDropListDropped)="onDrop($event)">
        <div class="kanban-card" cdkDrag [cdkDragData]="card" *ngFor="let card of column.data"
             (dblclick)="openModal(card.id!)">
          <span class="text" *ngIf="!card.name">Sem nome</span>
          <span class="text" *ngIf="card.name">{{ card.name }}</span>
          <span class="text">
          <i class="icon icon-Icon-awesome-whatsapp" (click)="openWhatsapp($event, card)"></i>
            {{ card.whatsapp }}
        </span>
        </div>
      </div>
    </div>
  </div>
</div>
