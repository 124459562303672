import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountModel } from 'src/app/core/models/account.model';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import {OptionsSelectModel} from "../../../shared/models/option";
import {ClinicsService} from "../../../core/services/clinics.service";
import {ClinicModel} from "../../../core/models/clinic.model";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading: boolean = false;
  account!: AccountModel;
  userClinicOptions: OptionsSelectModel[] = [];
  userClinics!: ClinicModel[];
  showClinics: boolean = false;
  userAccessClinicId!: number;
  isClinicStaff!: boolean;

  loginForm: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required, Validators.minLength(3)]],
    clinic_id: [null]
  });

  constructor(private accountsService: AccountsService, private sessionManager: SessionManagerService,
              private router: Router, private toast: ToastrService, private clinicsService: ClinicsService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  onSubmit(e: Event): void {
    if (this.loginForm.valid) {
      if (!this.showClinics) {
        this.loading = true;
        this.loginRequest();
        e.preventDefault();
      } else {
        this.setSessionAndRedirectUser();
      }
    }
  }

  loginRequest() {
    var accountData = {
      email: this.loginForm.get('email')!.value,
      password: this.loginForm.get('password')!.value
    } as AccountModel;
    this.accountsService.login(accountData).subscribe((response: AccountModel) => {
      this.account = response;
      this.fetchUserClinicList();
    }, (error: HttpErrorResponse) => {
      this.loading = false;
      this.mapErrorResponse(error);
    });
  }

  handleUserAccess() {
    if (this.account.clinics) {
      if (this.account.clinics.length === 0) {
        this.treatUserWithNoAssociatedClinic();
      } else if (this.account.clinics.length === 1) {
        this.userAccessClinicId = this.account.clinics[0];
        this.treatUserWithOneAssociatedClinic();
      } else {
        this.treatUserWithMultipleAssociatedClinics();
      }
    }
  }

  treatUserWithMultipleAssociatedClinics() {
    this.loginForm.get('clinic_id')?.setValidators([Validators.required])
    this.showClinics = true;
    this.loading = false;
  }

  treatUserWithOneAssociatedClinic() {
    let clinic = this.userClinics.find(clinic => clinic.id === this.userAccessClinicId)
    if (clinic && clinic.is_staff != undefined) {
      this.isClinicStaff = clinic.is_staff;
    }
    this.setSessionAndRedirectUser();
  }

  treatUserWithNoAssociatedClinic() {
    this.toast.error('Usuário não está associado a nenhuma Clínica')
    this.loginForm.reset();
    this.loading = false;
  }

  fetchUserClinicList() {
    this.clinicsService.clinicsByUser(this.account.id).subscribe(response => {
      this.userClinics = response;
      this.userClinicOptions = response.map(item => {
        return {
          label: item.display_name,
          value: item.id!.toString()
        } as OptionsSelectModel
      })
    this.handleUserAccess();
    });
  }

  setUserAccessClinic() {
    this.userAccessClinicId = parseInt(this.loginForm.get('clinic_id')!.value);
    let clinic = this.userClinics.find(clinic => clinic.id === this.userAccessClinicId)
    if (clinic && clinic.is_staff != undefined) {
      this.isClinicStaff = clinic.is_staff;
    }
  }

  setSessionAndRedirectUser() {
    this.sessionManager.setSessionData(this.account, this.userAccessClinicId, this.isClinicStaff);
    if (this.isClinicStaff) {
      this.router.navigate(["/dashboard"]);
    } else {
      this.router.navigate(["/dashboard/patients"]);
    }
  }

  mapErrorResponse(error: HttpErrorResponse) {
    if(error.error["detail"]) {
      this.toast.error(error.error["detail"], "Erro");
    } else {
      let errNames = [
        "email", "password"
      ];
      errNames.forEach(name => {
        console.log(error.error[name])
        if (error.error[name])
          this.loginForm.get(name)?.setErrors({ response: error.error[name] });
      });
    }
  }

  passwordRecoveryLinkClickHandler() {
    this.router.navigate(['passwordRecovery']);
  }
}
