import { HttpErrorResponse } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AttendanceModel } from 'src/app/core/models/attendance.model';
import { ClinicModel } from 'src/app/core/models/clinic.model';
import { HealthInsurancePatientModel } from 'src/app/core/models/health-insurance-patient';
import { HealthInsurancePlanModel } from 'src/app/core/models/health-insurance-plan.model';
import { HealthInsuranceModel } from 'src/app/core/models/health-insurance.model';
import { PatientModel } from 'src/app/core/models/patient.model';
import { PhoneModel } from 'src/app/core/models/phone.model';
import { ProfessionModel } from 'src/app/core/models/profession.model';
import { ProfessionalModel } from 'src/app/core/models/professional.model';
import { Schedule } from 'src/app/core/models/schedule.model';
import { BrapiService } from 'src/app/core/services/brapi.service';
import { ClinicsService } from 'src/app/core/services/clinics.service';
import { ProfessionalsService } from 'src/app/core/services/professionals.service';
import { ProfessionsService } from 'src/app/core/services/professions.service';
import { RegistersService } from 'src/app/core/services/registers.service';
import { ScheduleService } from 'src/app/core/services/schedules.service';
import {
  ConfirmationModalComponent
} from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import { OptionModel } from 'src/app/shared/renderers/models/option';
import { ProcedureModel } from "../../../../core/models/procedure.model";
import { SchedulingStatusModel } from "../../../../core/models/scheduling-status.model";
import { AttendanceTypeModel } from 'src/app/core/models/attendance-type.model';
import { phoneNumberStringFormatter } from 'src/app/core/utils/string-formatters';
import { DocumentModel } from 'src/app/core/models/document.model';
import { MedicalOrderModel } from 'src/app/core/models/medicalOrder.model';
import { DocumentTemplateModel } from 'src/app/core/models/documentTemplate.model';
import { ToastrService } from 'ngx-toastr';
import {OptionsSelectModel} from 'src/app/shared/models/option';
import {ClinicExamsRequestModel} from "../../../../core/models/clinic-exams-request.model";
import {Option} from "@angular/cli/models/interface";
import {ExamRequestModel} from "../../../../core/models/exam-request.model";

@Component({
  selector: 'app-scheduling-form',
  templateUrl: './scheduling-form.component.html',
  styleUrls: ['./scheduling-form.component.scss']
})
export class SchedulingFormComponent implements OnInit {
  addressFormGroup: FormGroup = this.fb.group({
    zipcode: [],
    address: [],
    number: [],
    complement: [],
    neighborhood: [],
    city: [],
    state: []
  });

  searchZipcode: boolean = false;

  patientFormGroup: FormGroup = this.fb.group({
    id: [],
    name: [null, [Validators.required]],
    display_name: [],
    gender: [],
    birthdate: [],
    email: [],
    document_number: [],
    marital_status: [],
    profession: [],
    tags: this.fb.array([]),
    phone_numbers: this.fb.array([]),
    health_insurances: this.fb.array([]),
    mother_name: [],
    father_name: [],
    address: this.addressFormGroup,
    clinic: [],
    professional: [],
    notes: []
  });

  polysomnographyFormGroup = this.fb.group({
    exam_nights: ['', [Validators.min(1)]],
    sent_at: [],
    period: [],
    id: [],
  });

  clinicExamsRequestFormGroup = this.fb.group({
    id: []
  });

  formGroup = this.fb.group({
    scheduling_status: [1, [Validators.required]],
    attendance_type: [1, [Validators.required]],
    schedule: ['', [Validators.required]],
    date_time: ['', [Validators.required]],
    duration: ['', [Validators.required]],
    patient: this.patientFormGroup,
    patient_health_insurance: [],
    procedures: this.fb.array([]),
    professional: [],
    notes: [],
    document_requesting_professional: [],
    polysomnography: this.polysomnographyFormGroup,
    clinic_exams_request: this.clinicExamsRequestFormGroup,
  });

  documentsForm = this.fb.group({
    professional: [],
    document_templates: this.fb.array([])
  });

  clinicExamRequestsOptions: OptionsSelectModel[] = [];
  clinicExamRequests: ClinicExamsRequestModel[] = [];
  examRequest: ExamRequestModel[] = [];

  constructor(@Inject(DOCUMENT) private document: Document, private registersService: RegistersService, private professionService: ProfessionsService,
    private professionalService: ProfessionalsService, private clinicsService: ClinicsService,
    private scheduleService: ScheduleService, private activatedRoute: ActivatedRoute,
    private fb: FormBuilder, private router: Router, config: NgbModalConfig,
    private modalService: NgbModal, private brApiService: BrapiService, private toast: ToastrService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  healthInsurances: HealthInsurancePatientModel[] | undefined = [];

  existingAttendanceData!: AttendanceModel;
  idAttendance!: number;

  isWeekly!: boolean;
  isRegister: boolean = true;
  isPatientPage?: number;
  queryParams?: any;
  date!: any;

  fetchingClinicExamRequests = false;

  schedules!: Schedule[];
  idSchedule!: number;

  saveButtonDisabled: boolean = false;
  removeButtonDisabled: boolean = false;

  submitting: boolean = false;
  submittingPatient: boolean = false;
  submittingRemove: boolean = false;
  removeButton?: String = "Desativar";
  submitButton?: String = "Salvar";

  button: boolean = false;

  isFetchSchedules: boolean = false;
  subTitle = "Carregando...";

  emitDocumentsLoading = false;
  emitDocumentsReceptionLoading = false;
  emitDocumentsRefundLoading = false;
  emitedDocuments = false;
  emitedDocumentsReception = false;
  emitedDocumentsRefund = false;
  formLoadingPatient: boolean = true;
  formLoadingDocument: boolean = false;
  existingPatient?: PatientModel;
  patientOptions!: OptionsSelectModel[];
  patients!: PatientModel[];
  phonesList!: PhoneModel[];
  healthInsurancesList!: HealthInsuranceModel[];
  healthInsurancesListAll!: OptionsSelectModel[];
  healthInsurancePlans: HealthInsurancePlanModel[] = [];
  professionsList!: OptionsSelectModel[];
  clinicsList!: ClinicModel[];
  clinicsListOptions!: OptionsSelectModel[];
  professionalsList!: OptionsSelectModel[];
  allProfessionalsList!: OptionsSelectModel[];
  allProfessionals: ProfessionalModel[] = [];
  patientTagsList!: OptionsSelectModel[];
  schedulingStatus: SchedulingStatusModel[] = [];
  attendanceType: AttendanceTypeModel[] = [];

  attendanceTimeId!: number;

  proceduresList: ProcedureModel[] = [];

  viewMore: boolean = false;

  adminTabIsActive: boolean = true;
  medicalOrderTabIsActive: boolean = false;
  examTabIsActive: boolean = false;
  downloadMedicalOrderDocuments: boolean = false;
  documentList: DocumentModel[] = [];
  thereIsDocuments: boolean = false;
  medicalOrder: MedicalOrderModel = {};


  isSendOximeterChecked!: boolean;
  maxDate: string = '';
  minDate: string = '';
  maxDays: number = 1;

  periodOptions: OptionsSelectModel[] = [
    { label: '08:00 às 12:00', value: 'FIRST_PERIOD' },
    { label: '12:00 às 14:00', value: 'SECOND_PERIOD' },
    { label: '14:00 às 17:00', value: 'THIRD_PERIOD' },
    { label: '17:00 às 19:00', value: 'FOURTH_PERIOD' },
  ];

  phoneTypeList: OptionsSelectModel[] = [
    { label: "Whatsapp", value: "WHATSAPP" },
    { label: "Comercial", value: "COMERCIAL" },
    { label: "Residencial", value: "RESIDENTIAL" },
  ];

  maritalStatusList: OptionsSelectModel[] = [
    { value: "MARRIED", label: "Casado" },
    { value: "SINGLE", label: "Solteiro" },
    { value: "DIVORCED", label: "Divorciado" },
    { value: "WIDOWED", label: "Viúvo" }
  ];

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.idAttendance = params["idAtendimento"];
      this.idSchedule = params["idAgenda"];
      if (this.idAttendance && this.idSchedule) {
        this.isRegister = false;
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.isWeekly = params['isWeekly'];
      this.isPatientPage = params["patientId"];
      if (!this.idSchedule) {
        this.idSchedule = params['scheduleId'];
      }
      this.date = moment(params['date']);
      this.attendanceTimeId = params['attendanceTimeId'];
    })
    this.scheduleService.retrieve(this.idSchedule).subscribe(schedule => {
      this.formGroup.get('schedule')?.setValue(schedule.name);
      this.subTitle = schedule.name ?? '';
    });
    this.queryParams = {
      date: moment(this.date).format('YYYY-MM-DD'),
      scheduleId: this.idSchedule,
      isWeekly: this.isWeekly
    }
    this.initializeForm();
  }

  get loadingPage(): boolean {
    let fetchLists = this.schedulingStatus && this.attendanceType && this.allProfessionalsList;
    if (fetchLists) {
      if (this.isRegister) {
        return false;
      } else if (this.isFetchSchedules) {
        return false;
      }
    }
    return true;
  }

  cancelHandler() {
    if (!this.isPatientPage) {
      this.router.navigate(['/dashboard/schedules/'], { queryParams: this.queryParams });
    } else {
      this.router.navigate([`/dashboard/patients/edit/${this.isPatientPage}/patientSchedulings/`]);
    }
  }

  async submitHandler(type?: String, navigate?: boolean) {
    this.formGroup.markAllAsTouched();
    let attendanceData = this.formGroup.getRawValue() as AttendanceModel;
    attendanceData['date_time'] = this.date;
    if (this.patientFormGroup.get('id')?.value == null) {
      this.patientFormGroup.get('id')?.setValue(undefined);
    }
    (this.phoneNumberForms?.value as PhoneModel[]).forEach((phone, index) => {
      if (phone.id == null) this.phoneNumberForms.at(index)?.get('id')?.setValue(undefined);
    });
    attendanceData.patient = this.patientFormGroup.getRawValue();
    attendanceData.repeat = [];
    if (type == "patient") {
      this.submittingPatient = true;
    } else {
      this.submitting = true;
    }
    if (this.isRegister) {
      this.attendanceRegister(attendanceData, type, navigate);
    } else this.attendanceEdit(attendanceData, type, navigate);
  }

  attendanceRegister(attendanceData: AttendanceModel, page: String | undefined, navigate: Boolean = true) {
    if (attendanceData.polysomnography) {
      if (attendanceData.polysomnography.sent_at) {
        attendanceData.polysomnography.id = undefined;
        attendanceData.polysomnography.sent_at = moment(this.polysomnographyFormGroup.get('sent_at')?.value).format();
      } else {
        attendanceData.polysomnography = undefined;
      }
    }
    this.scheduleService.attendanceCreate(this.idSchedule, attendanceData).subscribe((response) => {
      this.idAttendance = response.id;
      this.existingPatient = response.patient;
      if (navigate) {
        if (page == "patient") this.router.navigate([`/dashboard/patients/edit/${this.existingPatient!.id}/personal`]);
        else this.router.navigate(['/dashboard/schedules'], { queryParams: this.queryParams });
      }
      this.submitting = false;
      this.submittingPatient = false;
      this.isRegister = false;
      this.toast.success("Agendamento criado com sucesso", "Sucesso");
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    });
  }

  attendanceEdit(attendanceData: AttendanceModel, page: String | undefined, navigate: Boolean = true) {
    if (attendanceData.polysomnography) {
      if (attendanceData.polysomnography.sent_at) {
        attendanceData.polysomnography.sent_at = moment(this.polysomnographyFormGroup.get('sent_at')?.value).format();
        if (!attendanceData.polysomnography.id) {
          attendanceData.polysomnography.id = undefined;
        }
      } else {
        attendanceData.polysomnography = undefined;
      }
    }

    this.scheduleService.updateAttendance(this.idSchedule, this.idAttendance, attendanceData).subscribe((response) => {
      if (navigate) {
        if (!this.isPatientPage) {
          if (page == "patient") this.router.navigate([`/dashboard/patients/edit/${this.existingPatient!.id}/personal`]);
          else this.router.navigate(['/dashboard/schedules'], { queryParams: this.queryParams });
        } else {
          this.router.navigate([`/dashboard/patients/edit/${this.isPatientPage}/patientSchedulings/`]);
        }
      }
      this.toast.success("Agendamento alterado com sucesso", "Sucesso");
      this.submitting = false;
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    });
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja excluir este atendimento?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.scheduleService.removeAttendance(this.idSchedule, this.idAttendance).subscribe(() => {
          this.submitting = true;
          this.router.navigate(['/dashboard/schedules'], { queryParams: this.queryParams });
          this.toast.success("Agendamento excluido com sucesso", "Sucesso");
        }, (errorResponse: HttpErrorResponse) => {
          this.mapErrorResponse(errorResponse);
        });
      }
      this.submittingRemove = false;
    });
  }

  checkFormAndEmitDocument(type: string) {
    if (type == "refund") {
      this.emitDocumentsRefundLoading = true;
    } else {
      this.emitDocumentsReceptionLoading = true;
    }
    if (this.formGroup.dirty) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "O documento será gerado conforme os dados salvos. Este formulário não está salvo.\nDeseja salvar antes de emitir o documento?";
      modalRef.result.then((result) => {
        if (result) {
          this.submitHandler(undefined, false).then(response => {
            this.emitDocuments(type);
          });
        } else {
          this.emitDocumentsLoading = false;
        }
      });
    } else this.emitDocuments(type);
  }

  emitDocuments(type: string) {
    this.registersService.documentEmit(this.idSchedule, this.idAttendance, type).subscribe(response => {
      if (type == "refund") {
        this.emitedDocumentsRefund = true;
        this.emitDocumentsRefundLoading = false;
      } else {
        this.emitedDocumentsReception = true;
        this.emitDocumentsReceptionLoading = false;
      }
      response.forEach(document => {
        if (document.status_code && document.status_code >= 200) {
          let url = document.content;
          window.open(url, '_blank');
        }
      });
    }, error => {
      this.emitDocumentsReceptionLoading = false;
      this.emitDocumentsRefundLoading = false;
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
    });
  }

  checkFormAndEmitMedicalOrder() {
    this.emitDocumentsLoading = true;
    if (this.formGroup.dirty) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "O documento será gerado conforme os dados salvos. Este formulário não está salvo.\nDeseja salvar antes de emitir o documento?";
      modalRef.result.then((result) => {
        if (result) {
          this.submitHandler(undefined, false).then(response => {
            this.emitMedicalOrder();
          });
        } else {
          this.emitDocumentsLoading = false;
        }
      });
    } else this.emitMedicalOrder();
  }

  emitMedicalOrder() {
    this.medicalOrder = this.documentsForm.getRawValue() as MedicalOrderModel;
    this.medicalOrder.birdid_account = parseInt(sessionStorage.getItem('birdid_account') || '0')
    this.registersService.medicalOrderEmit(this.idSchedule, this.idAttendance, this.medicalOrder).subscribe(response => {
      this.emitDocumentsLoading = false;
      this.emitedDocuments = true;
      if (this.downloadMedicalOrderDocuments) {
        response.forEach(patientDocument => {
          let url = patientDocument.file;
          window.open(url, '_blank');
        })
      } else {
        this.toast.success("Documentos emitidos com sucesso.", "Sucesso!", {
          closeButton: true,
        });
      }
    }, error => {
      this.emitDocumentsLoading = false;
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
    });
  }

  initializeForm() {
    this.fetchPatientsList();
    this.fetchProcedures();
    this.fetchSchedulingStatus();
    this.fetchAttendanceTypes();
    this.fetchProfessionsList();
    this.fetchClinicsList();
    this.fetchProfessionalsList();
    this.fetchHealthInsurancesList();
    this.fetchPatientTags();
    this.addPhoneNumber();
    this.addHealthInsurance();
    if (this.idAttendance) this.fetchAttendanceExistingData();
    else this.setAttendanceData();
  }

  handleZipCodeChange() {
    this.searchZipcode = true;
    let zipcode = this.addressFormGroup.get('zipcode')?.value;
    this.addressFormGroup.disable();
    this.brApiService.getAddressByZipCode(zipcode).subscribe(response => {
      this.addressFormGroup.patchValue({
        address: response.street,
        neighborhood: response.neighborhood,
        city: response.city,
        state: response.state
      });
      this.searchZipcode = false;
      this.addressFormGroup.enable();
    }, (error: HttpErrorResponse) => {
      this.addressFormGroup.get('zipcode')?.setErrors({response: 'Cep não encontrado'});
      this.searchZipcode = false;
      this.addressFormGroup.enable();
    });
  }

  private createOptionFromClinicRequest(request: ClinicExamsRequestModel): OptionsSelectModel {
    let formattedDate = moment(request.created_at).format('DD/MM/YYYY');

    return {
      label: `Profissional: ${request.professional__name} | Solicitado em: ${formattedDate}`,
      value: request.id?.toString()
    } as OptionsSelectModel;
  }

  fetchClinicExamRequests(patientId: number) {
    this.fetchingClinicExamRequests = true;
    this.registersService.listClinicExamRequestsWithoutScheduling(patientId).subscribe({
      next: response => {
        this.clinicExamRequestsOptions = [];
        response.forEach(request => {
          let option = this.createOptionFromClinicRequest(request);
          this.clinicExamRequestsOptions.push(option);
          this.clinicExamRequests.push(request);
        });

        if (!this.isRegister && this.existingAttendanceData.clinic_exams_request) {
          let option = this.createOptionFromClinicRequest(this.existingAttendanceData.clinic_exams_request);
          this.clinicExamRequestsOptions.push(option);
          this.clinicExamRequests.push(this.existingAttendanceData.clinic_exams_request);
          let exams = this.existingAttendanceData.clinic_exams_request.exams;
          if (exams) {
            exams.forEach(_exam => {this.examRequest.push(_exam)})
          }
        }

        this.fetchingClinicExamRequests = false;
      }, error: error => {
        console.log(error);
        this.fetchingClinicExamRequests = false;
      }
    });
  }

  setExamRequest(event: any) {
    console.log(event)
    if (event) {
      this.examRequest = [];
      let clinicExamRequest = this.clinicExamRequests.find(_obj => _obj.id == event);
      if (clinicExamRequest) {
        if (clinicExamRequest.exams) {
          clinicExamRequest.exams.forEach(exam => this.examRequest.push(exam));
        }
      }
    } else{
      this.examRequest = [];
    }
  }

  fetchSchedulingStatus() {
    this.registersService.schedulingsStatusAll().subscribe(response => {
      this.schedulingStatus = response as SchedulingStatusModel[];
    }, error => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
    });
  }

  fetchAttendanceTypes() {
    this.scheduleService.getAttendanceTypes(this.attendanceTimeId).subscribe(response => {
      this.attendanceType = response.allowed_attendance_types as AttendanceTypeModel[];
      this.formGroup.get('attendance_type')?.setValue(this.attendanceType[0]?.id);
    }, error => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
    });
  }

  fetchProcedures() {
    this.registersService.procedureList().subscribe((result) => {
      this.proceduresList = result.results!;
    });
  }

  fetchProfessionsList() {
    this.professionService.listAll().subscribe(response => {
      this.professionsList = response.map(item => {
        console.log(item)
        return {
          label: item.name,
          value: item.name
        } as OptionsSelectModel
      });
    });
  }

  fetchClinicsList() {
    this.clinicsService.listAll().subscribe(response => {
      this.clinicsList = response;
      this.clinicsListOptions = response.map(item => {
        return {
          label: item.name,
          value: item.id?.toString()
        } as OptionsSelectModel
      });
    });
  }

  fetchProfessionalsList() {
    this.professionalService.listAll().subscribe(response => {
      this.allProfessionals = response;
      this.allProfessionalsList = response.map(item => {
        return {
          label: `${item.name} - CRO ${item.cro}`,
          value: item.id!.toString()
        } as OptionsSelectModel;
      });
    });
  }

  fetchHealthInsurancesList() {
    this.registersService.listAllHealthInsurances().subscribe(response => {
      this.healthInsurancesList = response;
      this.healthInsurancesListAll = response.map(item => {
        return {
          label: item.name,
          value: item.id!.toString()
        } as OptionsSelectModel
      });
    });
  }

  fetchPatientTags() {
    this.registersService.listAllPatientTags().subscribe(response => {
      this.patientTagsList = response.map(item => {
        return {
          label: item.name,
          value: item.id.toString ()
        } as OptionsSelectModel;
      });
    });
  }

  fetchAttendanceExistingData() {
    this.scheduleService.getAttendance(this.idSchedule, this.idAttendance).subscribe((response) => {
      this.existingAttendanceData = response;
      this.date = this.existingAttendanceData.date_time;
      if (this.existingAttendanceData.is_active) {
        this.removeButton = "Excluir";
        this.submitButton = "Salvar";
      } else {
        this.button = false;
        this.submitButton = "Salvar";
      }
      this.queryParams = {
        date: moment(this.existingAttendanceData.date_time).format('YYYY-MM-DD'),
        scheduleId: this.idSchedule,
        isWeekly: this.isWeekly
      }
      this.existingAttendanceData.procedures!.forEach(procedure => this.addProcedureFormGroup(procedure));
      this.formGroup.patchValue(this.existingAttendanceData);
      this.formGroup.get('date_time')?.setValue(moment(this.existingAttendanceData.date_time).format('DD/MM/YYYY - HH:mm:ss'));
      let polysomnography = this.existingAttendanceData.polysomnography;
      if (polysomnography) {
        if (polysomnography.sent_at) {
          this.polysomnographyFormGroup.patchValue(polysomnography);
          this.polysomnographyFormGroup.get('sent_at')?.setValue(moment(polysomnography.sent_at).format('YYYY-MM-DD'));
          this.isSendOximeterChecked = true;
        }
      }
      let patient = this.existingAttendanceData.patient;
      this.fetchPatient(patient?.id);
      this.formGroup.get('date_time')?.disable();
      this.formGroup.get('duration')?.disable();
      this.formGroup.get('schedule')?.disable();
      this.isFetchSchedules = true;
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
    });
  }

  sendOximeterChecked() {
    this.isSendOximeterChecked = !this.isSendOximeterChecked;
    if (this.isSendOximeterChecked) {
      this.polysomnographyFormGroup.get('period')?.addValidators([Validators.required]);
    } else this.polysomnographyFormGroup.get('period')?.clearValidators();
    this.polysomnographyFormGroup.get('sent_at')?.disable();
    this.minDate = moment().format('YYYY-MM-DD');
    this.maxDays = moment(this.date).diff(moment(this.minDate), 'days');
    this.polysomnographyFormGroup.get('exam_nights')?.addValidators([Validators.max(this.maxDays)]);
  }

  setDatePolysomnography(value: string) {
    let days = Number(value);
    if (days <= this.maxDays) {
      let date = moment(this.date).subtract(days, "days");
      this.maxDate = moment(this.date).subtract(days, "days").format('YYYY-MM-DD');
      this.polysomnographyFormGroup.get('sent_at')?.setValue(date?.format('YYYY-MM-DD'));
      this.polysomnographyFormGroup.get('sent_at')?.enable();
    }
  }

  fnWorkingDaysUntilDate(date: string, days: number) {
    let _aux = [];
    let _curr = moment(date).subtract(1, 'days');

    while (_aux.length < days) {
      let dayOfWeek = moment(_curr).day();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        _aux.push(moment(_curr));
      }
      _curr = moment(_curr).subtract(1, 'days');
    }

    return _aux;
  }

  setAttendanceData() {
    let date: moment.Moment = this.date;
    let time = {
      name: date.format('YYYY-MM-DDTHH:mm:ssZ'),
      label: date.format('DD/MM/YYYY - HH:mm:ss')
    };
    this.scheduleService.getAvailableTime(this.idSchedule, date.format('YYYY-MM-DD')).subscribe((response) => {
      let existingDateTime = response;
      let duration = existingDateTime.find((d: any) => moment(d.datetime).format('YYYY-MM-DDTHH:mm:ssZ') == time.name);
      this.formGroup.get('duration')?.setValue(duration.duration);
      this.formGroup.get('date_time')?.setValue(time.label);
      this.formGroup.get('date_time')?.disable();
      this.formGroup.get('duration')?.disable();
      this.formGroup.get('schedule')?.disable();
    });
  }

  fetchPatient(patientId: any) {
    if (patientId) {
      this.registersService.patientGet(patientId).subscribe((response) => {
        this.existingPatient = response;
        this.healthInsurances = this.existingPatient.health_insurances;
        this.phoneNumberForms.clear();
        this.healthInsuranceForms.clear();
        this.existingPatient.health_insurances!.forEach(() => this.addHealthInsurance());
        this.existingPatient.phone_numbers!.forEach(() => this.addPhoneNumber());
        this.patientFormGroup.patchValue(this.existingPatient);
        this.formGroup.get('patient_health_insurance')?.setValue(this.existingPatient.health_insurance_plan);
        this.fetchClinicExamRequests(patientId);
        this.formLoadingPatient = true;
      });
    }
  }

  fetchPatientsList() {
    this.registersService.listAllPatients().subscribe(patients => {
      this.patientOptions = patients.map((patient) => {
        let cpf = patient.document_number;
        let phone = patient.phone_numbers?.map((phone) => {
          return `${phone.phone_number}`
        });
        let number = phoneNumberStringFormatter(phone![0]);
        cpf = (cpf == '' || cpf == null) ? "Não cadastrado" : cpf;
        let options = ({
          value: patient.id!.toString(),
          label: `${patient.name} - CPF ${cpf} - Telefone ${number}`
        }) as OptionsSelectModel;

        return options;
      });
    }, error => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
    });
  }

  unsetPatient() {
    this.patientFormGroup.reset();
    this.existingPatient = undefined;
    this.phoneNumberForms.clear();
    this.healthInsuranceForms.clear();
    this.patientFormGroup.patchValue({ tags: [] });
  }

  setPatient(e: any) {
    this.patientFormGroup.reset();
    this.phoneNumberForms.clear();
    this.healthInsuranceForms.clear();
    this.addPhoneNumber();
    this.formLoadingPatient = false;
    if (e != undefined && !e.label) this.fetchPatient(e);
    else if (e && e.label) this.existingPatient = { name: e.label } as PatientModel;
    if (e == undefined) {
      this.patientFormGroup.reset();
      this.formLoadingPatient = true;
    } else this.patientFormGroup.get('name')?.setValue(e);
    this.formLoadingPatient = true;
  }

  getProfessionalsFromClinic(clinicId: any) {
    let clinic = this.clinicsList.find(item => item.id == clinicId);
    this.patientFormGroup.get('professional')?.reset();
    if (clinic) {
      let professionalsList = this.allProfessionals.filter(item => clinic!.professionals?.indexOf(item.id!) != -1);
      this.professionalsList = professionalsList.map(item => {
        return {
          label: `${item.name} - CRO ${item.cro}`,
          value: item.id!.toString()
        } as OptionsSelectModel;
      });
    } else {
      this.professionalsList = [];
    }
  }

  get healthInsuranceForms() {
    return this.patientFormGroup.get('health_insurances') as FormArray;
  }

  get phoneNumberForms() {
    return this.patientFormGroup.get('phone_numbers') as FormArray;
  }

  getPhoneNumberForm(index: number) {
    return this.phoneNumberForms.controls[index] as FormGroup;
  }

  getHealthInsuranceForm(index: number) {
    return this.healthInsuranceForms.controls[index] as FormGroup;
  }

  addPhoneNumber() {
    let phoneNumberFormGroup = this.fb.group({
      id: [],
      country_code: ['+55'],
      phone_number: ['', [Validators.required]],
      type: ['WHATSAPP'],
      is_active: [true]
    });

    this.phoneNumberForms.push(phoneNumberFormGroup);
  }

  removePhoneNumber(at: number) {
    let formGroup = this.phoneNumberForms.controls[at] as FormGroup;
    if (formGroup.get('id')?.value) {
      formGroup.patchValue({ is_active: false });
    } else {
      this.phoneNumberForms.removeAt(at);
    }
  }

  phoneNumberIsActive(index: number) {
    let formGroup = this.phoneNumberForms.controls[index] as FormGroup;
    return formGroup.get('is_active')?.value;
  }

  addHealthInsurance() {
    let healthInsuranceFormGroup = this.fb.group({
      id: [],
      health_insurance_id: [],
      health_insurance_plan: [],
      number: [],
      is_active: [true]
    });
    this.healthInsuranceForms.push(healthInsuranceFormGroup);
  }

  removeHealthInsurance(at: number) {
    let formGroup = this.healthInsuranceForms.controls[at] as FormGroup;
    if (formGroup.get('id')?.value) {
      formGroup.patchValue({ is_active: false });
    } else {
      this.healthInsuranceForms.removeAt(at);
    }
  }

  healthInsuranceIsActive(index: number) {
    let formGroup = this.healthInsuranceForms.controls[index] as FormGroup;
    return formGroup.get('is_active')?.value;
  }

  changeHealthInsurancePlans(value: any) {
    this.healthInsurancePlans = this.healthInsurancesList.find(item => item.id == value)?.plans || [];
  }

  getHealthInsurancePlansFrom(formGroupIndex: number): OptionsSelectModel[] {
    let formGroup = this.healthInsuranceForms.controls[formGroupIndex] as FormGroup;
    let healthInsuranceId = formGroup.get('health_insurance_id')?.value;
    if (this.healthInsurancesList) {
      let healthInsurance = this.healthInsurancesList.find(healthInsurance => healthInsurance.id == healthInsuranceId);
      if (healthInsurance) {
        let plans = healthInsurance.plans?.map(item => {
          return {
            label: item.name,
            value: item.id?.toString()
          } as OptionsSelectModel;
        }) || [];
        return plans;
      }
    }
    return [];
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    } else {
      this.setFormErrors(errorResponse);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
      this.toast.error("Verifique se os campos obrigatórios foram preenchidos corretamente", "Erro");
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["name"]) this.formGroup.get("name")?.setErrors({ response: errorResponse.error['name'] });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if ((this.formGroup.dirty || this.submitting) && this.isRegister) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "As alterações no formulário não foram salvas e serão descartadas, deseja prosseguir?";
      return modalRef.result
    } else
      return true;
  };

  addProcedureFormGroup(procedure: any) {
    if (!procedure.dates)
      procedure.dates = [];
    if (!procedure.value) {
      let defaultProcedure = this.proceduresList.find((value) => {
        return procedure['procedure'] == value.id
      });
      procedure.value = defaultProcedure?.value;
      console.log(procedure);
    }

    let formGroup = this.fb.group({
      procedure: [procedure.id],
      dates: this.fb.array([...procedure.dates]),
      value: [procedure.value]
    });
    this.procedureForms.push(formGroup);
  }

  toggleProcedure(procedure: ProcedureModel) {
    this.formGroup.markAsDirty();
    if (this.procedureIsSelected(procedure)) {
      let index = this.procedureForms.controls.findIndex(formGroup => formGroup.get('procedure')?.value == procedure.id);
      this.procedureForms.removeAt(index);
    } else {
      this.addProcedureFormGroup(procedure);
      this.addDate(procedure);
    }
  }

  toggleProcedureList() {
    this.formGroup.markAsDirty();
    if (this.procedureForms.value.length < this.proceduresList.length) {
      this.proceduresList.forEach(procedure => {
        if (!this.procedureIsSelected(procedure)) {
          this.addProcedureFormGroup(procedure);
        }
      });
    } else {
      this.procedureForms.clear();
    }
  }

  get procedureForms() {
    return this.formGroup.get('procedures') as FormArray;
  }

  procedureIsSelected(procedure: ProcedureModel): boolean {
    return this.procedureForms.controls.some(formGroup => formGroup.value['procedure'] == procedure.id);
  }

  getProcedureFormFromItem(procedure: ProcedureModel): FormGroup {
    return this.procedureForms.controls.find(formGroup => formGroup.value['procedure'] == procedure['id']) as FormGroup;
  }

  getDatesForm(procedure: ProcedureModel): FormArray {
    return this.getProcedureFormFromItem(procedure).get('dates') as FormArray;
  }


  addDate(procedure: ProcedureModel) {
    let formArray = this.getDatesForm(procedure);
    let today = moment().format('YYYY-MM-DD');
    formArray.push(this.fb.control(today));
  }

  removeDate(procedure: ProcedureModel, index: number) {
    let formArray = this.getDatesForm(procedure);
    formArray.removeAt(index);
  }

  getDocumentsByProfessional() {
    this.formLoadingDocument = true;
    let professional = this.documentsForm.get('professional')!.value;
    this.medicalOrder.professional = professional;
    if (!professional) {
      this.documentList = [];
      this.thereIsDocuments = false;
    } else {
      this.registersService.documentsByProfessionalGet(professional).subscribe(response => {
        this.formLoadingDocument = false;
        this.documentList = response;
        this.thereIsDocuments = true;
        this.emitedDocuments = false;
      }, error => {
        const response = error as HttpErrorResponse;
        this.mapErrorResponse(response);
        this.formLoadingDocument = false;
      });
    }
  }

  get documentsForms() {
    return this.documentsForm.get('document_templates') as FormArray;
  }

  getDocumentForm(document: DocumentModel) {
    return this.documentsForms.controls.find(formGroup => formGroup.value['document_template'] == document.id) as FormGroup;
  }

  addDocumentFormGroup(document: number) {
    let today = moment().format('YYYY-MM-DD');
    let documentFormGroup = this.fb.group({
      document_template: [Number(document)],
      date: [today],
      sign: [true]
    });
    this.documentsForms.push(documentFormGroup);
  }

  documentIsSelected(document: DocumentModel): boolean {
    if (this.documentsForms) {
      return this.documentsForms.controls.some(formGroup => formGroup?.get('document_template')?.value == document.id);
    } else {
      return false
    }
  }

  toggleDocument(e: any) {
    let documentForm = this.documentsForms;
    if (e.target.checked) {
      this.addDocumentFormGroup(e.target.value);
    } else {
      let i: number = 0;
      documentForm.controls.forEach((item: any) => {
        if (item.value.document_template == e.target.value) {
          documentForm.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  toggleDocumentsList() {
    let documentsArray: DocumentTemplateModel[] = this.documentsForms.value;
    if (!documentsArray) {
      this.documentList.forEach(document => this.addDocumentFormGroup(Number(document.id)));
    } else if (documentsArray.length != this.documentList.length) {
      this.documentList.forEach(document => {
        if (!documentsArray.some(d => d.document_template == document.id))
          this.addDocumentFormGroup(Number(document.id))
      });
    } else {
      this.documentsForms.clear();
    }
  }

  adminTabHandler() {
    this.medicalOrderTabIsActive = false;
    this.examTabIsActive = false;
    this.adminTabIsActive = true;
  }

  medicalOrderTabHandler() {
    this.adminTabIsActive = false;
    this.examTabIsActive = false;
    this.medicalOrderTabIsActive = true;
  }

  ExamTabHandler () {
    this.adminTabIsActive = false;
    this.medicalOrderTabIsActive = false;
    this.examTabIsActive = true;
  }


  hasErrors(formControlName: string): boolean {
    return (this.formGroup.get(formControlName)!.errors && this.formGroup.get(formControlName)!.touched) || false;
  }
}
