import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {RegistersService} from "../../../../core/services/registers.service";
import {ClinicsService} from "../../../../core/services/clinics.service";
import {OptionsSelectModel} from "../../../../shared/models/option";
import {ClinicExamsRequestModel} from "../../../../core/models/clinic-exams-request.model";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {ClinicExamModel} from "../../../../core/models/clinic-exam.model";
import {PatientModel} from "../../../../core/models/patient.model";
import {ExamsRequestService} from "../../../../core/services/exams-request.service";
import {
  ConfirmationModalComponent
} from "../../../../shared/renderers/components/confirmation-modal/confirmation-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FieldValidator} from 'src/app/shared/renderers/utils/field-validator/field-validator';
import {ProfessionalsService} from "../../../../core/services/professionals.service";

@Component({
  selector: 'app-exams-request-form',
  templateUrl: './exams-request-form.component.html',
  styleUrls: ['./exams-request-form.component.scss']
})
export class ExamsRequestFormComponent implements OnInit {

  id!: number;
  existingClinicExamRequest!: ClinicExamsRequestModel;

  isRegister: boolean = true;

  isClinicSet: boolean = false;
  isPatientSet: boolean = false;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  showRemoveButton: boolean = false;
  showSubmitButton: boolean = true;
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";
  cancelButton: string = "Cancelar"
  subtitle: string = 'Novo Exame';

  fetchingClinics: boolean = false;
  fetchingPatients: boolean = false;
  fetchingExamsRequest: boolean = false;
  fetchingProfessionals: boolean = false;

  clinicList: OptionsSelectModel[] = [];
  patientList: OptionsSelectModel[] = [];
  professionalList: OptionsSelectModel[] = [];

  clinicExamList!: ClinicExamModel[];
  unselectedClinicExamList: ClinicExamModel[] = [];

  selectedClinic!: number;

  isClinicStaff = JSON.parse(localStorage.getItem('isClinicStaff')!);
  userAccessClinicId = JSON.parse(localStorage.getItem('userAccessClinicId')!);

  patientFormGroup = this.fb.group({
    id: [''],
    name: ['', [Validators.required]],
    phone_number: [''],
    birthdate: [''],
    gender: ['']
  });

  formGroup = this.fb.group({
    id: [],
    patient_id: ['', [Validators.required]],
    clinic_id: ['', [Validators.required]],
    professional_id: ['', Validators.required],
    patient: this.patientFormGroup,
    exams: this.fb.array([]),
  });

  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute, private clinicService: ClinicsService,
              private registerService: RegistersService, private professionalService: ProfessionalsService,
              private examsRequestService: ExamsRequestService, private toast: ToastrService, private router: Router,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      if (this.id) {
        this.isRegister = false;
        this.showRemoveButton = true;
        this.subtitle = 'Editar Exame';
      }
      this.initializeForm();
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  }

  get fetchingData(): boolean {
    return this.fetchingClinics || this.fetchingPatients || this.fetchingProfessionals || this.fetchingExamsRequest;
  }

  async initializeForm() {
    this.fetchClinicList();
    if (this.isRegister && !this.isClinicStaff) {
      this.fetchPatientList(this.userAccessClinicId);
      this.fetchProfessionalList(this.userAccessClinicId);
      await this.fetchClinicExams(this.userAccessClinicId)
    } else if (!this.isRegister) {
      this.fetchExamsRequest();
    }
  }

   fetchExamsRequest() {
    this.fetchingExamsRequest = true;
    this.examsRequestService.getExamsRequest(this.id).subscribe(async response => {
      await this.fetchClinicExams(response.clinic_id);
      this.fetchPatientList(response.clinic_id);
      this.fetchProfessionalList(response.clinic_id);
      this.setForm(response);
    });
  }

  treatButtons(data: ClinicExamsRequestModel) {
    if (data.payment_status && data.payment_status == 'APPROVED') {
      this.showSubmitButton = false;
      this.showRemoveButton = false;
      this.cancelButton = 'Voltar'
    }
  }

  setForm(data: ClinicExamsRequestModel) {
    this.existingClinicExamRequest = data;
    this.examForms.clear();
    data.exams?.forEach(() => this.addExam());
    data.exams?.forEach((examRequest, index) => {
      let formGroup = this.examForms.controls[index] as FormGroup;
      if (examRequest.minimum_value) {
        console.log(examRequest.minimum_value)
        formGroup.get('value')?.addValidators(FieldValidator.minimumValue(examRequest.minimum_value))
      }
    })
    this.treatButtons(data)
    this.formGroup.patchValue(this.existingClinicExamRequest);
    this.patientFormGroup.get('name')?.setValue(data.patient_id);
    this.setPatientPhoneNumber(data.patient);
    this.fetchingExamsRequest = false;
    this.isPatientSet = true;
    this.isClinicSet = true;
  }

  async fetchClinicExams(clinicId: any) {
    this.clinicExamList = await this.registerService.clinicExams(clinicId).toPromise();
    this.unselectedClinicExamList = this.clinicExamList;
  }

  fetchClinicList() {
    if (this.isClinicStaff) {
      this.fetchingClinics = true;
      this.clinicService.clinicsListAllActive().subscribe(response => {
        this.fetchingClinics = false;
        this.clinicList = response.map(item => {
          return {value: item.id!.toString(), label: item.name!, item: item}
        })
      })
    } else {
      this.isClinicSet = true;
      this.formGroup.get('clinic_id')?.setValue(this.userAccessClinicId);
    }
  }

  fetchPatientList(clinicId: any) {
    this.fetchingPatients = true;
    this.registerService.listAllClinicPatients(clinicId).subscribe(response => {
      this.fetchingPatients = false;
      this.patientList = response.map(item => {
        let cpf = item.document_number ?? '-';
        return {value: item.id?.toString(), label: `${item.name} - CPF ${cpf}`, item: item}
      })
    })
  }

  fetchProfessionalList(clinicId: any) {
    this.fetchingProfessionals = true;
    this.professionalService.listAllByCLinic(clinicId).subscribe(response => {
      this.fetchingProfessionals = false;
      this.professionalList = response.map(item => {
        return {
          label: `${item.name} - CRO ${item.cro}`,
          value: item.id!.toString()
        } as OptionsSelectModel;
      })
    })
  }

  setPatientPhoneNumber(patient: PatientModel | undefined) {
    let phone = patient?.phone_numbers?.map((phone: any) => {
      return `${phone.country_code}${phone.phone_number}`
    });
    if (phone) {
      this.patientFormGroup.get('phone_number')?.setValue(phone![0]);
    }
    this.patientFormGroup.get('phone_number')?.disable()
  }

  setPatientForm(event: any) {
    let patient = this.patientList.find(item => item.value == event)?.item;
    this.patientFormGroup.patchValue(patient);
    this.formGroup.get('patient_id')?.setValue(patient.id)
    this.setPatientPhoneNumber(patient)
    this.patientFormGroup.get('name')?.setValue(event);
    this.isPatientSet = true;
  }

  async setClinic(event: any) {
    if (event && event != this.selectedClinic) {
      this.selectedClinic = event;
      this.isClinicSet = true;
      this.fetchPatientList(event)
      this.fetchProfessionalList(event)
      await this.fetchClinicExams(event)
    }
  }

  get examForms() {
    return this.formGroup.get('exams') as FormArray;
  }

  getExamForm(index: number) {
    return this.examForms.controls[index] as FormGroup;
  }

  addExam() {
    let examFormGroup = this.fb.group({
      id: [],
      clinic_exam_id: ['', [Validators.required]],
      value: ['', [Validators.required]],
      additional_information: [''],
      is_active: [true]
    });
    this.examForms.push(examFormGroup);
    this.filterSelectedExams();
  }

  removeExam(index: number) {
    let formGroup = this.getExamForm(index);
    if (formGroup.get('id')?.value) {
      formGroup.get('is_active')?.setValue(false);
    } else  {
      this.examForms.removeAt(index);
    }
  }

  treatExamsRequest(index: number) {
    this.filterSelectedExams();
    let examForm = this.getExamForm(index);
    let examId = examForm.get('clinic_exam_id')?.value
    let clinicExam = this.clinicExamList.find(item => item.id == examId) as ClinicExamModel;
    if (clinicExam.clinic_value && clinicExam.minimum_value) {
      examForm.get('value')?.setValue(clinicExam.clinic_value)
      examForm.get('value')?.addValidators([FieldValidator.minimumValue(clinicExam.minimum_value)]);
    }
  }

  filterSelectedExams() {
    let selectedExams = this.examForms.value.map((item: any) => item.clinic_exam_id);
    this.unselectedClinicExamList = this.clinicExamList.filter(item => !selectedExams.includes(item.id) && item.show_in_listing);
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar este exame?";
    modalRef.result.then((result: boolean) => {
      if (result) {
        this.examRemove();
      } else this.submittingRemove = false;
    })
  }

  examRemove() {
    this.examsRequestService.examsRequestDelete(this.id).subscribe(response => {
      this.toast.success("Exame desativado com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/exams-request']);
    }, (error: HttpErrorResponse) => {
      this.submittingRemove = false;
      this.mapErrorResponse(error);
    })
  }

  examsRequestRegister(data: ClinicExamsRequestModel) {
    this.examsRequestService.examsRequestRegister(data).subscribe(response => {
      this.toast.success("Exame criado com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/exams-request']);
    }, (error: HttpErrorResponse) => {
      this.submitting = false;
      this.mapErrorResponse(error);
    })
  }

  examsRequestEdit(data: ClinicExamsRequestModel) {
    this.examsRequestService.examsRequestEdit(data).subscribe(response => {
      this.toast.success("Exame editado com sucesso", "Sucesso")
      this.router.navigate(['/dashboard/exams-request']);
    }, (error: HttpErrorResponse) => {
      this.submitting = false;
      this.mapErrorResponse(error);
    })
  }

  submitHandler() {
    this.formGroup.markAllAsTouched();
    this.submitting = true;
    let data = this.formGroup.getRawValue() as ClinicExamsRequestModel;
    if (this.formGroup.valid) {
      if (this.isRegister) {
        this.examsRequestRegister(data)
      } else this.examsRequestEdit(data)
    } else this.submitting = false;
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/exams-request']);
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["name"]) this.formGroup.get("name")?.setErrors({ response: errorResponse.error['name'] });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  getCancelClass() {
    if (this.existingClinicExamRequest && this.existingClinicExamRequest.payment_status == 'APPROVED') {
      return 'btn btn-primary btn-lg'
    }
    return 'btn btn-link btn-lg'
  }

}
