import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { ProceduresListComponent } from './procedures-list/procedures-list.component';
import { ProceduresFormComponent } from './procedures-form/procedures-form.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ProceduresListComponent,
    ProceduresFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RenderersModule,
    CurrencyMaskModule,
    ReactiveFormsModule,
  ]
})
export class ProceduresModule { }
