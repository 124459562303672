import { Component, OnInit } from '@angular/core';
import {LeadsModel} from "../../../core/models/leads.model";
import {PermissionConst} from "../../../core/models/permission-const.model";
import {RegistersService} from "../../../core/services/registers.service";
import {SessionManagerService} from "../../../core/services/session-manager.service";
import {Title} from "@angular/platform-browser";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {CdkDragDrop, transferArrayItem} from "@angular/cdk/drag-drop";
import {LeadModalComponent} from "../../../shared/components/modals/lead-modal/lead-modal.component";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit {
  data: LeadsModel[] = [];
  filteredData: LeadsModel[] = [];
  organizedData: { name: string, color: string, data: LeadsModel[] }[] = [
    { name: 'LEAD', color: '#D06D69', data: [] },
    { name: 'QUIZ', color: '#704216', data: [] },
    { name: 'CONVERSA', color: '#A96324', data: [] },
    { name: 'AGUARDANDO PAGAMENTO AVALIAÇÃO', color: '#DA954A', data: [] },
    { name: 'AVALIAÇÃO AGENDADA', color: '#314E1D', data: [] },
    { name: 'AVALIAÇÃO REALIZADA', color: '#48742C', data: [] },
    { name: 'AGUARDANDO PAGAMENTO EXAMES', color: '#78A65A', data: [] },
    { name: 'EXAMES AGENDADOS', color: '#2754C5', data: [] },
    { name: 'EXAMES REALIZADOS', color: '#4B77D1', data: [] },
    { name: 'RETORNO AGENDADO', color: '#1E134A', data: [] },
    { name: 'CADASTRO INVÁLIDO', color: '#666666', data: [] },
    { name: 'SEM INTERESSE', color: '#D16D69', data: [] },
    { name: 'SEM RETORNO', color: '#EF9E39', data: [] },
    { name: 'RETORNO REALIZADO', color: '#48752A', data: [] },
  ];

  formGroup = this.fb.group({
    start: [],
    end: [],
    name: [],
    whatsapp: []
  });

  get canNewData() {
    const permissions = [PermissionConst.add_lead, PermissionConst.change_lead, PermissionConst.view_lead];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get canExport() {
    const permissions = [PermissionConst.export_lead, PermissionConst.change_lead, PermissionConst.view_lead];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  constructor(private registersService: RegistersService, private sessionManager: SessionManagerService,
              private titleService: Title, private modalService: NgbModal, private toastr: ToastrService,
              private fb: FormBuilder) {
    this.titleService.setTitle('ELEVE - Leads');
  }

  ngOnInit(): void {
    this.fetchLeads();
  }

  fetchLeads(): void {
    this.registersService.leadsAll().subscribe({
      next: (response) => {
        this.data = response;
        this.filteredData = response;
        this.filterData();
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  mountKanban(): void {
    this.organizedData.forEach(column => {
      column.data = [];
    });

    this.filteredData.forEach(lead => {
      let column: any = this.organizedData[0];

      if (lead.status) {
        column = this.organizedData.find(column => column.name == lead.status);
      }

      column.data.push(lead);
    });
  }

  onDrop(event: CdkDragDrop<LeadsModel[]>) {
    let lead = event.item.data as LeadsModel;
    let newStatus = event.container.id;
    lead.status = newStatus;
    transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    this.registersService.leadsEdit(lead.id!, lead).subscribe({
      next: (response) => {},
      error: (error) => {
        this.toastr.error('Não conseguimos editar o status deste card. Tente novamente.', 'Erro');
      }
    });
  }

  openModal(id?: number): void {
    let modalRef = this.modalService.open(LeadModalComponent);
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.leadId = id;

    modalRef.result.then(response => {
      let data = this.data.find(lead => lead.id == response['id']);
      if (data) {
        data.name = response['name'];
        data.whatsapp = response['whatsapp'];
      } else {
        this.data.unshift(response);
      }
      this.filterData();
    });
  }

  openWhatsapp(event: any, lead: LeadsModel) {
    event.stopPropagation();
    window.open(`whatsapp://send?phone=${lead.whatsapp}`, '_parent');
  }

  filterData() {
    let startedAt = this.formGroup.get('start')?.value;
    let endedAt = this.formGroup.get('end')?.value;
    let name = this.formGroup.get('name')?.value;
    let whatsapp = this.formGroup.get('whatsapp')?.value;

    this.filteredData = this.data.filter(lead => {
      if (startedAt && lead.created_at <= startedAt) {
        return false;
      }

      if (endedAt && lead.created_at >= endedAt) {
        return false;
      }

      if (lead.name && name && !lead.name.toLowerCase().includes(name.toLowerCase())) {
        return false;
      }

      if (lead.whatsapp && whatsapp && !lead.whatsapp.includes(whatsapp)) {
        return false;
      }

      return true;
    });

    this.mountKanban();
  }

  clearFilters(): void {
    this.formGroup.reset();
    this.filterData();
  }
}
