<div class="row p-0 m-0 header">
  <div class="col-12">
    <app-header title="Prontuário" [subTitle]="subTitle"></app-header>
  </div>
</div>

<div *ngIf="!loadingPage && existingPatient else register" [formGroup]="formGroup">
  <div class="row p-0 m-0">
    <div class="col-12 col-md-4 m-0 mb-4 p-0">
      <div class="card">
        <h3>Últimos atendimentos</h3>

        <div *ngIf="medicalRecords.length == 0">
          <span class="first_attendance">
            Este é o primeiro atendimento deste paciente.
          </span>
        </div>

        <div class="mb-4" *ngFor="let medicalRecord of medicalRecords, index as index">
          <button class="nav__button d-flex align-items-center justify-content-between p-0"
                  (click)="showMedicalRecordHandler(index)">
            <span class="p-0">{{medicalRecord.date}}</span>
            <div>
              <span class="IMC p-0 mr-4" [ngClass]="getIMCColor(medicalRecord.imc)">
                {{getIMCLabel(medicalRecord.imc)}}
              </span>
              <span class="i icon icon-Icon-awesome-caret-down p-0"
                    [ngClass]="showMedicalRecord[index] ? 'showing' : ''"></span>
            </div>
          </button>
          <div *ngIf="showMedicalRecord[index]">
            <div class="d-flex justify-content-end mr-4">
              <div class="d-flex imc__box m-2">
                <span>Altura</span>
                <span>{{medicalRecord.height}}m</span>
              </div>
              <div class="d-flex imc__box m-2">
                <span>Peso</span>
                <span>{{medicalRecord.weight}}kg</span>
              </div>
            </div>
            <span class="notes pt-2">{{medicalRecord.notes}}</span>
            <div class="mt-3" *ngIf="medicalRecord.exams">
              <span class="title__exam pl-2">exames solicitados</span>
              <div *ngFor="let exam of medicalRecord.exams" class="mt-2">
                <button class="nav__button d-flex align-items-center p-0" (click)="downloadExam(exam.file!)">
                  <span class="i icon icon-download teste p-0"></span>
                  <span class="notes exams pl-2">{{getType(exam.type)}} - {{exam.exam__name}} </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="hasNext" (click)="nextPage()">
          <div class="text-center loading-next">
            <ng-container *ngIf="!loadingNext">Carregar mais antigos</ng-container>
            <ng-container *ngIf="loadingNext">Carregando</ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-8 pr-0">
      <div class="card" [formGroup]="formGroup">
        <div class="row p-0 m-0 mb-3">
          <div class="col-3">
            <app-basic-input id="height" controlName="height" [maskOptions]="{mask: '0,00'}"
                             [dropSpecialCharacters]="false"
                             label="Altura (m)" [formGroup]="formGroup">
            </app-basic-input>
          </div>

          <div class="col-3">
            <app-basic-input id="weight" controlName="weight" [maskOptions]="{mask: '0*,000'}"
                             [dropSpecialCharacters]="false"
                             label="Peso (kg)" [formGroup]="formGroup">
            </app-basic-input>
          </div>

          <div class="col-4 column">
            <label class="field__title mb-4">IMC</label>
            <span [ngClass]="getIMCColor()" *ngIf="imc != 0">{{ imc.toFixed(1) }} {{ getIMCLabel() }}</span>
          </div>
        </div>
        <div class="row p-0 m-0 mb-4">
          <div class="col-12">
            <app-textarea-input type="text" label="Observações" id="notes" [formGroup]="formGroup" [rows]="6"
                                controlName="notes"></app-textarea-input>
          </div>
        </div>
        <div class="row p-0 m-0 mb-4">
          <div class="col-12 m-0 p-0 ">
            <label class="field__title">Exames<span *ngIf="attachmentFeedback"> - {{attachmentFeedback}}</span></label>
          </div>
          <div class="col-9 p-0 m-0">
            <div class="selector row m-0 mb-2 p-0" *ngFor="let externalExam of externalExams, index as index">
              <div class="col-12 row p-0 m-0">
                <input [id]="index" type="checkbox" (click)="toggleExternalExam(externalExam)"
                       [checked]="externalExamIsSelected(externalExam)" [disabled]="attachmentFeedback">
                <label class="ml-2 label__exam" [for]="index">
                  {{ externalExam.external_exam_description }}
                </label>
                <div class="ml-1 column">
                  <label class="ml-2 btn btn-link report" *ngIf="externalExamIsSelected(externalExam)">
                    <input type="file" (change)="addAttachment($event, externalExam)" class="d-none" name="file[]"
                           multiple="true" *ngIf="!attachmentFeedback"> Adicionar arquivo
                  </label>
                </div>
                <div *ngIf="externalExamIsSelected(externalExam)" class="col-12">
                  <div *ngFor="let attachment of getAttachments(externalExam.external_exam_id), let index = index" class="column">
                    <div class="d-flex align-items-center">
                      <input class="report-attachment" [value]="attachment.filename"
                             (change)="setFilename(attachment, $event)">
                      <button class="ml-2 btn btn-link report" (click)="deleteAttachment(attachment)">
                        <i class="icon-Icon-ionic-ios-close"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <span class="font-italic">
                {{externalExam.additional_information}}
              </span>
            </div>

            <ng-container *ngIf="externalExams.length == 0">
              <div *ngFor="let exam of examsList, index as index" class="selector row m-0 mb-2 p-0">
                <div class="col-12 row p-0 m-0">
                  <input [id]="index" (click)="toggleExam(exam)" type="checkbox" [checked]="examIsSelected(exam)"
                         [disabled]="attachmentFeedback">
                  <label class="ml-2 label__exam" [for]="index">
                    {{exam.name}}
                  </label>
                  <div class="ml-1 column">
                    <label class="ml-2 btn btn-link report" *ngIf="examIsSelected(exam)">
                      <input type="file" (change)="addAttachment($event, exam)" class="d-none" name="file[]"
                             [accept]="exam.attachment" multiple="true" *ngIf="!attachmentFeedback"> Adicionar arquivo
                    </label>
                  </div>
                  <div class="ml-1">
                    <button class="ml-2 btn btn-link report" (click)="editReport(exam)"
                            *ngIf="examIsSelected(exam) && exam.is_report && !success(exam)"
                            [disabled]="attachmentFeedback">
                      Adicionar Laudo
                    </button>
                    <button class="ml-2 btn btn-link report" (click)="editReport(exam)"
                            *ngIf="examIsSelected(exam) && exam.is_report && success(exam)"
                            [disabled]="attachmentFeedback">
                      Editar laudo
                    </button>
                  </div>
                </div>
                <div *ngIf="examIsSelected(exam)" class="col-12">
                  <div *ngFor="let attachment of getAttachments(exam.id), let index = index" class="column">
                    <div class="d-flex align-items-center">
                      <input class="report-attachment" [value]="attachment.filename"
                             (change)="setFilename(attachment, $event)">
                      <button class="ml-2 btn btn-link report" (click)="deleteAttachment(attachment)">
                        <i class="icon-Icon-ionic-ios-close"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-3">
            <div class="row">
              <input type="checkbox" id="download" [checked]="downloadIsChecked()" (change)="toggleDownload()">
              <label class="pl-2 m-0 label__exam" for="download">
                Baixar Laudos
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-end mt-5 mb-5">
        <div class="col-12 col-md-3 mb-3">
          <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
            Cancelar
          </button>
        </div>

        <div class="col-12 col-md-3 mb-3">
          <button (click)="saveHandler()" [disabled]="submittingSave" class="btn btn-outline-primary btn-lg"
                  type="button">
            Salvar
            <span class="spinner-border submitting" *ngIf="submittingSave"></span>
          </button>
        </div>

        <div class="col-12 col-md-3 mb-3">
          <button (click)="submitHandler()" [disabled]="submitting" class="btn btn-primary btn-lg" type="button">
            Finalizar
            <span class="spinner-border submitting" *ngIf="submitting"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #register>
  <div *ngIf="!loadingPage && isRegister && !existingPatient && patientList.length > 0 else loadingTemplate">
    <div class="row">
      <div class="col-12 col-md-4 m-0 mb-4 p-0">
        <div class="card">
          <h3>Últimos atendimentos</h3>
          <span>Selecione um paciente</span>
        </div>
      </div>

      <div class="col-12 col-md-8 pr-0">
        <div class="card" [formGroup]="formDate">
          <div class="row mb-3">
            <div class="col-8 p-0">
              <label for="date" class="field__title">Início</label>
              <div class="field__date">
                <label for="time" class="mr-2">Dia: </label>
                <input type="date" formControlName="date"/>
                <label for="time" class="mx-2">Hora: </label>
                <input type="time" formControlName="time"/>
              </div>
            </div>
            <div class="col-4 p-0">
              <label for="time" class="field__title">Duração</label>
              <div class="field__date field__date--duration">
                <input type="number" formControlName="duration" class="mr-2"/> min
              </div>
            </div>
          </div>
          <div class="row" [formGroup]="formGroup">
            <div class="col-12 col-md-6 p-0 m-0">
              <label class="field__title">Tipo de atendimento</label>
              <div *ngFor="let type of attendanceType" class="selector d-flex align-content-center mb-1">
                <label [for]="'attendance_type_'+type.id">
                  <input formControlName="attendance_type_id" type="radio" [id]="'attendance_type_'+type.id"
                         [value]="type.id" class="mr-2">
                  {{type.name}}</label>
              </div>
            </div>

            <div class="col-12 col-md-6 p-0 pt-3 p-md-0 m-0">
              <app-select-input id="patient_id" label="Paciente" [formGroup]="formGroup" [options]="patientList"
                                [searchable]="false" [clearable]="true"></app-select-input>
            </div>
          </div>
        </div>
        <div class="row justify-content-end mt-5 mb-5">
          <div class="col-12 col-md-3 mb-3">
            <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
              Cancelar
            </button>
          </div>

          <div class="col-12 col-md-3 mb-3">
            <button (click)="newRegister()" [disabled]="submitting" class="btn btn-primary btn-lg" type="button">
              Continuar
              <span class="spinner-border submitting" *ngIf="submitting"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div class="col card mx-4 full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
