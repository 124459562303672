import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { ProcedureModel } from 'src/app/core/models/procedure.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import { DefaultModalComponent } from 'src/app/shared/renderers/components/default-modal/default-modal.component';

@Component({
  selector: 'app-procedures-form',
  templateUrl: './procedures-form.component.html',
  styleUrls: ['./procedures-form.component.scss']
})
export class ProceduresFormComponent implements OnInit {

  id!: number;
  loadingPage: boolean = false;

  formGroup: FormGroup = this.fb.group({
    code: ['',[Validators.required]],
    name: ['', [Validators.required]],
    value: ['']
  });
  

  fetchedProcedureData!: ProcedureModel;

  isRegister: boolean = true;

  disabledSalvar: boolean = false;
  disabledRemover: boolean = false;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton?: String = "Desativar";
  submitButton?: String = "Salvar";

  button: boolean = false;
  registerSuccess: boolean = false;

  constructor(private registersService: RegistersService, private router: Router, private activatedRoute: ActivatedRoute, config: NgbModalConfig, private modalService: NgbModal, private sessionManager: SessionManagerService, private toast: ToastrService,
    private fb: FormBuilder) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      if (this.id) {
        this.isRegister = false;
        this.initializeForm();
      }
    })
  }

  async initializeForm() {
    await this.fetchProcedureExistingData();
    if (!this.sessionManager.getUserPermission(PermissionConst.change_procedure) || !this.sessionManager.getUserPermission(PermissionConst.add_procedure)) {
      this.disabledSalvar = true;
      this.disabledRemover = true;
      this.formGroup.disable();
    }
    if (this.sessionManager.getUserPermission(PermissionConst.remove_procedure) && this.sessionManager.getUserPermission(PermissionConst.add_procedure)) {
      this.disabledRemover = false;
    }
    if (!this.sessionManager.getUserPermission(PermissionConst.remove_procedure)) {
      this.disabledRemover = true;
    }
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/registers/procedures'])
  }

  submitHandler() {
    this.submitting = true;
    const procedureData = this.getFormData();
    console.log(procedureData);
    if (this.isRegister) this.procedureRegister(procedureData);
    else this.procedureEdit(procedureData);
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar este procedimento?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.procedureRemove();
      }
      this.submittingRemove = false;
    });
  }

  async procedureRegister(procedureData: any) {
    try {
      await this.registersService.procedureRegister(procedureData).toPromise();
      this.registerSuccess = true;
      this.router.navigate(['/dashboard/registers/procedures']);
      this.toast.success('Procedimento criado com sucesso', 'Sucesso');
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    }
  }

  async procedureEdit(procedureData: ProcedureModel) {
    try {
      procedureData.id = this.id;
      await this.registersService.procedureEdit(procedureData).toPromise();
      this.registerSuccess = true;
      this.router.navigate(['/dashboard/registers/procedures'])
      this.toast.success('Procedimento alterado com sucesso', 'Sucesso');
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    }
  }

  async procedureRemove() {
    try {
      await this.registersService.procedureRemove(this.id).toPromise();
      this.router.navigate(['/dashboard/registers/procedures']);
      this.toast.success('Procedimento desativado com sucesso', 'Sucesso');
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    }
  }

  async fetchProcedureExistingData() {
    try {
      this.loadingPage = true;
      this.fetchedProcedureData = await this.registersService.procedureGet(this.id).toPromise();
      if (this.fetchedProcedureData.is_active) {
        this.button = true;
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
      }
      else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
      }
      this.setFormData();
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
    }
  }

  getFormData() {
    return {
      name: this.formGroup.get("name")?.value,
      code: this.formGroup.get("code")?.value,
      value: this.formGroup.get("value")?.value,
      is_active: true
    }
  }

  setFormData() {
    this.formGroup.get("code")?.setValue(this.fetchedProcedureData.code);
    this.formGroup.get("name")?.setValue(this.fetchedProcedureData.name);    
    this.formGroup.get("value")?.setValue(this.fetchedProcedureData.value);
    this.loadingPage = false;
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro", {
        closeButton: true,
      });
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["name"]) this.formGroup.get("name")?.setErrors({ response: errorResponse.error['name'] });
    if (errorResponse.error["code"]) this.formGroup.get("code")?.setErrors({ response: errorResponse.error['code'] });    
    if (errorResponse.error["value"]) this.formGroup.get("value")?.setErrors({ response: errorResponse.error['value'] });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if ((this.formGroup.dirty || this.submitting) && !this.registerSuccess && this.isRegister) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "As alterações no formulário não foram salvas e serão descartadas, deseja prosseguir?";
      return modalRef.result
    }
    else
      return true;
  };

  hasErrors(formControlName: string): boolean {
    return (this.formGroup.get(formControlName)!.errors && this.formGroup.get(formControlName)!.touched) || false;
  }
}
