<div *ngIf="control">
  <div class="error" *ngIf="control!.touched && control!.errors && control!.hasError('required') && !isModalReport; else responseError">
    <label class="__label">Este campo é obrigatório</label>
  </div>

  <ng-template #responseError>
    <div class="error"
         *ngIf="control!.errors && control!.hasError('response'); else minlengthError">
      <label [ngClass]="isModalReport ? '__label-report ml-4' : '__label'">{{control.errors!.response}}</label>
    </div>
  </ng-template>

  <ng-template #minlengthError>
    <div class="error"
         *ngIf="control!.touched && control!.errors && control!.hasError('minlength'); else emailError">
      <label class="__label">Deve ter no mínimo
        {{control.errors!.minlength.requiredLength}} caracteres</label>
    </div>
  </ng-template>

  <ng-template #emailError>
    <div class="error"
         *ngIf="control!.touched && control!.errors && control!.hasError('email'); else documentError">
      <label class="__label">E-mail inválido</label>
    </div>
  </ng-template>

  <ng-template #documentError>
    <div class="error"
         *ngIf="control!.touched && control!.errors && control!.hasError('document'); else documentError2">
      <label class="__label">CPF inválido</label>
    </div>
  </ng-template>

  <ng-template #documentError2>
    <div class="error"
         *ngIf="control!.touched && control!.errors && control!.hasError('document2'); else documentError3">
      <label class="__label">RG inválido</label>
    </div>
  </ng-template>

  <ng-template #documentError3>
    <div class="error"
         *ngIf="control!.touched && control!.errors && control!.hasError('document3'); else phoneError">
      <label class="__label">CNPJ inválido</label>
    </div>
  </ng-template>

  <ng-template #phoneError>
    <div class="error" *ngIf="control!.touched && control!.errors && control!.hasError('phone_number'); else minError">
      <label class="__label">Telefone inválido</label>
    </div>
  </ng-template>

  <ng-template #minError>
    <div class="errorMaxMin" *ngIf="control!.touched && control!.errors && control!.hasError('min'); else maxError">
      <label class="__label">O valor mínimo desse campo é {{control!.errors['min'].min}}</label>
    </div>
  </ng-template>

  <ng-template #maxError>
    <div class="errorMaxMin" *ngIf="control!.touched && control!.errors && control!.hasError('max'); else minimumValue">
      <label class="__label">O valor máximo desse campo é {{control!.errors['max'].max}}</label>
    </div>
  </ng-template>

  <ng-template #minimumValue>
    <div class="errorMaxMin" *ngIf="control!.touched && control!.errors && control!.hasError('minimumValue')">
      <label class="__label">O valor mínimo é R${{control!.errors['value']}}</label>
    </div>
  </ng-template>
</div>
