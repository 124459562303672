import {DOCUMENT} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs';
import {AccountModel} from 'src/app/core/models/account.model';
import {PermissionConst} from 'src/app/core/models/permission-const.model';
import {PermissionModel} from 'src/app/core/models/permission.model';
import {AccountsService} from 'src/app/core/services/accounts.service';
import {ProfessionalsService} from 'src/app/core/services/professionals.service';
import {RegistersService} from 'src/app/core/services/registers.service';
import {ScheduleService} from 'src/app/core/services/schedules.service';
import {SessionManagerService} from 'src/app/core/services/session-manager.service';
import {OptionsSelectModel} from 'src/app/shared/models/option';
import {
  ConfirmationModalComponent
} from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import {FieldValidator} from 'src/app/shared/utils/field-validator';
import {ClinicsService} from "../../../../../core/services/clinics.service";
import {ClinicModel} from "../../../../../core/models/clinic.model";
import {ProfessionalModel} from "../../../../../core/models/professional.model";

@Component({
  selector: 'app-system-users-form',
  templateUrl: './system-users-form.component.html',
  styleUrls: ['./system-users-form.component.scss']
})
export class SystemUsersFormComponent implements OnInit {

  formGroup = this.fb.group({
    id: [],
    name: ['', [Validators.required, Validators.minLength(3)]],
    email: ['', [Validators.required, Validators.email]],
    birthdate: ['', [Validators.required]],
    document_number: ['', [Validators.required, FieldValidator.documentValidator()]],
    user_permissions: this.fb.array([]),
    professional_id: [],
    schedule_permissions: this.fb.array([]),
    clinics: [],
    font_color: [],
    background_color: [],
    isExternalAccount: [false, []]
  });

  passwordRecover: boolean = false;

  imageFile!: File | null;
  changedImage: boolean = false;

  isRegister: boolean = true;
  isToConnect: boolean = false;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  submitButton?: String = "Salvar";
  removeButton?: String = "Desativar";
  button: boolean = false;

  userPermissions!: PermissionModel[];
  loadingPermissions: boolean = true;
  isSelectAll: boolean = false;
  disabledSelectAll: boolean = false;
  isCurrentUser: boolean = false;
  isStaffAccount: boolean = true;
  disabledPermissions: string[] = [];
  fetchingPermissions: boolean = false;

  professionalsList: OptionsSelectModel[] = [];
  scheduleList: OptionsSelectModel[] = [];
  attendanceTypeList: OptionsSelectModel[] = [];
  clinicList!: ClinicModel[];

  id!: number;
  existingAccountData: AccountModel = {};
  fetchingAccount: boolean = false;

  isClinicStaff = JSON.parse(localStorage.getItem('isClinicStaff')!);
  userAccessClinicId = JSON.parse(localStorage.getItem('userAccessClinicId')!);

  constructor(@Inject(DOCUMENT) private document: Document, private fb: FormBuilder,
    private accountsService: AccountsService, private router: Router, private activatedRoute: ActivatedRoute,
    private modalService: NgbModal, private sessionManager: SessionManagerService,
    private toast: ToastrService, private professionalService: ProfessionalsService,
    private scheduleService: ScheduleService, private registerService: RegistersService,
    private clinicsService: ClinicsService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params["id"];
      if (this.id) {
        this.isRegister = false;
      }
    });
    this.initializeForm();
  }

  get loadingPage(): boolean {
    let fetchLists = this.scheduleList && this.professionalsList;
    if (this.fetchingPermissions && fetchLists) {
      if (this.isRegister) {
        return true;
      } else {
        if (this.fetchingAccount) return true;
      }
    }
    return false;
  }

  get canSave() {
    if (this.id) {
      if (this.isCurrentUser) {
        return true;
      }
      return this.sessionManager.getUserPermission(PermissionConst.change_account);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.add_account);
    }
  }

  get canGrantPermissions(): boolean {
    return this.sessionManager.getUserPermission(PermissionConst.grant_permissions);
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_account);
  }

  initializeForm() {
    this.fetchUserPermissions();
    this.fetchSchedulesList();
    this.fetchProfessionalsList();
    this.fetchClinicsList();
    this.treatAccessClinic();
    if (this.id) setTimeout(() => this.fetchAccount(), 1000);
  }

  fetchSchedulesList() {
    this.scheduleService.all().subscribe(response => {
      this.scheduleList = response.map(item => {
        return {
          label: item.name,
          value: item.id?.toString(),
          item: item
        } as OptionsSelectModel;
      });
    });
  }

  fetchProfessional():Observable<ProfessionalModel[]> {
    if (this.isClinicStaff) {
      return this.professionalService.listAllAssociatedWithAccount()
    }
    return this.professionalService.listAllAssociatedWithAccountByClinic(this.userAccessClinicId)
  }

  fetchProfessionalsList() {
    this.fetchProfessional().subscribe(response => {
      this.professionalsList = response.map(item => {
        return {
          label: `${item.name} - CRO ${item.cro}`,
          value: item.id!.toString()
        } as OptionsSelectModel;
      });
    });
  }

  fetchClinicsList() {
    this.clinicsService.clinicsListAllActive().subscribe(response => this.clinicList = response);
  }

  redefinePassword() {
    this.router.navigate([`/dashboard/registers/systemUsers/redefine_password/${this.id}`]);
  }

  get scheduleForms() {
    return this.formGroup.get('schedule_permissions') as FormArray;
  }

  getFormSchedule(index: number) {
    return this.scheduleForms.controls[index] as FormGroup;
  }

  addSchedule() {
    let scheduleFormGroup = this.fb.group({
      id: [],
      schedule: ['', [Validators.required]],
      attendance_types: [this.attendanceTypeList.map(el => el.value), [Validators.required]],
      is_active: [true]
    });
    this.scheduleForms.push(scheduleFormGroup);
  }

  removeSchedule(at: number) {
    this.scheduleForms.removeAt(at);
  }

  scheduleIsActive(index: number) {
    let formGroup = this.scheduleForms.controls[index] as FormGroup;
    return formGroup.get('is_active')?.value;
  }

  changeAttendanceType(formGroupIndex: number) {
    let formGroup = this.scheduleForms.controls[formGroupIndex] as FormGroup;
    let schedule = formGroup.get('schedule')?.value;
    if (schedule) {
      this.registerService.attendancesTypeAll().subscribe((response) => {
        this.attendanceTypeList = response.map(item => {
          return {
            label: item.name,
            value: item.id?.toString()
          } as OptionsSelectModel
        });
      });
    } else this.attendanceTypeList = [];
  }

  onSelectAll(formGroupIndex: number) {
    let formGroup = this.scheduleForms.controls[formGroupIndex] as FormGroup;
    const selected = this.attendanceTypeList.map(item => item.value);
    formGroup.get('attendance_types')?.patchValue(selected);
  }

  onImageSelect(file: any) {
    this.imageFile = file;
    this.changedImage = true;
  }

  treatAccessClinic() {
    if (!this.isClinicStaff && this.isRegister) {
      this.formGroup.get('clinics')?.setValue([this.userAccessClinicId]);
      this.isStaffAccount = false;
    }
  }

  cancelHandler() {
    this.router.navigate(['dashboard/registers/systemUsers']);
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    this.formGroup.removeControl('isExternalAccount');
    this.formGroup.addControl('is_staff', this.fb.control(this.isStaffAccount))
    let accountData = this.formGroup.getRawValue() as AccountModel;
    if (this.isRegister) {
      this.systemUserRegister(accountData);
    } else if (this.isToConnect) {
      this.connectAccount();
    } else {
      this.systemUserEdit(accountData);
    }
  }

  deactivateAccount() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar este usuário?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.systemUserRemove();
      }
      this.submittingRemove = false;
    })
  }

  revokeAccount() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja remover este usuário desta clinica ?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.isRegister = false;
        this.fetchingAccount = false;
        let data = {clinic_id: this.userAccessClinicId}
        this.accountsService.revokeAccount(this.existingAccountData, data).subscribe(response => {
        this.toast.success('Usuário removido desta clínica', "Sucesso");
        this.router.navigate(['dashboard/registers/systemUsers/']);
        }, (errorResponse: HttpErrorResponse) => {
          this.mapErrorResponse(errorResponse);
        })
      }
      this.submittingRemove = false;
    })
  }

  removeHandler() {
    if (this.isClinicStaff) {
      this.deactivateAccount();
    } else {
      this.revokeAccount();
    }
  }

  systemUserRegister(accountData: AccountModel) {
    if (!this.isClinicStaff) {
      accountData.user_permissions = [];
    }
    let errorResponse = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    };
    this.accountsService.accountRegister(accountData).subscribe(response => {
      if (this.changedImage) {
        this.accountsService.uploadImage(this.imageFile!, response.id!).subscribe(() => { }, errorResponse);
      }
      this.toast.success("Usuário criado com sucesso", "Sucesso");
      this.submitting = false;
      this.cancelHandler();
    }, errorResponse);
  }

  systemUserEdit(accountData: AccountModel) {
    let errorResponse = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    };
    accountData.is_active = true;
    this.accountsService.accountEdit(accountData).subscribe(response => {
      if (this.changedImage) {
        this.accountsService.uploadImage(this.imageFile!, response.id!).subscribe(() => { }, errorResponse);
      }
      let currentUser = this.sessionManager.accountData.id;
      if (response.id == currentUser) {
        this.sessionManager.setSessionData(response);
      }
      this.toast.success("Usuário alterado com sucesso", "Sucesso");
      this.submitting = false;
      this.cancelHandler();
    }, errorResponse);
  }

  systemUserRemove() {
    this.accountsService.accountRemove(this.id).subscribe(response => {
      this.toast.success("Usuário removido com sucesso", "Sucesso");
      this.submittingRemove = false;
      this.cancelHandler();
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submittingRemove = false;
    });
  }

  get permissionsMap() {
    let mapping = {};
    this.userPermissions.map((permission) => {
      if (permission.id)
        Object.assign(mapping, { [permission.id]: permission.codename });
    });
    return mapping;
  }

  groupBy(arr: any[], property: string) {
    return arr.reduce((acc, cur) => {
      acc[cur[property]] = [...acc[cur[property]] || [], cur];
      return acc;
    }, {});
  }

  get permissionModels() {
    return Object.keys(this.groupBy(this.userPermissions, 'model'));
  }

  getPermissions(name: string): [] {
    return this.groupBy(this.userPermissions, 'model')[name];
  }

  onCheckChange(event: any) {
    let userPermissions: FormArray = this.formGroup.get('user_permissions') as FormArray;
    if (event.target.checked) {
      userPermissions.push(this.fb.control(event.target.value));
      this.enableCheckbox(event.target.id);
    } else {
      let controls = userPermissions.controls;
      userPermissions = this.fb.array(controls.filter(control => control.value != event.target.value));
      this.formGroup.setControl('user_permissions', userPermissions);
      this.disableCheckbox(event.target.id);
    }
  }

  isChecked(permission: PermissionModel) {
    let formArray = this.formGroup.get('user_permissions') as FormArray;
    return formArray.value.some((id: number) => id == permission.id);
  }

  disableCheckbox(codename: string) {
    let permissionModel = codename.split('_')[1];
    let userPermissions: FormArray = this.formGroup.get('user_permissions') as FormArray;
    let controls = userPermissions.controls;

    if (codename.includes('view')) {
      this.disabledPermissions.push(`change_${permissionModel}`);
      this.disableCheckbox(`change_${permissionModel}`);
      this.disabledPermissions.push(`export_${permissionModel}`);
      this.disableCheckbox(`export_${permissionModel}`);
    } else if (codename.includes('change')) {
      this.disabledPermissions.push(`add_${permissionModel}`);
      this.disableCheckbox(`add_${permissionModel}`);
    } else if (codename.includes('add')) {
      this.disabledPermissions.push(`remove_${permissionModel}`);
      this.disableCheckbox(`remove_${permissionModel}`);
    }

    this.disabledPermissions.forEach(codename => {
      let index = Object.values(this.permissionsMap).indexOf(codename);
      let id = Object.keys(this.permissionsMap)[index];
      controls = controls.filter(control => control.value != id);
    });
    userPermissions = this.fb.array(controls);
    this.formGroup.setControl('user_permissions', userPermissions);
  }

  enableCheckbox(codename: string) {
    if (!codename)
      return;
    let permissionModel = codename.split('_')[1];

    if (codename.includes('view')) {
      this.disabledPermissions = this.disabledPermissions.filter(_codename => _codename != `change_${permissionModel}`);
      this.disabledPermissions = this.disabledPermissions.filter(_codename => _codename != `export_${permissionModel}`);
    } else if (codename.includes('change')) {
      this.disabledPermissions = this.disabledPermissions.filter(_codename => _codename != `add_${permissionModel}`);
    } else if (codename.includes('add')) {
      this.disabledPermissions = this.disabledPermissions.filter(_codename => _codename != `remove_${permissionModel}`);
    }
  }

  toggleAllPermissions(event: any) {
    if (event.target.checked) {
      if (!this.disabledSelectAll) {
        let userPermissions: FormArray = this.formGroup.get('user_permissions') as FormArray;
        if (event.target.checked) {
          this.userPermissions.forEach((permission) => {
            userPermissions.push(this.fb.control(permission.id));
            this.enableCheckbox(permission.codename ? permission.codename : '');
          });
        } else {
          userPermissions = this.fb.array([]);
          this.formGroup.setControl('user_permissions', userPermissions);
          this.userPermissions.forEach((permission,) => {
            this.disableCheckbox(permission.codename ? permission.codename : '');
          });
        }
      }
    }
  }

  clickedOnExternalUser(event: any) {
    if (event.target.checked) {
      this.isStaffAccount = false;
      if (this.isRegister) {
        let userPermissions = this.fb.array([]);
        this.formGroup.setControl('user_permissions', userPermissions);
      }
    } else {
      this.isStaffAccount = true;
    }
  }

  setExternalUserCheckBox(data: AccountModel) {
    if (data.is_staff !== undefined) {
      this.isStaffAccount = data.is_staff;
      this.formGroup.get('isExternalAccount')?.setValue(!this.isStaffAccount);
    }
  }

  fetchUserPermissions() {
    this.accountsService.permissionsList().subscribe(response => {
      this.userPermissions = response;
      let userPermissions: FormArray = this.formGroup.get('user_permissions') as FormArray;
      this.userPermissions.forEach(permission => {
        userPermissions.push(this.fb.control(permission.id));
        this.enableCheckbox(permission.codename ?? '');
      });
      this.fetchingPermissions = true;
    }, error => this.mapErrorResponse(error as HttpErrorResponse));
  }

  fetchAccount() {
    this.accountsService.accountGet(this.id).subscribe(response => {
      this.setForm(response);
    }, error => this.mapErrorResponse(error as HttpErrorResponse))
  }

  setForm(data: AccountModel) {
    this.existingAccountData = data;
    this.isCurrentUser = this.id == this.sessionManager.accountData.id;
    this.passwordRecover = this.isCurrentUser;
    this.setExternalUserCheckBox(data);
    this.formGroup.patchValue(this.existingAccountData);
    let permissionControls = this.fb.array([]);
    this.existingAccountData.user_permissions?.forEach(value => {
      let control = this.fb.control(value);
      permissionControls.push(control);
      let codename = PermissionConst[value];
      this.enableCheckbox(codename);
    });
    this.isSelectAll = data.user_permissions?.length == this.userPermissions?.length;
    if (this.existingAccountData.professional) {
      let currentProfessional = {
        label: `${data.professional?.name} - CRO ${data.professional?.cro}`,
        value: data.professional?.id!.toString()
      };
      this.professionalsList.push(currentProfessional);
    }
    this.formGroup.setControl('user_permissions', permissionControls);
    data.schedule_permissions?.forEach(() => this.addSchedule());
    this.formGroup.patchValue(data);
    data.schedule_permissions?.forEach((schedule, index) => {
      let formGroup = this.scheduleForms.controls[index] as FormGroup;
      this.changeAttendanceType(index);
      formGroup.get('attendance_types')?.setValue(schedule.attendance_types.map((item: any) => { return item.toString() }));
    });

    if (this.fetchingPermissions) {
      this.fetchingAccount = true;
    }
    this.treatFormsAndButtons();
  }

  treatFormsAndButtons() {
    if (this.isClinicStaff) {
      if (this.existingAccountData.is_active) {
      this.removeButton = "Desativar";
      this.submitButton = "Salvar";
      this.button = true;
      this.disabledSelectAll = false;
    } else {
      this.submitButton = "Reativar";
      this.scheduleForms.disable();
      this.formGroup.disable();
      this.button = false;
      this.disabledSelectAll = true;
    }
    } else if (this.existingAccountData.clinics?.includes(this.userAccessClinicId)){
      this.submitButton = 'Salvar'
      this.removeButton = 'Revogar'
      this.button = true;
    } else {
      this.submitButton = 'Conectar'
      this.button = false;
    }
  }

  searchAccount(document_number: any) {
    if (document_number) {
      this.isRegister = false;
      this.fetchingAccount = false;
      this.accountsService.searchAccount(document_number).subscribe(response => {
        if (response) {
          this.connectAccountOrRedirect(response);
        } else {
          this.isRegister = true;
        }
      }, error => {
        this.isRegister = true;
      })
    }
  }

  connectAccountOrRedirect(data: AccountModel) {
    if (this.isClinicStaff || data.clinics?.includes(this.userAccessClinicId)) {
      this.router.navigate([`dashboard/registers/systemUsers/edit/${data.id}`]);
    }
    this.button = false;
    this.submitButton = 'Connectar'
    this.isToConnect = true;
    this.setForm(data);
    this.formGroup.disable();
    this.formGroup.get('document_number')?.enable();
  }

  connectAccount() {
    let data = {clinic_id: this.userAccessClinicId}
    this.accountsService.connectAccount(this.existingAccountData, data).subscribe(response => {
      this.submitting = false;
      this.toast.success('Usuário conectado com sucesso', "Sucesso");
      this.router.navigate(['dashboard/registers/systemUsers']);
    })
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    this.document.getElementById('main-container')?.scroll({ top: 0 });
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    } else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "name", "birthdate", "email", "document_number", "professional_id"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    // if (this.formGroup && (!this.formGroup.dirty)) {
    //     const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    //     modalRef.componentInstance.text = 'As alterações no formulário não foram salvas e serão descartadas, deseja prosseguir?';
    //     return modalRef.result
    // } else
    return true;
  };

}
