import {Component, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MedicalRecordModel} from 'src/app/core/models/medicalRecord.model';
import {RegistersService} from 'src/app/core/services/registers.service';
import * as moment from 'moment';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SessionManagerService} from 'src/app/core/services/session-manager.service';
import {ReportModalComponent} from 'src/app/shared/components/report-modal/report-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {ExamModel} from 'src/app/core/models/examModel.model';
import {AttendanceTypeModel} from 'src/app/core/models/attendance-type.model';
import {DocumentGeneration} from "src/app/core/models/document-generation";
import {last, map, tap} from "rxjs/operators";
import {OptionsSelectModel} from 'src/app/shared/models/option';

@Component({
  selector: 'app-medical-registers',
  templateUrl: './medical-records.component.html',
  styleUrls: ['./medical-records.component.scss']
})
export class MedicalRecordsComponent implements OnInit {
  subTitle: string = "Carregando...";
  id!: number;
  draftId?: number;

  loadingPage: boolean = true;
  isRegister: boolean = true;
  submitting: boolean = false;
  submittingSave: boolean = false;
  existingPatient: boolean = false;
  firstAttendance: boolean = false;
  showMedicalRecord: boolean[] = [];
  download: boolean = false;
  warningFlag: boolean = true;

  hasNext: boolean = false;
  page: number = 1;
  loadingNext: boolean = false;

  attendanceType: AttendanceTypeModel[] = [];
  patientList: OptionsSelectModel[] = [];
  medicalRecords: MedicalRecordModel[] = [];
  examsList: ExamModel[] = [];

  examsListController: {
    exam_id: number,
    variables: {},
    success: boolean
  }[] = [];

  attachmentsController: {
    external_exam_id: number,
    exam__id: number,
    temporary_file_id: string,
    filename: string
  }[] = [];

  examController: {
    isSelected: boolean,
    exam_id: number
  }[] = [];

  externalExamsController: {
    isSelected: boolean,
    exam_id: number
  }[] = [];

  patientExamErrors: { name: string | undefined, error: string }[] = [];

  formGroup: FormGroup = this.fb.group({
    patient_id: ['', [Validators.required]],
    professional_id: ['', [Validators.required]],
    height: ['', [Validators.required]],
    weight: ['', [Validators.required]],
    notes: [''],
    attendance_type_id: [''],
    start_time: [moment()],
    end_time: [moment()],
    patient_exam: this.fb.array([]),
    clinic_exams_request_id: []
  });

  formDate: FormGroup = this.fb.group({
    date: [moment().format('YYYY-MM-DD')],
    time: [moment().format('HH:mm')],
    duration: [10]
  })

  attachmentFeedback = '';

  externalExams: { external_exam_id: number, external_exam_description: string, additional_information: string }[] = [];

  constructor(private registerService: RegistersService, private activatedRoute: ActivatedRoute,
              private fb: FormBuilder, private sessionManager: SessionManagerService, private modalService: NgbModal,
              private toast: ToastrService, private router: Router, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    let userAccount = this.sessionManager.getSessionData();
    this.formGroup.get("professional_id")?.setValue(userAccount.professional_id);

    this.activatedRoute.params.subscribe(params => {
      this.id = params["id"];
      if (this.id) {
        this.isRegister = false;
        this.activatedRoute.queryParams.subscribe(params => {
          let start_time = params['date'];
          this.formGroup.get('start_time')?.setValue(moment(start_time).format());
        });
        this.checkIfMedicalRecordAlreadyExists();
      } else {
        this.subTitle = "Novo Registro";
      }
    });
    this.initializeForm();
  }


  checkIfMedicalRecordAlreadyExists() {
    let startTime = this.formGroup.get('start_time')?.value;
    this.registerService.getMedicalRecordDraftByPatientAndStartTime(this.id, startTime).subscribe(async response => {
      this.draftId = response.id ?? undefined;
      this.formGroup.patchValue(response);
      let examController = this.examController;
      if (response.clinic_exams_request_id) {
        this.externalExams = await this.registerService.getExternalExamsByExamRequest(response.clinic_exams_request_id).toPromise()
        this.externalExams.forEach(_ => {
          this.externalExamsController.push({exam_id: _.external_exam_id, isSelected: false});
        });
        examController = this.externalExamsController;
      }
      response.exams?.forEach(exam => {
        let examData = examController.find(_controller => _controller.exam_id == (exam.exam_id || exam.external_exam_id));
        console.log('examData', examData);
        if (examData) {
          examData.isSelected = true;
        }
        if (exam.hasOwnProperty('variables')) {
          let reportData = this.examsListController.find(examController => examController.exam_id == exam.exam_id);
          if (reportData) {
            reportData.success = true;
            reportData.variables = exam.variables!;
          }
        } else {
          let attachmentData = {
            exam__id: exam.exam_id!,
            temporary_file_id: exam.temporary_file_id!,
            external_exam_id: exam.external_exam_id!,
            filename: exam.filename!
          };
          this.attachmentsController.push(attachmentData);
        }
      });
    });
  }

  getType(type: string | undefined) {
    if (!type)
      return '';
    if (type === 'ATTACHMENT')
      return 'Anexo';
    return 'Laudo';
  }

  initializeForm() {
    if (this.id) {
      this.fetchPatient();
    } else {
      this.fetchPatientList();
      this.fetchAttendancesTypes();
    }
    this.fetchExamsList();
  }

  cancelHandler() {
    this.router.navigate(["dashboard/charts"]);
  }

  newRegister() {
    this.id = this.formGroup.get('patient_id')?.value;
    let hour_start = moment(this.formDate.get('time')?.value, 'HH:mm');
    let hour_end = moment(this.formDate.get('time')?.value, 'HH:mm');
    let duration = this.formDate.get('duration')?.value;
    let dateStart = moment(this.formDate.get('date')?.value).set({
      hour: hour_start.get('hour'),
      minute: hour_start.get('minute'),
      second: hour_start.get('second')
    });
    let dateEnd = moment(this.formDate.get('date')?.value).set({
      hour: hour_end.get('hour'),
      minute: hour_end.get('minute') + duration,
      second: hour_end.get('second')
    });
    this.formGroup.get('start_time')?.setValue(dateStart.format());
    this.formGroup.get('end_time')?.setValue(dateEnd.format());
    if (this.id) {
      this.loadingPage = true;
      this.fetchPatient();
    } else {
      this.formGroup.get('patient_id')?.setErrors({response: 'Escolha um paciente'});
    }
  }

  saveHandler() {
    this.fillForm();
    this.formGroup.markAllAsTouched();

    let medicalRecordData = this.formGroup.getRawValue();
    if (medicalRecordData.height && typeof medicalRecordData.height == 'string')
      medicalRecordData.height = medicalRecordData.height.replace(',', '.');
    if (medicalRecordData.weight && typeof medicalRecordData.weight == 'string')
      medicalRecordData.weight = medicalRecordData.weight.replace(',', '.');
    this.formGroup.disable();
    if (!this.isRegister) {
      medicalRecordData.attendance_type_id = null;
      medicalRecordData.end_time = null;
      medicalRecordData.start_time = medicalRecordData.start_time;
    }
    this.submittingSave = true;
    if (this.draftId) {
      medicalRecordData.id = this.draftId;
      this.registerService.updateMedicalRecordDraft(medicalRecordData).subscribe(response => {
        this.toast.success('Rascunho salvo.', 'Sucesso');
        this.submittingSave = false;
        this.router.navigate(['/dashboard/charts']);
      }, (error: HttpErrorResponse) => {
        this.mapErrorResponse(error);
        this.formGroup.enable();
        this.submittingSave = false;
      });
    } else {
      this.registerService.createMedicalRecordDraft(medicalRecordData).subscribe(response => {
        this.toast.success('Rascunho salvo.', 'Sucesso');
        this.submittingSave = false;
        this.router.navigate(['/dashboard/charts']);
      }, (error: HttpErrorResponse) => {
        this.mapErrorResponse(error);
        this.formGroup.enable();
        this.submittingSave = false;
      });
    }
  }

  submitHandler() {
    this.submitting = true;
    console.log('submit');
    this.fillForm();
    if (!this.examsValid) {
      console.log('1');
      this.submitting = false;
      return;
    }
    if (!this.hasAttachmentIfMedicalReportSelected()) {
      console.log('2');
      this.toast.error('Os laudos tem que ter pelo menos um anexo.', 'Atenção');
      this.submitting = false;
      return;
    }
    if (this.formGroup.valid && this.patientExamForm.valid) {
      console.log('3');
      let medicalRecordData = this.formGroup.getRawValue();
      if (medicalRecordData.height && typeof medicalRecordData.height == 'string')
        medicalRecordData.height = medicalRecordData.height.replace(',', '.');
      if (medicalRecordData.weight && typeof medicalRecordData.weight == 'string')
        medicalRecordData.weight = medicalRecordData.weight.replace(',', '.');
      this.formGroup.disable();
      if (!this.isRegister) {
        medicalRecordData.attendance_type_id = null;
        medicalRecordData.end_time = null;
        medicalRecordData.start_time = medicalRecordData.start_time;
      }
      this.medicalRecordRegister(medicalRecordData);
    } else if (this.formGroup.get('professional_id')!.value == null) {
      this.formGroup.enable();
      this.formGroup.markAllAsTouched();
      this.toast.error('Usuário deve estar associado a um profissional', 'Erro');
      this.submitting = false;
    } else {
      this.formGroup.enable();
      this.formGroup.markAllAsTouched();
      this.toast.error('Preencha todos os campos', 'Erro');
      this.submitting = false;
    }
  }

  medicalRecordRegister(medicalRecordData: MedicalRecordModel) {
    this.registerService.medicalRecordPost(medicalRecordData).subscribe(result => {
      this.toast.success('Registro médico emitido com sucesso.', 'Sucesso');
      this.storeDocuments(result);
      this.submitting = false;
      this.router.navigate(['/dashboard/charts']);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.formGroup.enable();
      this.submitting = false;
    });
  }

  storeDocuments(documentsToBeStored: DocumentGeneration[]) {
    let documentsInSessionStorage = sessionStorage.getItem('documents') || '';
    let storedDocuments = [];
    if (documentsInSessionStorage) {
      storedDocuments = JSON.parse(documentsInSessionStorage)
    }
    storedDocuments.push(...documentsToBeStored);
    sessionStorage.setItem('documents', JSON.stringify(storedDocuments));
  }

  fillForm() {
    console.log('fillform1');
    this.patientExamForm.clear();
    if (this.externalExamsController.length == 0) {
      this.examsListController.forEach(exam => {
        if (this.examController.find(controller => controller.exam_id == exam.exam_id)!.isSelected && this.examsList.find(item => item.id == exam.exam_id)!.is_report) {
          console.log('fillform3');
          if (exam.success) {
            console.log('success');
            let examForm = this.fb.group({
              exam_id: [exam.exam_id, [Validators.required]],
              variables: [exam.variables, [Validators.required]],
            });
            if (examForm.valid) this.patientExamForm.push(examForm);
          } else this.warningFlag = true;
        }
      });
    }

    this.attachmentsController.forEach(attachment => {
      let examsController = this.examController;
      if (this.externalExamsController.length > 0) {
        examsController = this.externalExamsController;
      }
      if (examsController.find(controller => controller.exam_id == (attachment.exam__id || attachment.external_exam_id))!.isSelected) {
        console.log('fillform4')
        let attachmentData: FormGroup;
        if (this.externalExamsController.length == 0) {
          attachmentData = this.fb.group({
            exam_id: [attachment.exam__id, [Validators.required]],
            temporary_file_id: [attachment.temporary_file_id, [Validators.required]],
            filename: [attachment.filename, [Validators.required]]
          });
        } else {
          attachmentData = this.fb.group({
            external_exam_id: [attachment.external_exam_id, [Validators.required]],
            temporary_file_id: [attachment.temporary_file_id, [Validators.required]],
            filename: [attachment.filename, [Validators.required]]
          });
        }
        if (attachmentData.valid) this.patientExamForm.push(attachmentData);
      }
    });
  }

  fetchPatient() {
    this.registerService.patientGet(this.id).subscribe(patient => {
      this.subTitle = patient.name!;
      this.existingPatient = true;
      this.formGroup.get('patient_id')?.setValue(patient.id);
      this.fetchMedicalRecords();
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  fetchAttendancesTypes() {
    this.registerService.attendancesTypeAll().subscribe(response => {
      this.attendanceType = response;
      this.formGroup.get('attendance_type_id')?.setValue(this.attendanceType[0].id);
      this.loadingPage = false;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  fetchPatientList() {
    this.registerService.listAllPatients().subscribe(response => {
      this.patientList = response.map(item => {
        return {
          label: item.name,
          value: item.id?.toString()
        } as OptionsSelectModel
      });
    });
  }

  fetchExamsList() {
    this.registerService.examsTemplateGetAll().subscribe(response => {
      this.examsList = response;
      this.examsList.forEach(exam => {
        let data = {
          exam_id: exam.id!,
          isSelected: false
        };
        let data2 = {
          exam_id: exam.id!,
          variables: {},
          success: false
        }
        this.examController.push(data);
        this.examsListController.push(data2);
      });
    });
  }

  fetchMedicalRecords() {
    this.registerService.medicalRecordsByPatientGet(this.id).subscribe(response => {
      this.medicalRecords = response.results!;
      this.hasNext = response.next != null;
      this.medicalRecords.forEach((item, index) => {
        this.showMedicalRecord[index] = false;
        this.medicalRecords[index].date = moment(this.medicalRecords[index].start_time).format("DD/MM/YYYY");
      });
      if (this.medicalRecords.length > 0) {
        if (this.formGroup.get('height')?.value == '')
          this.formGroup.get("height")!.setValue(String(this.medicalRecords[0].height));
        if (this.formGroup.get('weight')?.value == '')
          this.formGroup.get("weight")!.setValue(String(this.medicalRecords[0].weight));
      }
      this.loadingPage = false;
    });
  }

  showMedicalRecordHandler(index: number) {
    if (this.showMedicalRecord[index]) {
      this.showMedicalRecord[index] = false;
    } else {
      this.showMedicalRecord.forEach((item, index) => {
        this.showMedicalRecord[index] = false;
      });
      this.showMedicalRecord[index] = true;
    }
  }

  nextPage() {
    this.loadingNext = true;
    this.page++;
    this.registerService.medicalRecordsByPatientGet(this.id, this.page).subscribe(response => {
      this.hasNext = response.next != null;
      response.results!.forEach(result => {
        result.date = moment(result.date).format("DD/MM/YYYY");
        this.medicalRecords.push(result);
      });
      this.loadingNext = false;
    })
  }

  get imc() {
    let heightValue = this.formGroup.get('height')?.value;
    let height;
    if (heightValue && typeof heightValue == 'string') {
      height = parseFloat(heightValue.replace(',', '.'));
    } else {
      height = heightValue || 0;
    }

    let weightValue = this.formGroup.get('weight')?.value;
    let weight;
    if (weightValue && typeof weightValue == 'string') {
      weight = parseFloat(weightValue.replace(',', '.'));
    } else {
      weight = weightValue || 0;
    }

    if (height == 0 || weight == 0) return 0;

    return weight / height ** 2;
  }

  getIMCLabel(imc = this.imc) {
    if (imc == 0) return '';

    let response: string = "";
    if (imc < 18.5) response = 'Magreza';
    if (imc >= 18.5 && imc < 25) response = 'Peso Normal';
    if (imc >= 25 && imc < 30) response = 'Sobrepeso';
    if (imc >= 30 && imc < 35) response = 'Obesidade Grau I';
    if (imc >= 35 && imc < 40) response = 'Obesidade Grau II';
    if (imc >= 40) response = 'Obesidade Grau III';
    return response;
  }

  getIMCColor(imc = this.imc) {
    if (imc == 0) return '';

    let response: string = "";
    if (imc < 18.5) response = "blue";
    if (imc >= 18.5 && imc < 25) response = "green";
    if (imc >= 25 && imc < 30) response = "yellow";
    if (imc >= 30 && imc < 35) response = "orange";
    if (imc >= 35 && imc < 40) response = "red";
    if (imc >= 40) response = "purple";
    return response;
  }

  checkFormAndDownloadReport() {
    return;
  }

  getAttachments(exam_id: number | undefined) {
    return this.attachmentsController.filter(attachment => attachment.exam__id == exam_id || attachment.external_exam_id == exam_id);
  }

  addAttachment(event: any, exam: ExamModel) {
    let files: FileList = event.target.files;
    let filenames: string[] = [];
    let formData: FormData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('files', file);
      filenames.push(file.name);
    });
    this.registerService.uploadTemporaryFile(formData).pipe(
      map((event: any, formData: any) => {
        switch (event['type']) {
          case HttpEventType.UploadProgress:
            let percentage = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            return `Envio de arquivo em ${percentage}%.`;
          case HttpEventType.Response:
            event.body.forEach((attachment: any, index: number) => {
              let patientExam = {
                external_exam_id: exam.external_exam_id!,
                temporary_file_id: attachment['id'],
                filename: filenames[index],
                exam__id: exam.id!
              };
              this.attachmentsController.push(patientExam);
            });
            return '';
          default:
            return null;
        }
      }),
      tap(message => {
        this.attachmentFeedback = message || '';
      }),
      last()
    ).subscribe(response => {
      /**/
    }, error => {
      console.error(error);
    });
    event.target.value = '';
  }

  setFilename(attachment: any, $event: any) {
    this.attachmentsController = this.attachmentsController.filter(controller => controller != attachment);
    attachment.filename = $event.target.value;
    this.attachmentsController.push(attachment);
  }

  deleteAttachment(attachment: any) {
    this.attachmentsController = this.attachmentsController.filter(item => item != attachment);
  }

  downloadExam(url: string) {
    window.open(url, '_blank');
  }

  downloadIsChecked() {
    return this.download;
  }

  toggleDownload() {
    this.downloadIsChecked() ? this.download = false : this.download = true;
  }

  editReport(exam: ExamModel) {
    const modalRef = this.modalService.open(ReportModalComponent, {centered: true, size: 'lg', animation: true});
    let examController = this.examsListController.find(controller => controller.exam_id == exam.id!);
    modalRef.componentInstance.exam = examController;
    modalRef.componentInstance.date = this.formGroup.get('start_time')?.value;
    modalRef.result.then(result => {
      let index = this.examsListController.findIndex(controller => controller.exam_id == exam.id!);
      this.examsListController[index].variables = result;
      if (!result && !this.examsListController[index].success) {
        this.examsListController[index].success = false;
      } else {
        this.examsListController[index].success = true;
        this.warningFlag = false;
      }
    });
  }

  toggleExternalExam(exam: { external_exam_id: number, external_exam_description: string }) {
    let index = this.externalExamsController.findIndex(controler => controler.exam_id == exam.external_exam_id);
    if (this.externalExamsController[index].isSelected) {
      this.externalExamsController[index].isSelected = false;
      this.attachmentsController = this.attachmentsController.filter(attachment => attachment.exam__id != exam.external_exam_id);
      this.warningFlag = false;
    } else {
      this.externalExamsController[index].isSelected = true;
    }
  }

  toggleExam(exam: ExamModel) {
    let index = this.examController.findIndex(controler => controler.exam_id == exam.id);
    if (this.examController[index].isSelected) {
      this.examController[index].isSelected = false;
      this.attachmentsController = this.attachmentsController.filter(attachment => attachment.exam__id != exam.id);
      this.warningFlag = false;
    } else {
      this.examController[index].isSelected = true;
    }
  }

  get patientExamForm() {
    return this.formGroup.get('patient_exam') as FormArray;
  }

  get examsValid() {
    this.validateExams();
    return this.patientExamErrors.length == 0;
  }

  success(exam: ExamModel): boolean {
    return this.examsListController.find(controller => controller.exam_id == exam.id)!.success;
  }

  externalExamIsSelected(externalExam: { external_exam_id: number, external_exam_description: string }): boolean {
    return this.externalExamsController.find(controller => controller.exam_id == externalExam.external_exam_id)!.isSelected;
  }

  examIsSelected(exam: ExamModel): boolean {
    return this.examController.find(controller => controller.exam_id == exam.id)!.isSelected;
  }

  validateExams() {
    this.patientExamErrors = [];

    let selectedExamIds = this.examController.map(exam => {
      if (exam.isSelected)
        return exam.exam_id;
      return;
    }).filter(id => id != undefined);
    let selectedExams = this.examsList.filter(exam => selectedExamIds.some(exam_id => exam_id == exam.id));
    selectedExams.forEach(exam => {
      if (exam.is_report) {
        let hasMedicalReport = this.examsListController.some(medicalReport => medicalReport.exam_id == exam.id && medicalReport.success);
        if (!hasMedicalReport) {
          this.patientExamErrors.push({name: exam.name, error: 'medicalReport'});
        }
      } else {
        let hasAttachment = this.attachmentsController.some(attachment => attachment.exam__id == exam.id);
        if (!hasAttachment) {
          this.patientExamErrors.push({name: exam.name, error: 'attachment'});
        }
      }
    });

    this.patientExamErrors.forEach(error => {
      let type = error.error == 'attachment' ? 'anexo' : 'laudo';
      let message = `Adicione um ${type} no exame ${error.name}`;
      this.toast.warning(message, 'Atenção');
    })
  }

  hasAttachmentIfMedicalReportSelected(): boolean {
    let validator: boolean = false;
    let reportIds = this.examsList.map(item => {
      if (item.is_report)
        return item.id;
      return null;
    });
    let filteredExams = this.examController.filter(exam => exam.isSelected && reportIds.includes(exam.exam_id));
    if (filteredExams.length == 0)
      return true;
    filteredExams.forEach(exam => {
      let examItem = this.examsList.find(examItem => examItem.id == exam.exam_id)
      let findAttachment = this.attachmentsController.find(controler => controler.exam__id == exam.exam_id);
      if (examItem?.is_report && findAttachment?.temporary_file_id) validator = true;
    });
    return validator;
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], 'Erro');
    } else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "patient_id", "professional_id", "height",
      "weight", "notes", "patient_exam", "start_time",
      "end_time", "attendance_type_id"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({response: errorResponse.error[name]});
    });
  }

  canDeactivate() {
    return true;
  }
}
