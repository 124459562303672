<div *ngIf="!loadingPage else loadingTemplate">
  <div class="row mb-5">
    <div class="col card">
      <div class="col px-0">
        <h3 class="mb-4">Exames Externos</h3>
      </div>

      <div class="row px-0" [formGroup]="formGroup">
        <div class="col-9">
          <app-basic-input id="description" label="Descrição" controlName="description" [formGroup]="formGroup"></app-basic-input>
        </div>

        <div class="col-3">
          <app-basic-input id="time" label="Duração (minutos)" controlName="time" [formGroup]="formGroup"></app-basic-input>
        </div>

        <div class="col-6 mt-4">
          <label class="field__title">Valor</label>
          <input class="text-left p-3" id="value" maxlength="7" formControlName="value" [formGroup]="formGroup" currencyMask [options]="{prefix: '', thousands: '.', decimal: ','}">
        </div>

        <div class="col-6 mt-4">
          <label class="field__title">Alíquota (%)</label>
          <input class="text-left p-3" id="aliquot" maxlength="6" formControlName="aliquot" [formGroup]="formGroup" currencyMask [options]="{prefix: '', thousands: '.', decimal: ','}">
        </div>

      </div>
    </div>
  </div>

  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-md-3 mb-3">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3" *ngIf="button">
      <button (click)="removeHandler()" [disabled]="submittingRemove || !canRemove" class="btn btn-secondary btn-lg"
        type="button">
        {{removeButton}}
        <span class="spinner-border" *ngIf="submittingRemove"></span>
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3">
      <button (click)="submitHandler()" [disabled]="submitting || !canSave" class="btn btn-primary btn-lg"
        type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>


</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
