import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ChatbotService } from 'src/app/core/services/chatbot.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { BirdidModalComponent } from 'src/app/shared/components/birdid-modal/birdid-modal.component';
import { QrcodeModalComponent } from 'src/app/shared/components/qrcode-modal/qrcode-modal.component';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './root-nav.component.html',
  styleUrls: ['./root-nav.component.scss']
})
export class RootNavigationComponent implements OnInit {

  @Input() menuList!: boolean;

  showDropdownMenu: boolean = false;
  showRootMenuSidebar: boolean = false;
  showRegistersMenuSidebar = this.menuList;
  isRegistersScreen: boolean = false;
  connected: boolean = false;
  loading: boolean = false;
  signature: boolean = false;
  linkListHeader: any;
  isClinicStaff = JSON.parse(localStorage.getItem('isClinicStaff')!);
  userAccessClinicId = JSON.parse(localStorage.getItem('userAccessClinicId')!);

  staffClinicLinkHeaders = [
    { name: "Oxímetro", routerLink: "oximeter", routerLinkActive: "active", icon: "icon-oximetro" },
    { name: "Agenda", routerLink: "schedules", routerLinkActive: "active", icon: "icon-Icon-ionic-ios-calendar" },
    { name: "Prontuário", routerLink: "charts", routerLinkActive: "active", icon: "icon-medical" },
    { name: "Exames", routerLink: "exams-request", routerLinkActive: "active", icon: "icon-asterisk" },
    { name: "Pacientes", routerLink: "patients", routerLinkActive: "active", icon: "icon-Icon-open-person" },
    { name: "Financeiro", routerLink: "financial", routerLinkActive: "active", icon: "icon-credit" },
    { name: "Leads", routerLink: "leads", routerLinkActive: "active", icon: "icon-target" },
    { name: "Cadastros", routerLink: "registers", routerLinkActive: "active", icon: "icon-Icon-material-edit" }
  ];

  clinicLinkHeaders = [
    { name: "Exames", routerLink: "exams-request", routerLinkActive: "active", icon: "icon-asterisk" },
    { name: "Pacientes", routerLink: "patients", routerLinkActive: "active", icon: "icon-Icon-open-person" },
    { name: "Cadastros", routerLink: `/dashboard/registers/clinics/edit/${this.userAccessClinicId}`, routerLinkActive: "active", icon: "icon-Icon-material-edit" }

  ]

  constructor(private router: Router, private sessionManager: SessionManagerService,
    private chatbotService: ChatbotService, config: NgbModalConfig, private modalService: NgbModal) {
    config.keyboard = false;
  }

  ngOnInit() {
    this.getHeaders();
    this.checkRegistersScreen();
    this.birdIDSignature();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.checkRegistersScreen();
        this.birdIDSignature();
      }
      else if (event instanceof NavigationEnd) {
        this.checkRegistersScreen();
      }
    });
  }

  getHeaders() {
    if (this.isClinicStaff) {
      this.linkListHeader = this.staffClinicLinkHeaders;
    } else {
      this.linkListHeader = this.clinicLinkHeaders;
    }
  }


  financialLink() {
    let url = environment.REEMBOLSO_URL;
    let encodedData = window.btoa(JSON.stringify({
      account: localStorage.getItem('account'),
      token: localStorage.getItem('token'),
    }));
    window.open(url + `?account=${encodedData}`, '_blank');
  }

  ngAfterViewInit() {
    this.chatbotConnect();
  }

  birdIDSignature() {
    let expires = sessionStorage.getItem("expires_in");
    if (expires) {
      if (moment() < moment(expires))
        this.signature = true
    }
  }

  chatbotConnect() {
    this.chatbotService.qrcodeGet().subscribe(response => {
      this.connected = response!.connected!;
      this.loading = false;
    });
  }

  @HostListener('document:click', ['$event'])
  clickListener(event: Event) {
    if ((event.target! as HTMLElement).id != "dropdownButton") {
      this.showDropdownMenu = false;
    }

    if ((event.target! as HTMLElement).id == "sidebar-background") {
      this.showRootMenuSidebar = false;
      this.showRegistersMenuSidebar = false;
    }
  }

  avatarClickHandle() {
    this.showDropdownMenu = !this.showDropdownMenu;
  }

  logoutButtonHandler() {
    this.sessionManager.clearSessionData();
    this.router.navigate(["/login"]);
  }

  rootMenuButtonHandler() {
    this.showRootMenuSidebar = !this.showRootMenuSidebar;
  }

  registersMenuButtonHandler() {
    this.showRegistersMenuSidebar = !this.showRegistersMenuSidebar;
  }

  goToUserScreen() {
    if (this.router.url.substr(0, this.router.url.lastIndexOf("\/")) != "/dashboard/registers/systemUsers/edit") {
      this.router.navigate([`dashboard/registers/systemUsers/edit/`, this.sessionManager.getSessionData().id!]);
    }
  }

  getProfileImage() {
    let profileImage = this.sessionManager.getSessionData().profile_image ?? "/assets/iconmonstr-user-5.svg";
    return profileImage;
  }

  checkRegistersScreen() {
    if (this.router.url.split("/")[2] == "registers" || this.router.url.split("/")[2] == "oximeter" || (this.router.url.split("/")[2] == "patients" && this.router.url.split("/")[3] == "edit"))
      this.isRegistersScreen = true;
    else
      this.isRegistersScreen = false;
  }

  whatsappConnect() {
    if (this.connected) {
      this.loading = true;
      this.chatbotConnect();
    }
    else {
      const modalRef = this.modalService.open(QrcodeModalComponent, { centered: true });
      modalRef.result.then((result) => {
        this.connected = result;
      });
    }
  }

  birdId() {
    if (!this.signature) {
      const modalRef = this.modalService.open(BirdidModalComponent, { centered: true });
      modalRef.componentInstance.modalItems = localStorage.getItem('account');
      modalRef.result.then((result) => {
        if (result) {
          this.signature = true;
          sessionStorage.setItem("expires_in", result.expires_in);
          sessionStorage.setItem("birdid_account", result.birdid_account);
        }
      });
    } else {
      this.signature = false;
      sessionStorage.removeItem("expires_in");
    }
  }

}
