<div class="row m-0 header">
  <div class="col-12">
    <app-header title="Exames" subTitle="{{subtitle}}"></app-header>
  </div>
</div>

<div *ngIf="!fetchingData else loadingTemplate" [formGroup]="formGroup">
  <div class="row separator">
    <div class="col-12 card">

      <div class="row px-0" [formGroup]="formGroup">
        <div class="col-6 px-2 mb-3" *ngIf="isClinicStaff">
          <app-select-input id="clinic_id" label="Clínica" [formGroup]="formGroup" [options]="clinicList"
                            [hideSelected]="true" [addTag]="false" [multiple]="false" [clearable]="false"
                            [searchable]="true"
                            (onValueChange)="setClinic($event)">
          </app-select-input>
        </div>

        <div class="col-6 px-2 mb-3" [formGroup]="formGroup">
          <app-select-input id="professional_id" label="Profissional" [formGroup]="formGroup" [options]="professionalList"
                            [hideSelected]="true" [addTag]="false" [multiple]="false" [clearable]="false"
                            [searchable]="true" [readonly]="!isClinicSet">
          </app-select-input>
        </div>
      </div>


      <div class="row px-0" [formGroup]="patientFormGroup">
        <div class="col-6 px-2">
          <div class="search">
            <app-select-input id="name" label="Paciente" [formGroup]="patientFormGroup" [options]="patientList"
                              [hideSelected]="true" [addTag]="false" [multiple]="false" [clearable]="false"
                              [searchable]="true"
                              (onValueChange)="setPatientForm($event)" [readonly]="!isClinicSet">
            </app-select-input>
          </div>
        </div>

        <div class="col-3 px-2">
          <app-basic-input id="phone_number" label="Telefone" controlName="phone_number" [formGroup]="patientFormGroup"
                           [readonly]="true">
          </app-basic-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" formArrayName="exams">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Exames</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <label class="field__title">Exame</label>
        </div>

        <div class="col-1">
          <label class="field__title">Valor</label>
        </div>

        <div class="col-6">
          <label class="field__title">Observações</label>
        </div>
      </div>

      <div class="row">
        <ng-container *ngFor="let examForm of examForms.controls; index as i">
          <div class="row col-12 p-0 mb-3" [formGroup]="getExamForm(i)" *ngIf="examForm.get('is_active')?.value">
            <div class="px-2 col-3">
              <ng-select id="clinic_exam_id" formControlName="clinic_exam_id" bindValue="id" bindLabel="description"
                         [items]="unselectedClinicExamList" [clearable]="false" (change)="treatExamsRequest(i)"
                         [ngClass]="examForm.get('exam')?.errors && examForm.get('exam')?.touched ? 'error' : 'custom'">
              </ng-select>
              <app-form-error id="exam" [formGroup]="getExamForm(i)" controlName="exam">
              </app-form-error>
            </div>

            <div class="px-2 col-1">
              <input id="value" formControlName="value" [formGroup]="getExamForm(i)"
                     currencyMask [options]="{prefix: '', thousands: '.', decimal: ','}">
              <app-form-error id="value" [formGroup]="getExamForm(i)" controlName="value"></app-form-error>
            </div>

            <div class="px-2 col-6">
              <app-basic-input [formGroup]="getExamForm(i)" controlName="additional_information"></app-basic-input>
            </div>

            <div class="px-2 col-2">
              <button (click)="removeExam(i)" [disabled]="submitting" class="remove-button">Remover</button>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="row">
          <div class="col-12">
            <button
              class="btn btn-link" [disabled]="!isPatientSet || !isClinicSet || submitting || unselectedClinicExamList.length === 0" (click)="addExam()">
              Adicionar novo
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-md-3 mb-3">
      <button (click)="cancelHandler()" [class]="getCancelClass()" type="button">
        {{cancelButton}}
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3" *ngIf="showRemoveButton">
      <button (click)="removeHandler()" [disabled]="submittingRemove" class="btn btn-secondary btn-lg" type="button">
        {{removeButton}}
        <span class="spinner-border" *ngIf="submittingRemove"></span>
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3" *ngIf="showSubmitButton">
      <button (click)="submitHandler()" [disabled]="submitting" class="btn btn-primary btn-lg" type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
