import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExamsRequestListComponent } from './exams-request-list/exams-request-list.component';
import { ExamsRequestFormComponent } from './exams-request-form/exams-request-form.component';
import {RouterModule} from "@angular/router";
import {NgSelectModule} from "@ng-select/ng-select";
import {NavigationModule} from "../navigation/navigation.module";
import {SharedModule} from "../../../shared/shared.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {ReactiveFormsModule} from "@angular/forms";
import {CurrencyMaskModule} from "ng2-currency-mask";



@NgModule({
  declarations: [
    ExamsRequestListComponent,
    ExamsRequestFormComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        NgSelectModule,
        NavigationModule,
        SharedModule,
        NgbTooltipModule,
        ReactiveFormsModule,
        CurrencyMaskModule,
    ]
})
export class ExamsRequestModule { }
