<div class="lead-modal">
  <h3>Lead</h3>

  <div class="content" [formGroup]="formGroup" *ngIf="!loading else loadingTemplate">
    <div class="row mb-3">
      <div class="col-6 pl-0">
        <basic-input [formGroup]="formGroup" id="name" label="Nome" (onValueChange)="treatLeadIfExists()"></basic-input>
      </div>

      <div class="col-6 pr-0">
        <basic-input [formGroup]="formGroup" id="whatsapp" label="WhatsApp" [maskOptions]="{mask: '(00) 00000-0000'}"
                     (onValueChange)="treatLeadIfExists()"></basic-input>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 p-0">
        <basic-input [formGroup]="formGroup" id="email" label="E-mail" (onValueChange)="treatLeadIfExists()"></basic-input>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-7 pl-0">
        <basic-input [formGroup]="formGroup" id="service_channel" label="Canal de atendimento"></basic-input>
      </div>

      <div class="col-5 pr-0">
        <div class="field">
          <label for="value" class="field__label">Valor</label>
          <input type="text" currencyMask id="value" formControlName="value">
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 p-0">
        <basic-input [formGroup]="formGroup" id="purchased_services" label="Serviços adquiridos (separado por vírgula)"></basic-input>
      </div>
    </div>

    <p class="item">
      <b>Criado em:</b>
      {{ lead?.created_at|date:'dd/MM/YYYY HH:mm:ss' }}
    </p>

    <p class="item">
      <b>Status:</b>
      {{ lead?.status }}
    </p>

    <p class="item">
      <b>Source:</b>
      {{ lead?.source }}
    </p>

    <p class="item">
      <b>Campanha:</b>
      {{ lead?.campaign }}
    </p>

    <p class="item">
      <b>Medium:</b>
      {{ lead?.medium }}
    </p>

    <p class="item">
      <b>Content:</b>
      {{ lead?.content }}
    </p>

    <div class="row">
      <div class="col-6">
        <button class="btn btn-link" (click)="cancel()">Cancelar</button>
      </div>

      <div class="col-6">
        <button class="btn btn-primary" (click)="submit()">Salvar</button>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <p>Carregando...</p>
</ng-template>
