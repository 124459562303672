<div [formGroup]="formGroup" *ngIf="loadingPage else loadingTemplate">

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 pb-3 col-12">
            <app-basic-input id="document_number" controlName="document_number" [formGroup]="formGroup" label="CPF"
              [maskOptions]="{mask: '000.000.000-00'}" (onValueChange)="searchAccount($event)"></app-basic-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Dados do usuário</h3>
        </div>

        <div class="col-12 col-lg-3">
          <app-image-input id="profile_image" [disabled]="formGroup.disabled" (onImageChange)="onImageSelect($event)"
                           [value]="existingAccountData.profile_image ?? ''">
          </app-image-input>
        </div>

        <div class="col">
          <div class="row remove-padding">
            <div class="col-12 pb-3">
              <app-basic-input id="name" controlName="name" [formGroup]="formGroup"
                               label="Nome completo"></app-basic-input>
            </div>

            <div class="col-sm-6 pb-3">
              <app-basic-input id="email" controlName="email" [formGroup]="formGroup" label="E-mail"></app-basic-input>
            </div>

            <div class="col-sm-6 pb-3">
              <app-basic-input id="birthdate" controlName="birthdate" type="date" label="Data de nascimento"
                               [formGroup]="formGroup"></app-basic-input>
            </div>

            <div class="col-6">
              <app-basic-input id="font_color" controlName="font_color" [formGroup]="formGroup" label="Cor do texto"
                               type="color"></app-basic-input>
            </div>

            <div class="col-6">
              <app-basic-input id="background_color" controlName="background_color" [formGroup]="formGroup"
                               label="Cor do fundo" type="color"></app-basic-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" formArrayName="schedule_permissions" *ngIf="isClinicStaff">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Agendas</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <label class="field__title">Agenda</label>
        </div>

        <div class="col-8">
          <label class="field__title">Tipo de atendimento</label>
        </div>
      </div>

      <div class="row">
        <ng-container *ngFor="let scheduleForm of scheduleForms.controls; index as i">
          <div class="row col-12 p-0 mb-3" [formGroup]="getFormSchedule(i)" [class]="{'d-none': !scheduleIsActive(i)}">
            <div class="px-2 col-4">
              <app-select-input [formGroup]="getFormSchedule(i)" [options]="scheduleList" id="schedule"
                                [clearable]="true" (onValueChange)="changeAttendanceType(i)"></app-select-input>
            </div>

            <div class="px-2 col-6">
              <ng-select id="attendance_types" formControlName="attendance_types" [items]="attendanceTypeList"
                         bindLabel="label" bindValue="value" [hideSelected]="true" [multiple]="true" [disabled]="true"
                         [ngClass]="scheduleForm.get('attendance_types')?.errors && scheduleForm.get('attendance_types')?.touched ? 'error' : 'custom'"
                         [clearable]="true" addTagText="Adicionar novo" notFoundText="Não encontrado">

                <ng-template ng-header-tmp>
                  <span class="btn btn-link" (click)="onSelectAll(i)">Selecionar todos</span>
                </ng-template>

                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label">{{item.label}}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="false">×</span>
                </ng-template>
              </ng-select>
              <app-form-error id="attendance_types" [formGroup]="getFormSchedule(i)" controlName="attendance_types">
              </app-form-error>
            </div>

            <div class="px-2 col-2">
              <button (click)="removeSchedule(i)" [disabled]="formGroup.disabled" class="remove-button">Remover</button>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-link" [disabled]="formGroup.disabled" (click)="addSchedule()">Adicionar
              novo
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" *ngIf="isClinicStaff">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Clínicas</h3>
        </div>
        <div class="px-2 col-12">
          <ng-select id="clinics" [items]="clinicList" bindLabel="name" bindValue="id" [hideSelected]="true"
            [multiple]="true" [clearable]="false" addTagText="Adicionar novo"
            notFoundText="Não encontrado" formControlName="clinics" [ngClass]="formGroup.get('clinics')?.errors && formGroup.get('clinics')?.touched ? 'error' : 'custom'">

            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span class="ng-value-label">{{item.name}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="false">×</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <span class="ng-value-label">{{item.name}}</span>
            </ng-template>
          </ng-select>
          <app-form-error id="clinics" [formGroup]="formGroup" controlName="clinics">
          </app-form-error>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Profissional</h3>
        </div>
        <div class="px-2 col-12">
          <app-select-input id="professional_id" [formGroup]="formGroup" [options]="professionalsList"
                            [clearable]="true"></app-select-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" *ngIf="isClinicStaff">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Permissões</h3>

          <div class="select-all" (click)="toggleAllPermissions($event)" [hidden]="!isStaffAccount">
            <label for="selectAllPermissions">
              <input type="checkbox" id="selectAllPermissions" [disabled]="!canGrantPermissions" [checked]="isSelectAll">
              Selecionar tudo
            </label>
          </div>

          <div class="select-all">
            <label for="isExternalAccount" (click)="clickedOnExternalUser($event)">
              <input formControlName="isExternalAccount" type="checkbox" id="isExternalAccount" [disabled]="!canGrantPermissions">
              Usuário externo
            </label>
          </div>
        </div>

        <ng-container *ngIf="userPermissions && isStaffAccount">
          <div class="col-6 col-md-4" *ngFor="let model of permissionModels">
            <div class="checkbox-field mt-4 mb-4">
              <label class="checkbox-field__title">{{ model }}</label>

              <div class="row" *ngFor="let permission of getPermissions(model)">
                <div class="col checkbox-option p-0">
                  <input type="checkbox" [value]="permission['id']" [id]="permission['codename']"
                         [checked]="isChecked(permission)" [disabled]="!canGrantPermissions"
                         (change)="onCheckChange($event)">
                  <label [for]="permission['codename']">{{ permission['name'] }}</label>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-lg-3 mb-3">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-12 col-lg-3 mb-3" *ngIf="passwordRecover">
      <button (click)="redefinePassword()" class="btn btn-primary btn-lg" type="button">
        Redefinir a Senha
      </button>
    </div>

    <div class="col-12 col-lg-3 mb-3" *ngIf="button">
      <button (click)="removeHandler()" [disabled]="submittingRemove || !canRemove" class="btn btn-secondary btn-lg"
              type="button">
        {{removeButton}}
        <span class="spinner-border" *ngIf="submittingRemove"></span>
      </button>
    </div>

    <div class="col-12 col-lg-3 mb-3">
      <button (click)="submitHandler()" [disabled]="submitting || !canSave" class="btn btn-primary btn-lg"
              type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
