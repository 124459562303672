<div *ngIf="!loadingPage else loadingTemplate">
  <div class="row mb-5">
    <div class="col card">
      <div class="col px-0">
        <h3 class="mb-4">Procedimentos</h3>
      </div>

      <div class="row px-0">
        <div class="col-4 px-0">
          <basic-input id="code" label="Código TUSS" [formGroup]="formGroup"></basic-input>
        </div>

        <div class="col-8 px-0 pl-4 mb-3">
          <basic-input id="name" label="Nome" [formGroup]="formGroup"></basic-input>
        </div>
        
        <div class="col-4 px-0" [formGroup]="formGroup">
          <!--<basic-input id="value" label="Valor" [formGroup]="formGroup"></basic-input>-->
          <label for="value" class="field__title">Valor</label>
          <input id="value" formControlName="value"          
          type="text" class="value_input" currencyMask 
          [options]="{prefix: '', thousands: '.', decimal: ','}"
          [class.error]="hasErrors('value')">
          <app-form-error id="value" [formGroup]="formGroup" controlName="value">
          </app-form-error>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-md-3 mb-3">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3" *ngIf="button">
      <button (click)="removeHandler()" [disabled]="submittingRemove" class="btn btn-secondary btn-lg" type="button">
        {{removeButton}}
        <span class="spinner-border" *ngIf="submittingRemove"></span>
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3">
      <button (click)="submitHandler()" [disabled]="submitting" class="btn btn-primary btn-lg" type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>

</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
