import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadsListComponent } from './leads-list/leads-list.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { LeadsFormComponent } from './leads-form/leads-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {DragDropModule} from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [
    LeadsListComponent,
    LeadsFormComponent
  ],
    imports: [
        CommonModule,
        RenderersModule,
        SharedModule,
        NgSelectModule,
        ReactiveFormsModule,
        DragDropModule
    ]
})
export class LeadsModule { }
