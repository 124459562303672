import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MenuItem } from '../models/menu-item';

@Component({
  selector: 'list-nav',
  templateUrl: './list-nav.component.html',
  styleUrls: ['./list-nav.component.scss']
})
export class ListNavigationComponent implements OnInit {
  isClinicStaff = JSON.parse(localStorage.getItem('isClinicStaff')!);
  userAccessClinicId = JSON.parse(localStorage.getItem('userAccessClinicId')!);

  menuRegistersByStaffClinic = [
    { label: "Clínicas", routerLink: "clinics" },
    { label: "Profissionais", routerLink: "professionals" },
    { label: "Usuários do Sistema", routerLink: "systemUsers" },
    {
      label: "Agenda", collapsed: true, subItems: [
        { label: "Agendas", routerLink: "schedules" },
        { label: "Status do agendamento", routerLink: "schedulingStatus" },
        { label: "Tipo de atendimento", routerLink: "attendanceType" },
        { label: "Status do lembrete", routerLink: "statusReminder" }
      ]
    },
    { label: "Convênios", routerLink: "healthInsurances" },
    {
      label: "Tags", collapsed: true, subItems: [
        { label: "Tags de Pacientes", routerLink: "patientTags" },
        { label: "Tags de Clínicas", routerLink: "clinicTags" },
        { label: "Tags de Profissionais", routerLink: "professionalTags" },
      ]
    },
    {
      label: "Auxiliares", collapsed: true, subItems: [
        { label: "Profissões", routerLink: "professions" },
        { label: "Áreas de Atuação", routerLink: "specialties" },
      ]
    },
    { label: "Procedimentos", routerLink: "procedures" },
    { label: "Exames Externos", routerLink: "exams" },
    {
      label: "Financeiro", collapsed: true, subItems: [
        { label: "Status do financeiro", routerLink: "financialStatus" },
        { label: "Estágio do status", routerLink: "stageStatus" },
        { label: "Tarefas", routerLink: "tasks" },
      ]
    },
    {
      label: "Documentos", collapsed: true, subItems: [
        { label: "Documentos", routerLink: "documents" },
        { label: "Exames Internos", routerLink: "examsTemplate" },
      ]
    },
    {
      label: "Chatbot", collapsed: true, subItems: [
        { label: "Gatilhos", routerLink: "triggers" },
        { label: "Configurações", routerLink: "welcomeMessage" },
        { label: "Disparo manual", routerLink: "manualTrigger" },
        { label: "Encerrar conversa", routerLink: "endConversation" },
      ]
    },
  ]

  menuRegisters = [
    { label: "Clínica", routerLink: `clinics/edit/${this.userAccessClinicId}` },
    { label: "Profissionais", routerLink: "professionals" },
    { label: "Usuários do Sistema", routerLink: "systemUsers" }
  ]

  menuPatientByStaffClinic = [
    { label: "Dados pessoais", routerLink: "personal" },
    { label: "Anexos", routerLink: "attachments" },
    { label: "Pedidos médicos", routerLink: "patientDocuments" },
    { label: "Exames", routerLink: "patientExams" },
    { label: "Polissonografia", routerLink: "patientPolysomnography" },
    { label: "Agendamentos", routerLink: "patientSchedulings" }
  ]

  menuPatient = [
    { label: "Dados pessoais", routerLink: "personal" },
    { label: "Exames", routerLink: "patientExams" },
  ]

  menuOximeter = [
    { label: "Exames", routerLink: "exams" },
    { label: "Oxímetro", routerLink: "oximeters" },
  ]

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.router.url.split("/")[2] == "registers" && this.isClinicStaff) {
      if (this.router.url.split("/")[3] != "clinicTags" && this.router.url.split("/")[3] != "patientTags" && this.router.url.split("/")[3] != "professionalTags") {
        this.menuItems[6].collapsed = true;
      }
      else {
        this.menuItems[6].collapsed = false;
      }

      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          if (event.url.split("/")[3] != "clinicTags" && event.url.split("/")[3] != "patientTags" && event.url.split("/")[3] != "professionalTags") {
            this.menuItems[6].collapsed = true;
          }
          else {
            this.menuItems[6].collapsed = false;
          }
        }
      })
    }
  }

  get menuItems(): MenuItem[] {
    let url = this.router.url.split("/")[2]
    if (url == 'registers') {
      if (this.isClinicStaff) {
        return this.menuRegistersByStaffClinic
      } else {
        return this.menuRegisters
      }
    }
    if (url == 'oximeter') {
      return this.menuOximeter;
    }
    if (!this.isClinicStaff) {
      return this.menuPatient
    }
    return this.menuPatientByStaffClinic
  }

  subItemsClickHandle(index: number) {
    this.menuItems[index].collapsed = !this.menuItems[index].collapsed
  }
}
