import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocumentModel } from '../models/document.model';
import { FileModel } from '../models/file.model';
import { HealthInsurancePlanModel } from '../models/health-insurance-plan.model';
import { HealthInsuranceModel } from '../models/health-insurance.model';
import { ListModel } from '../models/list.model';
import { PatientModel } from '../models/patient.model';
import { ProcedureModel } from '../models/procedure.model';
import { ProfessionModel } from '../models/profession.model';
import { SpecialtyModel } from '../models/specialty.model';
import { TagModel } from '../models/tag.model';
import { SchedulingStatusModel } from '../models/scheduling-status.model';
import { AttendanceTypeModel } from '../models/attendance-type.model';
import { LeadsModel } from '../models/leads.model';
import { TriggerModel } from '../models/triggers.model';
import { PatientDocument } from '../models/patientDocument.model';
import { GeneratedDocumentsModel } from '../models/generatedDocuments.model';
import { MedicalOrderModel } from '../models/medicalOrder.model';
import { WelcomeMessageModel } from '../models/welcome-message.model';
import { ExamModel } from '../models/examModel.model';
import { MedicalRecordModel } from '../models/medicalRecord.model';
import { AttachmentTypeModel } from "../models/attachmentType.model";
import { ReminderStatusModel } from '../models/reminder-status.model';
import { PostedReport } from '../models/posted-report.model';
import { DocumentGeneration } from "../models/document-generation";
import { FinancialStatusModel } from '../models/financial-status.model';
import { FinancialSettingsModel } from '../models/financial-settings.model';
import { TicketModel } from '../models/ticket.model';
import { TicketPostModel } from '../models/ticket-post.model';
import { StatusStageModel } from '../models/status-stage.model';
import {OriginModel} from "../models/origin.model";
import {ExternalExamModel} from "../models/external-exam.model";
import {ClinicExamsRequestModel} from "../models/clinic-exams-request.model";
import {ClinicExamModel} from "../models/clinic-exam.model";

@Injectable({
  providedIn: 'root'
})
export class RegistersService {

  constructor(private httpClient: HttpClient) { }

  listAllHealthInsurancePlans(): Observable<HealthInsurancePlanModel[]> {
    return this.httpClient.get<HealthInsurancePlanModel[]>(`${environment.API_URL}/health_insurances_plans/all/`);
  }

  healthInsuranceRegister(healthInsuranceData: HealthInsuranceModel): Observable<HealthInsuranceModel> {
    return this.httpClient.post<HealthInsuranceModel>(`${environment.API_URL}/health_insurances/`, JSON.stringify(healthInsuranceData));
  }

  healthInsuranceEdit(healthInsuranceData: HealthInsuranceModel): Observable<HealthInsuranceModel> {
    return this.httpClient.patch<HealthInsuranceModel>(`${environment.API_URL}/health_insurances/${healthInsuranceData.id}/`, JSON.stringify(healthInsuranceData));
  }

  healthInsuranceGet(planId: number): Observable<HealthInsuranceModel> {
    return this.httpClient.get<HealthInsuranceModel>(`${environment.API_URL}/health_insurances/${planId}/`);
  }

  healthInsuranceGetAll(): Observable<HealthInsuranceModel[]> {
    return this.httpClient.get<HealthInsuranceModel[]>(`${environment.API_URL}/health_insurances/all/`);
  }

  healthInsuranceRemove(planId: number) {
    return this.httpClient.delete(`${environment.API_URL}/health_insurances/${planId}/`);
  }

  healthInsurancesList(searchString: string = "", orderingParam: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/health_insurances/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  listAllHealthInsurances(): Observable<HealthInsuranceModel[]> {
    return this.httpClient.get<HealthInsuranceModel[]>(`${environment.API_URL}/health_insurances/all/?ordering=name&is_active=true`);
  }

  healthInsurancePlanRegister(healthInsuranceId: number, planData: HealthInsurancePlanModel): Observable<HealthInsurancePlanModel> {
    return this.httpClient.post<HealthInsurancePlanModel>(`${environment.API_URL}/health_insurances/${healthInsuranceId}/plans/`, JSON.stringify(planData));
  }

  healthInsurancePlanRemove(healthInsuranceId: number) {
    return this.httpClient.delete(`${environment.API_URL}/health_insurances_plans/${healthInsuranceId}/`);
  }

  healthInsurancePlanEdit(planData: HealthInsurancePlanModel): Observable<HealthInsurancePlanModel> {
    return this.httpClient.patch<HealthInsurancePlanModel>(`${environment.API_URL}/health_insurances_plans/${planData}/`, JSON.stringify(planData));
  }

  uploadImageHealthInsurance(imageFile: File | undefined, healthInsuranceId: number): Observable<object> {
    const formData = new FormData();
    formData.append("image", imageFile ? imageFile : '');

    return this.httpClient.patch<object>(`${environment.API_URL}/health_insurances/${healthInsuranceId}/image/`, formData);
  }

  examsTemplateGetAll(): Observable<ExamModel[]> {
    return this.httpClient.get<ExamModel[]>(`${environment.API_URL}/exam_templates/all/?ordering=name&is_active=true`)
  }

  examsTemplateList(searchString: string = "", orderingItem: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/exam_templates/?search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  examTemplateGet(examId: number): Observable<ExamModel> {
    return this.httpClient.get<ExamModel>(`${environment.API_URL}/exam_templates/${examId}/`);
  }

  examsTemplateRegister(examData: ExamModel): Observable<ExamModel> {
    return this.httpClient.post<ExamModel>(`${environment.API_URL}/exam_templates/`, JSON.stringify(examData));
  }

  examsTemplateEdit(examData: ExamModel): Observable<ExamModel> {
    return this.httpClient.patch<ExamModel>(`${environment.API_URL}/exam_templates/${examData.id}/`, JSON.stringify(examData));
  }

  examsTemplateRemove(examsId: number) {
    return this.httpClient.delete(`${environment.API_URL}/exam_templates/${examsId}/`);
  }

  externalExamsList(searchString: string = "", orderingItem: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/exams/?search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  externalExamsAll(): Observable<ExternalExamModel[]> {
    return this.httpClient.get<ExternalExamModel[]>(`${environment.API_URL}/exams/all/?&is_active=true&ordening=name`);
  }

  externalExamGet(examId: number): Observable<ExternalExamModel> {
    return this.httpClient.get<ExternalExamModel>(`${environment.API_URL}/exams/${examId}/`);
  }

  externalExamRegister(examData: ExternalExamModel): Observable<ExternalExamModel> {
    return this.httpClient.post<ExternalExamModel>(`${environment.API_URL}/exams/`, JSON.stringify(examData));
  }

  externalExamEdit(examData: ExternalExamModel): Observable<ExternalExamModel> {
    return this.httpClient.patch<ExternalExamModel>(`${environment.API_URL}/exams/${examData.id}/`, JSON.stringify(examData));
  }

  externalExamRemove(examsId: number) {
    return this.httpClient.delete(`${environment.API_URL}/exams/${examsId}/`);
  }

  clinicExams(clinicId: number): Observable<ClinicExamModel[]> {
    return this.httpClient.get<ClinicExamModel[]>(`${environment.API_URL}/clinic_exams/?clinic_id=${clinicId}&is_active=true&ordening=name`);
  }

  signExam(patientId: number, signData: any): Observable<DocumentGeneration[]> {
    return this.httpClient.post<DocumentGeneration[]>(`${environment.API_URL}/patients/${patientId}/patient_exams/sign/`, JSON.stringify(signData))
  }

  medicalReportPatientList(patientId: number, searchString: string = "", orderingItem: string = "name", page = 1, clinicId: number | string = ""): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/patients/${patientId}/patient_exams/?clinic_id=${clinicId}&search=${searchString}&ordering=${orderingItem}&page=${page}`);
  }

  allExamsFromPatient(patientId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.API_URL}/patients/${patientId}/patient_exams/all/`);
  }

  medicalReportGet(medicalReportsId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.API_URL}/medical_reports/${medicalReportsId}/`);
  }

  getExternalExamsByExamRequest(id: number): Observable<{external_exam_id: number, external_exam_description: string, additional_information: string}[]> {
    return this.httpClient.get<{external_exam_id: number, external_exam_description: string, additional_information: string}[]>(`${environment.API_URL}/clinic_exams_request/${id}/external_exams`);
  }

  getMedicalReportText(body: any): Observable<any> {
    return this.httpClient.post(`${environment.API_URL}/medical_records/report_text/`, body);
  }

  reemitMedicalReport(exam_id: number): Observable<any> {
    return this.httpClient.post(`${environment.API_URL}/medical_records/generate/`, { exam_id });
  }

  medicalRecordPost(medicalRecord: {}): Observable<DocumentGeneration[]> {
    return this.httpClient.post<DocumentGeneration[]>(`${environment.API_URL}/medical_records/`, JSON.stringify(medicalRecord));
  }

  medicalRecordsByPatientGet(patientId: number, page: number = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/medical_records/?patient=${patientId}&page=${page}`);
  }

  financialStatusList(searchString: string = "", orderingItem: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/financial/statuses/?search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  financialStatusGetAll(is_active: boolean = true): Observable<FinancialStatusModel[]> {
    return this.httpClient.get<FinancialStatusModel[]>(`${environment.API_URL}/financial/statuses/all/?is_active=${is_active}/`);
  }

  financialStatusGet(id: number): Observable<FinancialStatusModel> {
    return this.httpClient.get<FinancialStatusModel>(`${environment.API_URL}/financial/statuses/${id}/`);
  }

  financialStatusRegister(status: FinancialStatusModel): Observable<FinancialStatusModel> {
    return this.httpClient.post<FinancialStatusModel>(`${environment.API_URL}/financial/statuses/`, JSON.stringify(status));
  }

  financialStatusEdit(status: FinancialStatusModel): Observable<FinancialStatusModel> {
    return this.httpClient.patch<FinancialStatusModel>(`${environment.API_URL}/financial/statuses/${status.id}/`, JSON.stringify(status));
  }

  financialStatusRemove(statusId: number): Observable<any> {
    return this.httpClient.delete(`${environment.API_URL}/financial/statuses/${statusId}/`);
  }

  listAllFinancialTickets(active = true): Observable<TicketModel[]> {
    if (active) {
      return this.httpClient.get<TicketModel[]>(`${environment.API_URL}/financial/tickets/`);
    } else {
      return this.httpClient.get<TicketModel[]>(`${environment.API_URL}/financial/tickets/done/`);
    }
  }

  financialTicketGet(search = '', status = '', user = '', dateSince = '', dateUntil = '', page = 1, filters: {[key: string]: string} = {}, ordering = '-date_time', active = true): Observable<TicketModel[]> {
    if (active) {
      let queryParams = [];
      for (let key in filters) {
        if (filters[key]) {
          let queryParam = `${key}=${filters[key]}`;
          queryParams.push(queryParam);
        }
      }
      let queryParamsString = queryParams.join('&');
      return this.httpClient.get<TicketModel[]>(`${environment.API_URL}/financial/tickets/?${queryParamsString}&status=${status}&user=${user}&since=${dateSince}&until=${dateUntil}&page=${page}&ordering=${ordering}`);
    } else {
      return this.httpClient.get<TicketModel[]>(`${environment.API_URL}/financial/tickets/done/`);
    }
  }

  exportFinancialToCSV(ticketIds: string) {
    return this.httpClient.get(`${environment.API_URL}/financial/tickets/export/?ids=${ticketIds}`, { responseType: 'text' });
  }

  getAllPatientOrigins(): Observable<OriginModel[]> {
    return this.httpClient.get<OriginModel[]>(`${environment.API_URL}/financial/tickets/origins/`);
  }

  financialTicketGetById(id: number): Observable<TicketModel> {
    return this.httpClient.get<TicketModel>(`${environment.API_URL}/financial/tickets/${id}/`);
  }

  financialTicketRegister(ticketData: TicketPostModel): Observable<TicketPostModel>{
    return this.httpClient.post<TicketPostModel>(`${environment.API_URL}/financial/tickets/`, JSON.stringify(ticketData));
  }

  financialTicketEdit(ticketData: TicketPostModel): Observable<TicketPostModel>{
    return this.httpClient.patch<TicketPostModel>(`${environment.API_URL}/financial/tickets/${ticketData.id}/`, JSON.stringify(ticketData));
  }

  financialSettingsGet(id = 2): Observable<FinancialSettingsModel>{
    return this.httpClient.get<FinancialSettingsModel>(`${environment.API_URL}/financial/settings/${id}/`);
  }

  financialSettingsPath(settings: FinancialSettingsModel, id = 2): Observable<FinancialSettingsModel>{
    return this.httpClient.patch<FinancialSettingsModel>(`${environment.API_URL}/financial/settings/${id}/`, JSON.stringify(settings));
  }

  getMedicalRecordDraftByPatientAndStartTime(patientId: number, startTime: any): Observable<MedicalRecordModel> {
    return this.httpClient.get<MedicalRecordModel>(`${environment.API_URL}/medical_record_drafts/by_patient_and_start_time?patient_id=${patientId}&start_time=${startTime}`);
  }

  saveMedicalRecordDraft(medicalRecord: {}): Observable<MedicalRecordModel> {
    return this.httpClient.post<MedicalRecordModel>(`${environment.API_URL}/medical_record_drafts/`, medicalRecord);
  }

  createMedicalRecordDraft(medicalRecord: {}): Observable<MedicalRecordModel> {
    return this.httpClient.post<MedicalRecordModel>(`${environment.API_URL}/medical_record_drafts/`, medicalRecord);
  }

  updateMedicalRecordDraft(medicalRecord: {id: number}): Observable<MedicalRecordModel> {
    let id = medicalRecord.id;
    return this.httpClient.patch<MedicalRecordModel>(`${environment.API_URL}/medical_record_drafts/${id}/`, medicalRecord);
  }

  celeryGet(uuid: string): Observable<PostedReport[]> {
    return this.httpClient.get<PostedReport[]>(`${environment.API_URL}/document_generation/?uuid=${uuid}`);
  }

  attachmentTypesList(): Observable<AttachmentTypeModel[]> {
    return this.httpClient.get<AttachmentTypeModel[]>(`${environment.API_URL}/exam_templates/attachment_types/`);
  }

  professionGet(professionId: number): Observable<TagModel> {
    return this.httpClient.get<TagModel>(`${environment.API_URL}/professions/${professionId}/`);
  }

  professionRegister(professionData: ProfessionModel): Observable<ProfessionModel> {
    return this.httpClient.post<ProfessionModel>(`${environment.API_URL}/professions/`, JSON.stringify(professionData));
  }

  professionEdit(professionData: ProfessionModel): Observable<ProfessionModel> {
    return this.httpClient.patch<ProfessionModel>(`${environment.API_URL}/professions/${professionData.id}/`, JSON.stringify(professionData));
  }

  professionRemove(professionId: number) {
    return this.httpClient.delete(`${environment.API_URL}/professions/${professionId}/`);
  }

  professionsList(searchString: string = "", orderingItem: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/professions/?search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  tagGet(tagId: number, tagType: string): Observable<TagModel> {
    return this.httpClient.get<TagModel>(`${environment.API_URL}/tags/${tagType}/${tagId}/`);
  }

  tagGetAll(tagType: string): Observable<TagModel[]> {
    return this.httpClient.get<TagModel[]>(`${environment.API_URL}/tags/${tagType}/all/?ordering=name&is_active=true`);
  }

  tagRegister(tagData: TagModel): Observable<TagModel> {
    return this.httpClient.post<TagModel>(`${environment.API_URL}/tags/${tagData.type}/`, JSON.stringify(tagData));
  }

  tagEdit(tagData: TagModel): Observable<TagModel> {
    return this.httpClient.patch<TagModel>(`${environment.API_URL}/tags/${tagData.type}/${tagData.id}/`, JSON.stringify(tagData));
  }

  tagRemove(tagId: number, tagType: string) {
    return this.httpClient.delete(`${environment.API_URL}/tags/${tagType}/${tagId}/`);
  }

  tagsList(tagType: string, searchString: string = "", orderingItem: string = "", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/tags/${tagType}/?search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  listAllPatientTags(): Observable<TagModel[]> {
    return this.httpClient.get<TagModel[]>(`${environment.API_URL}/tags/patient/all/?ordering=name&is_active=true`);
  }

  specialtyGet(specialtyId: number): Observable<SpecialtyModel> {
    return this.httpClient.get<SpecialtyModel>(`${environment.API_URL}/specialties/${specialtyId}/`);
  }

  specialtyRegister(specialtyData: SpecialtyModel): Observable<SpecialtyModel> {
    return this.httpClient.post<SpecialtyModel>(`${environment.API_URL}/specialties/`, JSON.stringify(specialtyData));
  }

  specialtyEdit(specialtyData: SpecialtyModel): Observable<SpecialtyModel> {
    return this.httpClient.patch<SpecialtyModel>(`${environment.API_URL}/specialties/${specialtyData.id}/`, JSON.stringify(specialtyData));
  }

  specialtyRemove(specialtyId: number) {
    return this.httpClient.delete(`${environment.API_URL}/specialties/${specialtyId}/`);
  }

  specialtiesList(searchString: string = "", orderingParam: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/specialties/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  listAllSpecialties(): Observable<SpecialtyModel[]> {
    return this.httpClient.get<SpecialtyModel[]>(`${environment.API_URL}/specialties/all/?&ordering=name&is_active=true`);
  }

  patientRegister(patientData: PatientModel): Observable<PatientModel> {
    return this.httpClient.post<PatientModel>(`${environment.API_URL}/patients/`, JSON.stringify(patientData));
  }

  patientAttachmentRegister(file: File, name: string, patientId: number): Observable<FileModel> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("label", name);
    formData.append("is_active", "True");

    return this.httpClient.post<FileModel>(`${environment.API_URL}/patients/${patientId}/attachments/`, formData);
  }

  patientAttachmentRemove(patientId: number, attachmentId: number) {
    return this.httpClient.delete(`${environment.API_URL}/patients/${patientId}/attachments/${attachmentId}/`);
  }

  patientAttachmentsList(patientId: string): Observable<object[]> {
    return this.httpClient.get<object[]>(`${environment.API_URL}/patients/${patientId}/`);
  }

  patientEdit(patientData: PatientModel): Observable<PatientModel> {
    return this.httpClient.patch<PatientModel>(`${environment.API_URL}/patients/${patientData.id}/`, JSON.stringify(patientData));
  }

  patientDocuments(patientId: number, search: string = '', ordering: string = '-created_at', page: number = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/patients/${patientId}/documents/?page=${page}&search=${search}&ordering=${ordering}`);
  }

  allDocumentsFromPatient(patientId: number): Observable<any> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/patients/${patientId}/documents/all/`);
  }

  patientDocumentRemove(patientId: number, patientDocumentId: number) {
    return this.httpClient.delete(`${environment.API_URL}/patients/${patientId}/documents/${patientDocumentId}/`)
  }

  patientRemove(patientId: number) {
    return this.httpClient.delete(`${environment.API_URL}/patients/${patientId}/`);
  }

  patientsList(searchString: string = "", orderingParam: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/patients/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  patientsListByClinic(clinicId: number, searchString: string = "", orderingParam: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/patients/?clinic_id=${clinicId}&search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  listAllPatients() {
    return this.httpClient.get<PatientModel[]>(`${environment.API_URL}/patients/list_all/`);
  }

  patientGet(patientId: number): Observable<PatientModel> {
    return this.httpClient.get<PatientModel>(`${environment.API_URL}/patients/${patientId}/`);
  }

  patientGetAll(): Observable<PatientModel[]> {
    return this.httpClient.get<PatientModel[]>(`${environment.API_URL}/patients/all/?is_active=true`);
  }

  listAllClinicPatients(clinicId: number): Observable<PatientModel[]> {
    return this.httpClient.get<PatientModel[]>(`${environment.API_URL}/patients/all/?clinic_id=${clinicId}&is_active=true`);
  }

  searchPatient(document_number: string): Observable<PatientModel> {
    return this.httpClient.get<PatientModel>(`${environment.API_URL}/patients/search/?document_number=${document_number}`);
  }

  connectPatient(patient: PatientModel, data: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.API_URL}/patients/${patient.id}/connect/`, data)
  }

  revokePatient(patient: PatientModel, data: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.API_URL}/patients/${patient.id}/revoke/`, data)
  }

  uploadPatientImage(imageFile: File | undefined, patientId: number): Observable<object> {
    const formData = new FormData();
    formData.append("profile_image", imageFile ? imageFile : '');

    return this.httpClient.patch<object>(`${environment.API_URL}/patients/${patientId}/profile_image/`, formData);
  }

  patientGetSchedulings(patientId: number, searchString: string = "", orderingParam: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/patients/${patientId}/schedulings/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  procedureGet(procedureId: number): Observable<ProcedureModel> {
    return this.httpClient.get<ProcedureModel>(`${environment.API_URL}/procedures/${procedureId}/`);
  }

  procedureGetAll(): Observable<ProcedureModel[]> {
    return this.httpClient.get<ProcedureModel[]>(`${environment.API_URL}/procedures/all/`);
  }

  procedureList(searchString: string = "", orderingParam: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/procedures/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  procedureRegister(procedureData: ProcedureModel): Observable<ProcedureModel> {
    return this.httpClient.post<ProcedureModel>(`${environment.API_URL}/procedures/`, JSON.stringify(procedureData));
  }

  procedureEdit(procedureData: ProcedureModel): Observable<ProcedureModel> {
    return this.httpClient.patch<ProcedureModel>(`${environment.API_URL}/procedures/${procedureData.id}/`, JSON.stringify(procedureData));
  }

  procedureRemove(procedureId: number) {
    return this.httpClient.delete(`${environment.API_URL}/procedures/${procedureId}/`);
  }

  documentsByProfessionalGet(professionalId: number): Observable<DocumentModel[]> {
    return this.httpClient.get<DocumentModel[]>(`${environment.API_URL}/document_templates/all/?professional=${professionalId}&type=MEDICAL`)
  }

  documentGet(documentId: number): Observable<DocumentModel> {
    return this.httpClient.get<DocumentModel>(`${environment.API_URL}/document_templates/${documentId}/`);
  }

  documentsList(searchString: string = "", orderingParam: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/document_templates/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  documentRegister(documentData: DocumentModel): Observable<DocumentModel> {
    return this.httpClient.post<DocumentModel>(`${environment.API_URL}/document_templates/`, JSON.stringify(documentData));
  }

  documentEdit(documentData: DocumentModel): Observable<DocumentModel> {
    return this.httpClient.patch<DocumentModel>(`${environment.API_URL}/document_templates/${documentData.id}/`, JSON.stringify(documentData));
  }

  documentRemove(documentId: number) {
    return this.httpClient.delete(`${environment.API_URL}/document_templates/${documentId}/`);
  }

  documentEmit(scheduleId: number, attendanceId: number, type: string): Observable<GeneratedDocumentsModel[]> {
    return this.httpClient.post<GeneratedDocumentsModel[]>(`${environment.API_URL}/schedules/${scheduleId}/attendances/${attendanceId}/deprecated_documents/?type=${type}`, {});
  }

  medicalOrderEmit(scheduleId: number, attendanceId: number, medicalOrders: MedicalOrderModel): Observable<PatientDocument[]> {
    return this.httpClient.post<PatientDocument[]>(`${environment.API_URL}/schedules/${scheduleId}/attendances/${attendanceId}/deprecated_medical_orders/`, JSON.stringify(medicalOrders))
  }

  schedulingStatusGet(schedulingStatusId: number): Observable<SchedulingStatusModel> {
    return this.httpClient.get<SchedulingStatusModel>(`${environment.API_URL}/scheduling_status/${schedulingStatusId}/`);
  }

  schedulingsStatusAll(): Observable<SchedulingStatusModel[]> {
    return this.httpClient.get<SchedulingStatusModel[]>(`${environment.API_URL}/scheduling_status/all/?is_active=true&ordering=id`);
  }

  schedulingsStatusList(searchString: string = "", orderingParam: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/scheduling_status/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  schedulingStatusRegister(schedulingStatusData: SchedulingStatusModel): Observable<SchedulingStatusModel> {
    return this.httpClient.post<SchedulingStatusModel>(`${environment.API_URL}/scheduling_status/`, JSON.stringify(schedulingStatusData));
  }

  schedulingStatusEdit(schedulingStatusData: SchedulingStatusModel): Observable<SchedulingStatusModel> {
    return this.httpClient.patch<SchedulingStatusModel>(`${environment.API_URL}/scheduling_status/${schedulingStatusData.id}/`, JSON.stringify(schedulingStatusData));
  }

  schedulingStatusRemove(schedulingStatusId: number) {
    return this.httpClient.delete(`${environment.API_URL}/scheduling_status/${schedulingStatusId}/`);
  }

  attendanceListGet(searchString: string, schedules: number[], orderingParam: string = "", date: string, page: number = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/attendances/?search=${searchString}&schedule=${schedules}&ordering=${orderingParam}&date=${date}&page=${page}`);
  }

  attendanceTypeGet(attendanceTypeId: number): Observable<AttendanceTypeModel> {
    return this.httpClient.get<AttendanceTypeModel>(`${environment.API_URL}/attendance_type/${attendanceTypeId}/`);
  }

  attendancesTypeAll(): Observable<AttendanceTypeModel[]> {
    return this.httpClient.get<AttendanceTypeModel[]>(`${environment.API_URL}/attendance_type/all/?is_active=true&ordering=id`);
  }

  attendancesTypeList(searchString: string = "", orderingParam: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/attendance_type/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  attendanceTypeRegister(attendanceTypeData: AttendanceTypeModel): Observable<AttendanceTypeModel> {
    return this.httpClient.post<AttendanceTypeModel>(`${environment.API_URL}/attendance_type/`, JSON.stringify(attendanceTypeData));
  }

  attendanceTypeEdit(attendanceTypeData: AttendanceTypeModel): Observable<AttendanceTypeModel> {
    return this.httpClient.patch<AttendanceTypeModel>(`${environment.API_URL}/attendance_type/${attendanceTypeData.id}/`, JSON.stringify(attendanceTypeData));
  }

  attendanceTypeRemove(attendanceTypeId: number) {
    return this.httpClient.delete(`${environment.API_URL}/attendance_type/${attendanceTypeId}/`);
  }

  schedulingStatusToggle(schedulesId: number, attendanceId: number, schedulingStatus: any): Observable<any> {
    return this.httpClient.patch<any>(`${environment.API_URL}/schedules/${schedulesId}/attendances/${attendanceId}/scheduling_status/`, JSON.stringify(schedulingStatus));
  }

  leadsGet(id: number): Observable<LeadsModel> {
    return this.httpClient.get<LeadsModel>(`${environment.API_URL}/leads/${id}/`);
  }

  leadsAll(): Observable<LeadsModel[]> {
    return this.httpClient.get<LeadsModel[]>(`${environment.API_URL}/leads/all/`);
  }

  leadsList({searchString = "", orderingParam = "name", isActive = true, page = 1, name = '', whatsapp = '', email = ''}): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/leads/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}&name=${name}&whatsapp=${whatsapp}&email=${email}`);
  }

  leadsSearchTags(searchString: string = "", phoneNumber: string = "name"): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/leads/?search=${phoneNumber}&tags=${searchString}`);
  }

  leadsRegister(leadsData: LeadsModel): Observable<LeadsModel> {
    return this.httpClient.post<LeadsModel>(`${environment.API_URL}/leads/`, JSON.stringify(leadsData));
  }

  leadsEdit(id: number, leadsData: LeadsModel): Observable<LeadsModel> {
    return this.httpClient.patch<LeadsModel>(`${environment.API_URL}/leads/${id}/`, JSON.stringify(leadsData));
  }

  leadsRemove(phoneNumber: string) {
    return this.httpClient.delete(`${environment.API_URL}/leads/${phoneNumber}/`);
  }

  leadsLabelsAll(leadsId: number, leadsData: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.API_URL}/leads/${leadsId}/labels/`, JSON.stringify(leadsData));
  }

  leadsLabelsRegister(leadsId: number, leadsData: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.API_URL}/leads/${leadsId}/labels/`, JSON.stringify(leadsData));
  }

  leadsLabelsRemove(leadsId: number, label: string) {
    return this.httpClient.delete(`${environment.API_URL}/leads/${leadsId}/label/${label}`);
  }

  leadsVariablesRegister(phoneNumber: string, variable: any): Observable<LeadsModel> {
    return this.httpClient.post<LeadsModel>(`${environment.API_URL}/leads/${phoneNumber}/variables/`, JSON.stringify(variable));
  }

  leadsAllLabel(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.API_URL}/leads/all_labels/`);
  }

  triggersGet(triggersId: number): Observable<TriggerModel> {
    return this.httpClient.get<TriggerModel>(`${environment.API_URL}/chatbot/triggers/${triggersId}/`);
  }

  triggersList(searchString: string = "", orderingParam: string = "trigger", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/chatbot/triggers/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  triggersRegister(triggersData: TriggerModel): Observable<TriggerModel> {
    return this.httpClient.post<TriggerModel>(`${environment.API_URL}/chatbot/triggers/`, JSON.stringify(triggersData));
  }

  triggersEdit(triggersData: TriggerModel): Observable<TriggerModel> {
    return this.httpClient.patch<TriggerModel>(`${environment.API_URL}/chatbot/triggers/${triggersData.id}/`, JSON.stringify(triggersData));
  }

  triggersRemove(triggersId: number) {
    return this.httpClient.delete(`${environment.API_URL}/chatbot/triggers/${triggersId}/`);
  }

  zeroTrigger(triggersId: number): Observable<TriggerModel> {
    return this.httpClient.patch<any>(`${environment.API_URL}/chatbot/triggers/${triggersId}/reset_counter/`, JSON.stringify(''));
  }

  welcomeMessageGet(): Observable<WelcomeMessageModel> {
    return this.httpClient.get<WelcomeMessageModel>(`${environment.API_URL}/chatbot/triggers/welcome_message/`);
  }

  welcomeMessageList(searchString: string = "", orderingParam: string = "trigger", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/chatbot/triggers/welcome_message/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  welcomeMessageRegister(welcomeMessageData: WelcomeMessageModel): Observable<WelcomeMessageModel> {
    return this.httpClient.post<WelcomeMessageModel>(`${environment.API_URL}/chatbot/triggers/welcome_message/`, JSON.stringify(welcomeMessageData));
  }

  welcomeMessageEdit(welcomeMessageData: WelcomeMessageModel): Observable<WelcomeMessageModel> {
    return this.httpClient.patch<WelcomeMessageModel>(`${environment.API_URL}/chatbot/triggers/welcome_message/`, JSON.stringify(welcomeMessageData));
  }

  welcomeMessageRemove(welcomeMessageId: number) {
    return this.httpClient.delete(`${environment.API_URL}/chatbot/triggers/welcome_message/${welcomeMessageId}/`);
  }

  statusReminderGet(statusId: number): Observable<ReminderStatusModel> {
    return this.httpClient.get<ReminderStatusModel>(`${environment.API_URL}/reminders_status/${statusId}/`);
  }

  statusReminderList(searchString: string = "", orderingParam: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/reminders_status/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  statusReminderAll(): Observable<ReminderStatusModel[]> {
    return this.httpClient.get<ReminderStatusModel[]>(`${environment.API_URL}/reminders_status/all/`);
  }

  statusReminderRegister(statusData: ReminderStatusModel): Observable<ReminderStatusModel> {
    return this.httpClient.post<ReminderStatusModel>(`${environment.API_URL}/reminders_status/`, JSON.stringify(statusData));
  }

  statusReminderEdit(statusData: ReminderStatusModel): Observable<ReminderStatusModel> {
    return this.httpClient.patch<ReminderStatusModel>(`${environment.API_URL}/reminders_status/${statusData.id}/`, JSON.stringify(statusData));
  }

  statusReminderRemove(statusId: number) {
    return this.httpClient.delete(`${environment.API_URL}/reminders_status/${statusId}/`);
  }

  statusReminderToggle(reminderId: number, status: any) {
    return this.httpClient.patch<ReminderStatusModel>(`${environment.API_URL}/reminders/${reminderId}/`, JSON.stringify(status));
  }

  uploadTemporaryFile(formData: FormData) {
    return this.httpClient.post(`${environment.API_URL}/temporary_files/`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  statusStageGet(stageId: number): Observable<StatusStageModel> {
    return this.httpClient.get<StatusStageModel>(`${environment.API_URL}/financial/status_stages/${stageId}/`);
  }

  statusStageList(searchString: string = "", orderingParam: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/financial/status_stages/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  statusStageAll(): Observable<StatusStageModel[]> {
    return this.httpClient.get<StatusStageModel[]>(`${environment.API_URL}/financial/status_stages/all/?is_active=true`);
  }

  statusStageRegister(stageData: StatusStageModel): Observable<StatusStageModel> {
    return this.httpClient.post<StatusStageModel>(`${environment.API_URL}/financial/status_stages/`, JSON.stringify(stageData));
  }

  statusStageEdit(stageData: StatusStageModel): Observable<StatusStageModel> {
    return this.httpClient.patch<StatusStageModel>(`${environment.API_URL}/financial/status_stages/${stageData.id}/`, JSON.stringify(stageData));
  }

  statusStageRemove(statusId: number) {
    return this.httpClient.delete(`${environment.API_URL}/financial/status_stages/${statusId}/`);
  }

  listClinicExamRequestsWithoutScheduling(patientId: number) {
    return this.httpClient.get<ClinicExamsRequestModel[]>(`${environment.API_URL}/patients/${patientId}/clinic_exams_request/without_scheduling/`);
  }

  sendExamMessageToPatient(patientId: number, patientExamId: number): Observable<null> {
    return this.httpClient.post<null>(`${environment.API_URL}/patients/${patientId}/patient_exams/${patientExamId}/send_message/`, null);
  }
}
