export enum PermissionConst {
  add_account = 1,
  change_account = 2,
  remove_account = 3,
  view_account = 4,
  add_clinic = 5,
  grant_permissions = 118,
  change_clinic = 6,
  remove_clinic = 7,
  view_clinic = 8,
  add_professional = 9,
  change_professional = 10,
  remove_professional = 11,
  view_professional = 12,
  add_healthinsurance = 13,
  change_healthinsurance = 14,
  remove_healthinsurance = 15,
  view_healthinsurance = 16,
  add_profession = 17,
  change_profession = 18,
  remove_profession = 19,
  view_profession = 20,
  add_tag = 21,
  change_tag = 22,
  remove_tag = 23,
  view_tag = 24,
  add_specialty = 25,
  change_specialty = 26,
  remove_specialty = 27,
  view_specialty = 28,
  add_patient = 29,
  change_patient = 30,
  remove_patient = 31,
  view_patient = 32,
  export_account = 33,
  export_clinic = 34,
  export_professional = 35,
  export_healthinsurance = 36,
  export_profession = 37,
  export_tag = 38,
  export_specialty = 39,
  export_patient = 40,
  add_calendar = 41,
  change_calendar = 42,
  remove_calendar = 43,
  view_calendar = 44,
  export_calendar = 45,
  add_procedure = 47,
  change_procedure = 48,
  remove_procedure = 49,
  view_procedure = 50,
  export_procedure = 51,
  add_document = 52,
  change_document = 53,
  remove_document = 54,
  view_document = 55,
  export_document = 56,
  add_scheduling = 57,
  change_scheduling = 58,
  remove_scheduling = 59,
  view_scheduling = 60,
  export_scheduling = 61,
  add_lead = 62,
  change_lead = 63,
  remove_lead = 64,
  view_lead = 65,
  export_lead = 66,
  add_chatbottrigger = 67,
  change_chatbottrigger = 68,
  remove_chatbottrigger = 69,
  view_chatbottrigger = 70,
  export_chatbottrigger = 71,
  view_chatbotmessage = 72,
  remove_chatbotmessage = 73,
  change_chatbotmessage = 74,
  export_chatbotmessage = 75,
  add_chatbotmessage = 76,
  view_financialsettings = 77,
  change_financialsettings = 78,
  add_financialsettings = 79,
  remove_financialsettings = 80,
  export_financialsettings = 81,
  view_financialstatus = 82,
  change_financialstatus = 83,
  add_financialstatus = 84,
  remove_financialstatus = 85,
  export_financialstatus = 86,
  view_ticket = 87,
  change_ticket = 88,
  add_ticket = 89,
  remove_ticket = 90,
  export_ticket = 91,
  add_procedure_to_an_existent_ticket = 92,
  view_statusstage = 93,
  change_statusstage = 94,
  add_statusstage = 95,
  remove_statusstage = 96,
  export_statusstage = 97,
  view_oximeter = 98,
  change_oximeter = 99,
  add_oximeter = 100,
  remove_oximeter = 101,
  export_oximeter = 102,
  view_polysomnography = 103,
  change_polysomnography = 104,
  add_polysomnography = 105,
  remove_polysomnography = 106,
  export_polysomnography = 107,
  view_polysomnographyexam = 108,
  change_polysomnographyexam = 109,
  add_polysomnographyexam = 110,
  remove_polysomnographyexam = 111,
  export_polysomnographyexam = 112,
  view_task = 113,
  change_task = 114,
  add_task = 115,
  remove_task = 116,
  export_task = 117,
  view_exam = 119,
  change_exam = 120,
  add_exam = 121,
  remove_exam = 122,
  export_exam = 123,
}
