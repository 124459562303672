import { Injectable } from '@angular/core';
import { AccountModel } from '../models/account.model';
import { PermissionConst } from '../models/permission-const.model';

@Injectable({
  providedIn: 'root'
})
export class SessionManagerService {
  accountData: AccountModel = {} as AccountModel;
  token: string = '';
  loggedIn: boolean = false;

  constructor() {
    this.getSessionData();
  }

  getSessionData() {
    const storedAccount = localStorage.getItem('account');

    if (storedAccount != undefined) {
      this.loggedIn = true;
      this.accountData = JSON.parse(storedAccount) as AccountModel;
      this.token = localStorage.getItem('token') as string;
    }
    else {
      this.loggedIn = false;
      this.accountData = {} as AccountModel;
    }

    return JSON.parse(storedAccount!) as AccountModel;
  }

  setSessionData(data: AccountModel, userAccessClinicId?: number, isClinicStaff?: boolean): void {
    this.loggedIn = true;
    this.accountData = data;
    localStorage.setItem('account', JSON.stringify(data));
    if (this.accountData.token) {
      localStorage.setItem('token', this.accountData.token);
    }
    if (userAccessClinicId) {
      localStorage.setItem('userAccessClinicId', userAccessClinicId.toString());
    }
    if (isClinicStaff != undefined){
      localStorage.setItem('isClinicStaff', isClinicStaff.toString());
    }
  }

  clearSessionData(): void {
    this.loggedIn = false;
    this.accountData = {} as AccountModel;
    localStorage.removeItem('account');
    localStorage.removeItem('token');
  }

  getUserPermission(id: PermissionConst) {
      let hasPermission = false;
      let list = this.getSessionData().user_permissions;
      if(list) {
          hasPermission = list.some(permissionId => permissionId == id);
      }
      return hasPermission;
  }

  isExternalClinicUser(): boolean {
    return JSON.parse(localStorage.getItem('isClinicStaff') ?? "false");
  }

  getClinicId(): number {
    return JSON.parse(localStorage.getItem('userAccessClinicId') ?? "null");
  }
}
