import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FinancialTask} from "../models/financial-task.model";
import {ListModel} from "../models/list.model";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {search} from "ngx-bootstrap-icons";

@Injectable({
  providedIn: 'root'
})
export class FinancialService {
  constructor(private httpClient: HttpClient) { }

  listTasks(searchString: string = "", orderingParam: string = "", status: string = '', isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/financial/tasks/?search=${searchString}&ordering=${orderingParam}&status=${status}&is_active=${isActive}&page=${page}`);
  }

  retrieveTask(id: number): Observable<FinancialTask> {
    return this.httpClient.get<FinancialTask>(`${environment.API_URL}/financial/tasks/${id}/`);
  }

  createTask(task: FinancialTask): Observable<FinancialTask> {
    return this.httpClient.post<FinancialTask>(`${environment.API_URL}/financial/tasks/`, task);
  }

  editTask(task: FinancialTask): Observable<FinancialTask> {
    return this.httpClient.patch<FinancialTask>(`${environment.API_URL}/financial/tasks/${task.id}/`, task);
  }
}
