import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExamsRegisterListComponent } from './exams-register-list/exams-register-list.component';
import { ExamsRegisterFormComponent } from './exams-register-form/exams-register-form.component';
import {RenderersModule} from "../../../../shared/renderers/renderers.module";
import {SharedModule} from "../../../../shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {CurrencyMaskModule} from "ng2-currency-mask";



@NgModule({
  declarations: [
    ExamsRegisterListComponent,
    ExamsRegisterFormComponent
  ],
    imports: [
        CommonModule,
        RenderersModule,
        SharedModule,
        ReactiveFormsModule,
        CurrencyMaskModule
    ]
})
export class ExamsRegisterModule { }
