import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClinicsListComponent } from './clinics-list/clinics-list.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { ClinicFormComponent } from './clinic-form/clinic-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';
import {CurrencyMaskModule} from "ng2-currency-mask";



@NgModule({
  declarations: [
    ClinicsListComponent,
    ClinicFormComponent
  ],
    imports: [
        CommonModule,
        RenderersModule,
        SharedModule,
        NgSelectModule,
        NgxMaskModule,
        ReactiveFormsModule,
        CurrencyMaskModule
    ]
})
export class ClinicsModule { }
