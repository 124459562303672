<div *ngIf="!loadingPage else loadingTemplate" [formGroup]="formGroup">

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 pb-3 col-12">
            <app-basic-input id="document_number" controlName="document_number" [formGroup]="formGroup" label="CPF"
              [maskOptions]="{mask: '000.000.000-00'}" (onValueChange)="searchProfessional($event)"></app-basic-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Dados pessoais</h3>
        </div>

        <div class="col-12 col-lg-3">
          <image-input id="imageInput" [value]="existingProfessionalData?.profile_image!" [disabled]="formGroup.disabled" (onImageChange)="onImageSelect($event)"></image-input>
        </div>

        <div class="col p-0">
          <div class="row p-0 m-0 px-2">
            <div class="col-12 p-0 m-0 mb-3">
              <app-basic-input id="name" [formGroup]="formGroup" controlName="name" label="Nome Completo">
              </app-basic-input>
            </div>

            <div class="col-sm-6 p-0 m-0 mb-3 pr-sm-3">
              <app-basic-input id="display_name" [formGroup]="formGroup" controlName="display_name"
                label="Nome de tratamento"></app-basic-input>
            </div>

            <div class="col-sm-3 p-0 m-0 mb-3 px-sm-2">
              <label class="field__title">Gênero</label>

              <div class="radio-option">
                <label for="gender_male"><input type="radio" name="gender" formControlName="gender" value="MALE"
                    id="gender_male" /> Masculino</label>
              </div>

              <div class="radio-option">
                <label for="gender_female"><input type="radio" name="gender" formControlName="gender" value="FEMALE"
                    id="gender_female" /> Feminino</label>
              </div>
            </div>

            <div class="col-sm-3 p-0 m-0 mb-3">
              <date-input id="birthdate" [formGroup]="formGroup" controlName="birthdate" label="Data de nascimento">
              </date-input>
            </div>

            <div class="col-sm-9 p-0 m-0 pl-1 mb-3 pr-sm-2">
              <app-basic-input id="email" [formGroup]="formGroup" controlName="email" label="E-mail"></app-basic-input>
            </div>

            <div class="col-sm-3 p-0 m-0 pl-1 mb-3">
              <app-basic-input id="cro" [formGroup]="formGroup" controlName="cro" label="CRO"></app-basic-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" *ngIf="isClinicStaff">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Clínicas</h3>
        </div>
        <div class="px-2 col-12">
          <ng-select id="clinics" [items]="clinicsList" bindLabel="name" bindValue="id" [hideSelected]="true"
            [multiple]="true" [clearable]="false" addTagText="Adicionar novo"
            notFoundText="Não encontrado" formControlName="clinics" [ngClass]="formGroup.get('clinics')?.errors && formGroup.get('clinics')?.touched ? 'error' : 'custom'">

            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span class="ng-value-label">{{item.name}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="false">×</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <span class="ng-value-label">{{item.name}}</span>
            </ng-template>
          </ng-select>
          <app-form-error id="clinics" [formGroup]="formGroup" controlName="clinics">
          </app-form-error>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" *ngIf="isClinicStaff">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Tags</h3>
        </div>
        <div class="px-2 col-12">
          <ng-select id="tags" [items]="professionalTagsList" bindLabel="name" bindValue="id" [hideSelected]="true"
            [multiple]="true" [clearable]="false" addTagText="Adicionar novo"
            notFoundText="Não encontrado" formControlName="tags" [ngClass]="formGroup.get('tags')!.errors ? 'error' : 'custom'">

            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span class="ng-value-label">{{item.name}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="false">×</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <span class="ng-value-label">{{item.name}}</span>
            </ng-template>
          </ng-select>
          <app-form-error id="tags" [formGroup]="formGroup" controlName="tags">
          </app-form-error>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" formArrayName="schedules" *ngIf="isClinicStaff">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Agendas</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <label class="field__title">Agenda</label>
        </div>

        <div class="col-8">
          <label class="field__title">Tipo de atendimento</label>
        </div>
      </div>

      <div class="row">
        <ng-container *ngFor="let scheduleForm of scheduleForms.controls; index as i">
          <div class="row col-12 p-0 mb-3" [formGroup]="getFormSchedule(i)" [class]="{'d-none': !scheduleIsActive(i)}">
            <div class="px-2 col-4">
              <ng-select id="schedule" formControlName="schedule" [items]="scheduleList" bindValue="id"
                bindLabel="name" [ngClass]="scheduleForm.get('schedule')?.errors && scheduleForm.get('schedule')?.touched ? 'error' : 'custom'" (change)="changeAttendanceType(i)">
              </ng-select>
              <app-form-error id="schedule" [formGroup]="getFormSchedule(i)" controlName="schedule">
              </app-form-error>
            </div>

            <div class="px-2 col-6">

              <ng-select id="attendance_types" formControlName="attendance_types" [items]="attendanceTypeList"
                bindLabel="name" bindValue="id" [hideSelected]="true" [multiple]="true" [ngClass]="scheduleForm.get('attendance_types')?.errors && scheduleForm.get('attendance_types')?.touched ? 'error' : 'custom'"
                [clearable]="true" addTagText="Adicionar novo" notFoundText="Não encontrado">

                <ng-template ng-header-tmp>
                  <span class="btn btn-link" (click)="onSelectAll(i)">Selecionar todos</span>
                </ng-template>

                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label">{{item.name}}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="false">×</span>
                </ng-template>
              </ng-select>
              <app-form-error id="attendance_types" [formGroup]="getFormSchedule(i)" controlName="attendance_types">
              </app-form-error>
            </div>

            <div class="px-2 col-2">
              <button (click)="removeSchedule(i)" [disabled]="formGroup.disabled" class="remove-button">Remover</button>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-link" [disabled]="formGroup.disabled" (click)="addSchedule()">Adicionar novo</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Áreas de atuação</h3>
        </div>
        <div class="px-2 col-12">
          <ng-select id="specialties" [items]="specialtiesList" bindLabel="name" bindValue="id" [hideSelected]="true"
            [multiple]="true" [ngClass]="formGroup.get('specialties')?.errors && formGroup.get('specialties')?.touched ? 'error' : 'custom'" [clearable]="false" addTagText="Adicionar novo"
            notFoundText="Não encontrado" formControlName="specialties">

            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span class="ng-value-label">{{item.name}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="false">×</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <span class="ng-value-label">{{item.name}}</span>
            </ng-template>
          </ng-select>
          <app-form-error id="specialties" [formGroup]="formGroup" controlName="specialties">
          </app-form-error>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" formArrayName="phone_numbers">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Telefone</h3>
        </div>

        <ng-container *ngFor="let phoneNumber of phoneNumberForms.controls; index as i">
          <div class="row col-12" [class]="{'d-none': !phoneNumberIsActive(i)}">
            <div class="col-6 p-0 mb-3">
              <phone-number-input [formGroup]="getPhoneNumberForm(i)"></phone-number-input>
            </div>

            <div class="px-2 col-4 mb-3" [formGroup]="getPhoneNumberForm(i)">
              <label class="field__title" for="type"></label>
              <ng-select [ngClass]="phoneNumber.get('phone_number')?.errors && phoneNumber.get('phone_number')?.touched ? 'error' : 'custom'" [searchable]="false" [clearable]="false" id="type" formControlName="type">
                <ng-option value="WHATSAPP">Whatsapp</ng-option>
                <ng-option value="COMERCIAL">Comercial</ng-option>
                <ng-option value="RESIDENTIAL">Residencial</ng-option>
              </ng-select>
            </div>

            <div class="px-2 col-2">
              <button (click)="removePhoneNumber(i)" [disabled]="formGroup.disabled"
                class="remove-button">Remover</button>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-link" [disabled]="formGroup.disabled" (click)="addPhoneNumber()">Adicionar
              novo</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-md-3 mb-3">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3" *ngIf="button">
      <button (click)="removeHandler()" [disabled]="submittingRemove" class="btn btn-secondary btn-lg" type="button">
        {{removeButton}}
        <span class="spinner-border" *ngIf="submittingRemove"></span>
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3">
      <button (click)="submitHandler()" [disabled]="submitting" class="btn btn-primary btn-lg" type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
