import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RenderersModule } from './renderers/renderers.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxMaskModule } from "ngx-mask";
import { AppFormErrorComponent } from './components/form-error/form-error.component';
import { AppBasicInputComponent } from "./components/basic-input/basic-input.component";
import { ContextMenuBasicComponent } from './components/context-menu/context-menu.component';
import { ContextMenuModule } from 'ngx-contextmenu';
import {NgbTimepickerModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { IncludeSchedulingModalComponent } from './components/include-scheduling-modal/include-scheduling-modal.component';
import { QrcodeModalComponent } from './components/qrcode-modal/qrcode-modal.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { BirdidModalComponent } from './components/birdid-modal/birdid-modal.component';
import { CreateReminderModalComponent } from './components/create-reminder-modal/create-reminder-modal.component';
import { ReportModalComponent } from './components/report-modal/report-modal.component';
import { ToastButtonComponent } from './components/toast-button/toast-button.component';
import { SeiosDaFaceComponent } from './components/report-forms/seios-da-face/seios-da-face.component';
import { AttachmentModalComponent } from './components/attachment-modal/attachment-modal.component';
import { ProtocolModelComponent } from './components/protocol-model/protocol-model.component';
import { IncludeExamModalComponent } from './components/include-exam-modal/include-exam-modal.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MailingModalComponent } from './components/mailing-modal/mailing-modal.component';
import { EditorModule } from "@tinymce/tinymce-angular";
import { ListRendererComponent } from './list-renderer/list-renderer.component';
import { ExportModalComponent } from './modals/export-modal/export-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TagInputModule } from 'ngx-chips';
import { RouterModule } from '@angular/router';
import { SelectInputComponent } from './components/select-input/select-input.component';
import { TextareaInputComponent } from './components/textarea-input/textarea-input.component';
import { ImageInputComponent } from './components/image-input/image-input.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { LeadModalComponent } from './components/modals/lead-modal/lead-modal.component';

@NgModule({
  declarations: [
    PhoneNumberInputComponent,
    AppFormErrorComponent,
    AppBasicInputComponent,
    ContextMenuBasicComponent,
    IncludeSchedulingModalComponent,
    QrcodeModalComponent,
    BirdidModalComponent,
    CreateReminderModalComponent,
    ReportModalComponent,
    ToastButtonComponent,
    SeiosDaFaceComponent,
    AttachmentModalComponent,
    ProtocolModelComponent,
    IncludeExamModalComponent,
    MailingModalComponent,
    ListRendererComponent,
    ExportModalComponent,
    SelectInputComponent,
    TextareaInputComponent,
    ImageInputComponent,
    FileInputComponent,
    LeadModalComponent,
  ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        RenderersModule,
        NgSelectModule,
        NgbTimepickerModule,
        NgxMaskModule,
        ContextMenuModule.forRoot({
            useBootstrap4: true,
        }),
        NgxQRCodeModule,
        NgxBootstrapIconsModule.pick(allIcons),
        CurrencyMaskModule,
        EditorModule,
        TagInputModule,
        NgbTooltipModule,
    ],
  exports: [
    PhoneNumberInputComponent,
    AppFormErrorComponent,
    AppBasicInputComponent,
    TextareaInputComponent,
    ContextMenuBasicComponent,
    CreateReminderModalComponent,
    SelectInputComponent,
    ListRendererComponent,
    ImageInputComponent,
    FileInputComponent,
    IncludeExamModalComponent
  ]
})
export class SharedModule {
}
