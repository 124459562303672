import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AttendanceTypeModel } from 'src/app/core/models/attendance-type.model';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import { DefaultModalComponent } from 'src/app/shared/renderers/components/default-modal/default-modal.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-attendance-type-form',
  templateUrl: './attendance-type-form.component.html',
  styleUrls: ['./attendance-type-form.component.scss']
})
export class AttendanceTypeFormComponent implements OnInit {

  id!: number;
  loadingPage: boolean = false;
  backgroundColorValue?: String = "#FFFFFF";
  fontColorValue?: String = "#000000";

  formGroup = this.fb.group({
    name: new FormControl ('', [Validators.required]),
    background_color: new FormControl (this.backgroundColorValue, [Validators.required]),
    background_opacity: [1, [Validators.required]],
    font_color: new FormControl (this.fontColorValue, [Validators.required])
  });

  fetchedAttendanceTypeData!: AttendanceTypeModel;

  isRegister: boolean = true;

  disabledSalvar: boolean = false;
  disabledRemover: boolean = false;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton?: String = "Desativar";
  submitButton?: String = "Salvar";

  button: boolean = false;
  registerSuccess: boolean = false;

  constructor(private registersService: RegistersService, private router: Router, private fb: FormBuilder,
              private activatedRoute: ActivatedRoute, config: NgbModalConfig, private modalService: NgbModal,
              private sessionManager: SessionManagerService, private toast: ToastrService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      if (this.id) {
        this.isRegister = false;
        this.loadingPage = true;
        this.initializeForm();
      }
    })
  }

  async initializeForm() {
    await this.fetchAttendanceTypeExistingData();
    if (!this.sessionManager.getUserPermission(PermissionConst.change_scheduling) || !this.sessionManager.getUserPermission(PermissionConst.add_scheduling)) {
      this.disabledSalvar = true;
      this.disabledRemover = true;
      this.formGroup.disable();
    }
    if (this.sessionManager.getUserPermission(PermissionConst.remove_scheduling) && this.sessionManager.getUserPermission(PermissionConst.add_scheduling)) {
      this.disabledRemover = false;
    }
    if (!this.sessionManager.getUserPermission(PermissionConst.remove_scheduling)) {
      this.disabledRemover = true;
    }
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/registers/attendanceType'])
  }

  opacityToHex(opacity: number): string {
    let decimalOpacity: number = parseInt((255 * opacity).toFixed());
    return decimalOpacity.toString(16);
  }

  opacityFromHex(opacity: string): number {
    let opacityInteger = parseInt(opacity, 16);
    return opacityInteger / 255;
  }

  submitHandler() {
    this.submitting = true;
    const attendanceTypeData = this.formGroup.getRawValue();
    let opacity = this.opacityToHex(attendanceTypeData['background_opacity']);
    attendanceTypeData['background_color'] = `${attendanceTypeData['background_color']}${opacity}`;
    attendanceTypeData.is_active = true;
    if (this.isRegister) this.attendanceTypeRegister(attendanceTypeData);
    else this.attendanceTypeEdit(attendanceTypeData);
  }

  removeHandler() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar este tipo de atendimento?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.submittingRemove = true;
        this.attendanceTypeRemove();
      }
    })
  }

  async attendanceTypeRegister(attendanceTypeData: any) {
    try {
      await this.registersService.attendanceTypeRegister(attendanceTypeData).toPromise();
      this.registerSuccess = true;
      this.toast.success('Tipo de atendimento criado com sucesso', "Sucesso");
      this.router.navigate(['/dashboard/registers/attendanceType']);
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    }
  }

  async attendanceTypeEdit(attendanceTypeData: AttendanceTypeModel) {
    try {
      attendanceTypeData.id = this.id;
      await this.registersService.attendanceTypeEdit(attendanceTypeData).toPromise();
      this.registerSuccess = true;
      this.toast.success('Tipo de atendimento alterado com sucesso', "Sucesso");
      this.router.navigate(['/dashboard/registers/attendanceType']);
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    }
  }

  async attendanceTypeRemove() {
    try {
      await this.registersService.attendanceTypeRemove(this.id).toPromise();
      this.toast.success('Tipo de atendimento removido com sucesso', "Sucesso");
      this.router.navigate(['/dashboard/registers/attendanceType']);
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submittingRemove = false;
    }
  }

  async fetchAttendanceTypeExistingData() {
    try {
      this.fetchedAttendanceTypeData = await this.registersService.attendanceTypeGet(this.id).toPromise();
      if (this.fetchedAttendanceTypeData.is_active) {
        this.button = true;
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
      }
      else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
      }
      this.setFormData();
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
    }
  }

  setFormData() {
    this.fetchedAttendanceTypeData.background_opacity = 0;
    if (this.fetchedAttendanceTypeData.background_color?.padEnd(2))
      this.fetchedAttendanceTypeData.background_opacity = this.opacityFromHex(this.fetchedAttendanceTypeData.background_color?.slice(7));
    this.fetchedAttendanceTypeData.background_color = this.fetchedAttendanceTypeData.background_color?.slice(0, 7);
    this.formGroup.patchValue(this.fetchedAttendanceTypeData);
    this.loadingPage = false;
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro", {
        closeButton: true,
      });
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["name"]) this.formGroup.get("name")?.setErrors({ response: errorResponse.error['name'] });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if ((this.formGroup.dirty || this.submitting) && !this.registerSuccess && this.isRegister) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "As alterações no formulário não foram salvas e serão descartadas, deseja prosseguir?";
      return modalRef.result
    }
    else
      return true;
  };
}
