import { Component, OnInit } from '@angular/core';
import {ListTemplate} from "../../../../../shared/models/list-template";
import {PermissionConst} from "../../../../../core/models/permission-const.model";
import {SessionManagerService} from "../../../../../core/services/session-manager.service";
import {HttpErrorResponse} from "@angular/common/http";
import {FinancialService} from "../../../../../core/services/financial.service";

@Component({
  selector: 'app-financial-tasks-table',
  templateUrl: './financial-tasks-table.component.html',
  styleUrls: ['./financial-tasks-table.component.scss']
})
export class FinancialTasksTableComponent implements OnInit {
  searchString: string = "";
  orderingItem: string = "";
  statusString: string = "";

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Nome",
    showAdvancedSearch: false,
    newDataText: "+ Nova Tarefa",
    newDataRouterLink: (this.sessionManager.getUserPermission(PermissionConst.add_task) && this.sessionManager.getUserPermission(PermissionConst.change_task) && this.sessionManager.getUserPermission(PermissionConst.view_task)) ? "register" : undefined,
    notFoundMessage: "Nenhuma tarefa encontrada",
    header: [
      { name: "name", label: "Nome", widthInPercentage: "100%" },
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if(value == 'status_oximeter') {
        value = 'status';
      }
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      this.fetchList(this.searchString, `${invertString}${value}`, this.statusString,  this.listTemplate.is_active);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      this.fetchList(value, this.orderingItem, this.statusString,  this.listTemplate.is_active);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
  };

  constructor(private sessionManager: SessionManagerService, private financialService: FinancialService) { }

  ngOnInit(): void {
    this.fetchList(this.searchString, this.orderingItem, this.statusString, this.listTemplate.is_active);
  }

  fetchList(searchString: string, orderingItem: string, status: string, isActive: boolean | undefined) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;
    this.financialService.listTasks(searchString, orderingItem, status, isActive).subscribe(response => {
      this.listTemplate.loading = false;
      this.listTemplate.data = response;
      this.listTemplate.hasNext = response.next != null;
    }, (error: HttpErrorResponse) => {
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = error.error['detail'];
    });
  }

  loadMore(ev: any) {}
}
