import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {PermissionConst} from "../../../../../core/models/permission-const.model";
import {SessionManagerService} from "../../../../../core/services/session-manager.service";
import {ExternalExamModel} from "../../../../../core/models/external-exam.model";
import {RegistersService} from "../../../../../core/services/registers.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {
  ConfirmationModalComponent
} from "../../../../../shared/renderers/components/confirmation-modal/confirmation-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-exams-register-form',
  templateUrl: './exams-register-form.component.html',
  styleUrls: ['./exams-register-form.component.scss']
})
export class ExamsRegisterFormComponent implements OnInit {

  formGroup = this.fb.group({
    id: [],
    description: [null, [Validators.required]],
    aliquot: [null, [Validators.required]],
    value: [null, [Validators.required]],
    time: [null, [Validators.required, Validators.min(1)]]
  });

  id!: number;
  loadingPage: boolean = false;

  isRegister: boolean = true;

  existingExam!: ExternalExamModel;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  button: boolean = false;
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";

  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute, private sessionManager: SessionManagerService,
              private registersService: RegistersService, private toast: ToastrService, private router: Router,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      if (this.id) {
        this.isRegister = false;
        this.loadingPage = true;
        this.fetchExistingExam();
      }
    })
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_document);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_document);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_document);
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["name"]) this.formGroup.get("name")?.setErrors({ response: errorResponse.error['name'] });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  treatButtonsAndForm() {
    if (this.existingExam.is_active) {
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
        this.button = true;
      } else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
      }
  }

  fetchExistingExam() {
    this.registersService.externalExamGet(this.id).subscribe(response => {
      this.existingExam = response;
      this.treatButtonsAndForm();
      this.formGroup.patchValue(this.existingExam);
      this.loadingPage = false;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    })
  }

  examRegister(examData: ExternalExamModel) {
    this.registersService.externalExamRegister(examData).subscribe(response => {
      this.toast.success("Exame criado com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/registers/exams']);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  examEdit(examData: ExternalExamModel) {
    examData.is_active = true;
    this.registersService.externalExamEdit(examData).subscribe(response => {
      this.toast.success("Exame alterado com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/registers/exams']);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    let examData = this.formGroup.getRawValue() as ExternalExamModel;
    if (this.isRegister) {
      if (this.formGroup.valid) {
        this.examRegister(examData);
      } else this.submitting = false;
    } else {
      this.examEdit(examData);
    }
  }

  examRemove() {
    this.registersService.externalExamRemove(this.id).subscribe(response => {
      this.toast.success("Exame desativado com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/registers/exams']);
    }, (error: HttpErrorResponse) => {
      this.submittingRemove = false;
      this.mapErrorResponse(error);
    })
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar este exame?";
    modalRef.result.then((result: boolean) => {
      if (result) {
        this.examRemove();
      } else this.submittingRemove = false;
    })
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/registers/exams'])
  }
}
